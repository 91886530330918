<template>
    <span class="loading-dots">
      <span></span>
      <span></span>
      <span></span>
    </span>
  </template>

<script>

export default {
    name: 'loading-dots'
}
</script>

<style>
.loading-dots {
  /* display: inline-block;
  position: relative; */
  display: flex;
  justify-content: start;
  flex-direction: row;
}

.loading-dots span {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000; /* Колір крапок */
  margin-right: 5px; /* Простір між крапками */
}

/* Анімація */
@keyframes loading-animation {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.loading-dots span:nth-child(1) {
  animation: loading-animation 1.4s infinite;
}

.loading-dots span:nth-child(2) {
  animation: loading-animation 1.4s infinite 0.2s;
}

.loading-dots span:nth-child(3) {
  animation: loading-animation 1.4s infinite 0.4s;
}
</style>
