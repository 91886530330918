import { createRouter, createWebHistory } from 'vue-router';
import UploadVideo from '../components/UploadVideo.vue';
import Settings from '../components/Settings.vue';
import DonateApp from '../components/Donate.vue';
import Login from '../components/Login.vue'
import UserWorkflow from '../components/UserWorkflow.vue'
// import ArhivePage from '../components/ArhivePage.vue'
import MyProjectPage from '../components/MyProjectPage.vue';
import HomePage from '../components/HomePage.vue';
import NewProject from '../components/NewProject.vue';
import EditProject from '../components/EditProject.vue'
import VoiceActing from '../components/VoiceActing.vue'


const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/home',
    name: 'AppHome',
    component: UserWorkflow,
    // meta: {
    //   needAuth: true,
    // },
    children: [
      {
      path: '',
      name: 'HomePage',
      component: HomePage,
      meta: {
        title: "Home",
        label: 'Home',
      // breadcrumb: 'Home',
    },
      },
      {
      path: 'voiceacting',
      name: 'VoiceActing',
      component: VoiceActing,
      meta: {
      label: 'Voice Acting',
      breadcrumb: 'Voice Acting',
    },
    },
    {
      path: 'donate',
      name: 'DonateApp',
      component: DonateApp,
      meta: {
        label: 'Donate',
        breadcrumb: 'Donate',
      },
    },
    {
      path: 'projects',
      name: 'MyProjectPage',
      component: MyProjectPage,
      meta: {
        label: 'My projects',
        breadcrumb: 'My projects',
      },
      children: [
        {
          path: 'newproject',
          name: 'NewProject',
          component: NewProject,
          meta: {
            label: 'New Project',
            breadcrumb: 'New project',
          }, 
      },
      {
        path: 'editproject/:videoId',
        name: 'EditProject',
        component: EditProject,
        props: {
            videoId: {
              type: String,
              required: true
            }
          },
        meta: {
            label: 'Edit Project',
            breadcrumb: 'Edit project',
          }, 

      },
      
      ]
    },
    ]
},
  {
    path: '/upload',
    name: 'UploadVideo',
    component: UploadVideo,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
    document.title = 'Clip Translate'
    if (to.name === 'AppHome') {
        // Redirect to HomePage
        next({ name: 'HomePage' });
    } else {
        next();
    }
});

export default router;

// router.beforeEach((to)=>{
//   if(to.meta.needAuth){
//     return { name: 'Login'}
//   }
// }
// )