import MyButton from './MyButton.vue';
import SecondaryButton from './SecondaryButton.vue';
import LoadingDots from './LoadingDots.vue';
import LoadingSpinner from './Loader.vue';
import DropdownList from './DropdownList.vue'
import HintApp from './HintApp.vue'
import PlashkaModal from './PlashkaModal.vue'
import SliderApp from './SliderApp.vue'
import PlashkaConfirm from './PlashkaConfirm.vue'

export default [
    MyButton,
    SecondaryButton,
    LoadingDots,
    LoadingSpinner,
    DropdownList,
    HintApp,
    PlashkaModal,
    SliderApp,
    PlashkaConfirm
]