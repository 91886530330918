// import Vue from 'vue';
// import Vuex from 'vuex';
import video from './video';
import languages from './languages'
import { createStore } from 'vuex';


const store = createStore({
  modules: {
    video,
    languages
  },
});

export default store;
