<template>
  <div id="app">
    <!-- <img alt="Project logo" src="./assets/project_logo.jpg"> -->
        <router-view v-if="!isLoggedIn" />
        <router-view name="default" v-if="isLoggedIn" />
        <router-view name="submenu" v-if="isLoggedIn" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return{
      showMenu: false,
      isLoggedIn: false,
    }
  },
  watch: {
  '$route'() {
    if (this.$route.path.startsWith('/home')) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
  },
  }
}
  

</script>

<style>
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  /* width: 1440px;
height: 900px;
overflow-y: scroll; */
background: #F0F2F5;
}
</style>
