<template>
    <div class="wrapper">
        <div 
            class="header" 
            @click.stop="openLanguageDropdown" ref="dropdownRef"
        >
        <a href="https://www.youtube.com/watch?v=YT4yxrmKlq8" target="_blank" style="font-size: 16px; color: #1890FF; white; text-decoration: none;">Instruction</a>

        <a href="https://cliptranslate.canny.io/feature-requests" target="_blank" style="font-size: 16px; color: #1890FF; text-decoration: none; margin-right: 10px; font-weight: 400;">Add your feedback</a>

        <router-link to="/home/donate" style="font-size: 16px; color: Red; text-decoration: none;">Donate</router-link>


            <img
                src="../icon/language1.png"
                alt="language"
                class="language" 
                v-if="hide"
            />
            <language-dropdown v-show="showLanguageDropdown" class="language-dropdown"></language-dropdown>
        </div>
        <div class="main">
            <div class="title">
                <div><h1>Clip Translate<span style="position: relative; top: -0.6em; font-size: 0.6em;">β</span></h1></div>
                <div>
                    <p>
                        Translate video and audio files into any language for goodness sake
                    </p>
                </div>
            </div>
            <form @submit.prevent="submitAuthKey" class="form">
                <div class="name">
                    <p style="color: blue"><a href="https://t.me/authkey_generator_allatra_bot" target="_blank" class="link"><img src="../icon/arrow1.png" alt="attention" class="attention">Get key</a></p>
                </div>
                <input
                    placeholder="Enter the key"
                    type="text"
                    v-model="authKey"
                    required
                    class="inputText"
                    minlength="10"
                    maxlength="10"
                />
                <div class="box">
                    <div class="checkbox">
                        <input type="checkbox" v-model="rememberMe" />
                        <label for="checkbox">Remember me</label>
                    </div>
                    <div>
                        <!-- <a href="#"><p>How to gain access?</p></a> -->
                    </div>
                </div>
                <div>
                    <my-button
                        style="width: 360px; font-size: 16px;font-weight: 400;line-height: 24px;"
                        type="submit"
                        class="button"
                    >
                        Sign in
                    </my-button>
                </div>
                <div class="error-wrapper">
                    <p class="error" v-if="error" style="color: red">{{ error }}</p>
                </div>
            </form>
        </div>
        <div class="footer">
            <p style="{margin-block-end: 0; font-size: 12px; margin-block-end: 0;}">Copyright ©2023 Produced by Creative Society</p>
            <p style="{margin: 0; font-size: 8px;}">V2.2024-02-15</p>
        </div>
        <!-- <div ref="questionRef">
            <div class="q" @click="openQuestionWindow">?</div>
            <question-window v-show="showQuestionWindow"></question-window>
        </div> -->
    </div>
</template>

<script>
import Cookies from 'js-cookie';
// import QuestionWindow from "./QuestionWindow.vue";
import LanguageDropdown from "./LanguageDropdown.vue";
import { apiUrl } from './api';

export default {
  name: "LoginComponent",
  components: {
    // QuestionWindow,
    LanguageDropdown,
  },
  data() {
    return {
    //   inputText: "",
      rememberMe: false,
    //   showQuestionWindow: false,
      showLanguageDropdown: false,
      authKey: "",
      error: "",
      dateExp: null,
    //   isHovered: false,

    hide: false
    };
  },

  created() {
    const authKeyCookie = Cookies.get('auth_key');
    console.log('authKeyCookie', authKeyCookie)
    const authKeySession = window.sessionStorage.getItem('auth_key');
    console.log('authKeySession', authKeySession)


    let authKey;
    if (authKeyCookie) {
        const keyValue = authKeyCookie.split("|||");
        console.log("keyValue", keyValue)
        const [authKey, timestamp, dateExp] = keyValue;
        console.log('authKey', authKey, 'dateExp', dateExp)
        console.log('timestamp', timestamp)

        // const decodedValue = decodeURIComponent(authKey);
        // console.log(decodedValue); // Виведе "some encoded value;with;semicolon"

        const dateExp1 = new Date(dateExp); // Перетворюємо на об'єкт дати
        console.log('dateExp1',dateExp1)
        this.rememberMe = true;  // добавить это, чтобы проверить, что мы восстановили из куки
        this.currentDate = new Date();
        console.log('currentDate', this.currentDate)

      if (this.currentDate.getTime() <= dateExp1.getTime()) {
        console.log('ключ ще діє')
        this.checkAuthKey(authKey);
      } else {
        console.log('термін дії ключа закінчився!')
        this.setError('Frontend: Key is outdated. Please update your key.');
      }
    } else if (authKeySession) {
      authKey = authKeySession;
      console.log('authKey', authKey)
      this.checkAuthKey(authKey);
    } else {
      this.setError('Frontend: The key is missing. Please enter the key.');
    }
  },
    mounted() {
        document.addEventListener('click', this.onClickOutside);
        console.log('mounted')

    },
    beforeUnmount() {
        document.removeEventListener('click', this.onClickOutside);
        console.log('beforeUnmount')
    },

  methods: {
    setError(errorMsg) {
      this.error = errorMsg;
    },
    async submitAuthKey() {
        console.log('submit AuthKey', this.authKey)
      if (this.authKey.length !== 10) {
        this.error = "Frontend: Key must be 10 characters.";
        return;
      }
      try {
        const response = await fetch(`${apiUrl}/api/save-auth-key`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ auth_key: this.authKey }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            console.log('data', data)
            const dateExp = new Date(data.key_data_created); // змінити назву
            console.log('this.dateExp', dateExp)
            dateExp.setHours(dateExp.getHours() + (3 * 24));
            console.log('this.dateExp', dateExp)


            // this.$store.dispatch('video/updateDateExpiration', dateExp.toISOString());
            this.$store.commit('video/setDateExpiration', dateExp.toISOString())
            // Создание временной метки
            const timestamp = new Date().getTime();
            console.log('this.timestamp', timestamp)


            // Save the key to sessionStorage
            window.sessionStorage.setItem('auth_key', this.authKey);
            console.log('this.authKey', this.authKey)

            window.sessionStorage.setItem('expired_date', dateExp.toISOString());


            // Save the key to cookie if rememberMe is checked
            if (this.rememberMe) {
                console.log('remember me', this.authKey, timestamp, dateExp)
              document.cookie = `auth_key=${encodeURIComponent(this.authKey)}|||${timestamp}|||${dateExp}; path=/;`;
              console.log('document.cookie', document.cookie)

            }
            this.$router.push({ name: "HomePage" });
          } else {
            console.error("BackEnd Error 1:", data.error);
            this.error = data.error;
          }
        } else {
          console.error("BackEnd Error 2");
          this.error = "The key does not match or is outdated in the database. Obtain a new one.";
        }
      } catch (err) {
        console.error("Frontend Error 3:", err);
        this.error = "Frontend 3: An error occurred while saving the key.";
      }
    },
    async checkAuthKey(authKey) {
        console.log('check AuthKey')
      try {
        const response = await fetch(`${apiUrl}/api/check-auth-key`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ auth_key: authKey }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            console.log('ключ перевірений', authKey)
            const dateExp = new Date(data.key_data_created)
            dateExp.setDate(dateExp.getDate() + 3);
            // Save the key to sessionStorage
            window.sessionStorage.setItem('auth_key', authKey);
            window.sessionStorage.setItem('expired_date', dateExp.toISOString());
            console.log('this.authKey- sessionStorage', authKey)
            console.log('expired_date', data.key_data_created)

            this.$store.commit('video/setDateExpiration', dateExp.toISOString())


            this.$router.push({ name: "HomePage" });
          } else {
            this.error = data.error;
          }
        } else {
          this.error = "Backend Error: An error occurred while validating the key.";
        }
      } catch (err) {
        this.error = "Frontend Error: An error occurred while validating the key.";
      }
    },
    // setHover() {
    //   this.isHovered = true;
    // },
    // clearHover() {
    //   this.isHovered = false;
    // },
    // openQuestionWindow() {
    //   this.showQuestionWindow = !this.showQuestionWindow;
    // //   console.log("help");
    // },
    // openLanguageDropdown() {
    //   this.showLanguageDropdown = !this.showLanguageDropdown;
    //   console.log("language");
    // },
    // onClickOutside(event) {
    //     console.log(event.target)
    //     if (!this.$refs.dropdownRef.contains(event.target)) {
    //     this.showLanguageDropdown = false;
    //     console.log(this.showLanguageDropdown)
    //   } 
    //   if (!this.$refs.questionRef.contains(event.target)) {
    //     this.showQuestionWindow = false;
    //     // console.log(this.showQuestionWindow)
    //   }
    // }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* overflow: scroll; */
  margin: 0;
  min-height: 100vh;
}
.header {
  display: flex;
  justify-content: end;
  gap: 15px;
  padding: 3px 10px;
  height: 40px;
  align-items: center;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding: 0px;
  /* gap: 10px; */
  /* width: 290px; */
  /* height: 46px; */
  margin-top: auto;
}

.language {
  width: 30px;
  height: 30px;
  padding-top: 10px;
  padding-right: 10px;
}

.language-dropdown {
    position: absolute;
    z-index: 999;
    top: 20px;
    right: 50px;
    color: black;
}
.title {
  margin: 0 auto;
  /* width: 440px; */
  height: 81px;
  margin-bottom: 98px;
}
.title h1 {
  font-style: normal;
  font-weight: 800;
  font-size: 33px;
  line-height: 38px;
}
.title p {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  gap: 22px;
  width: 360px;
  height: 216px;
  margin: 0 auto;
}
.name {
  border-style: solid;
  border-width: 0 0 2px 0;
  border-color: #d9d9d9;
}
.inputText {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  height: 40px;
}
.name p {
  text-align: left;
  /* border-style: solid; */
  /* border-width: 0 0 2px 0;
  border-color: blue; */
  /* width: fit-content; */
}
.box {
  display: flex;
  justify-content: space-between;
}
.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  margin: 0;
}
.checkbox input {
  width: 20px;
  height: 20px;
}
.checkbox label {
  height: fit-content;
}
.button {
  width: 360px;
  height: 40px;
  background-color: #1890ff;
  color: white;
  border: 1px solid #1890ff;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.q {
  display: block;
  margin-left: auto;
  position: absolute;
  z-index: 9999;
  bottom: 30px;
  right: 50px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d9d9d9;
  color: black;
  font-size: 30px;
  text-align: center;
}
.lahguage:hover {
  cursor: pointer;
}
.q:hover {
  cursor: pointer;
}
/* button.activ{

} */
button:hover {
  cursor: pointer;
}
.attention {
    height: 20px;
    width: 20px;
}
.link {
    display: flex;
    gap: 3px;
}
</style>
