<template>
    <div class="wrap-hint"  :class="{'visible': showHint }" >
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'hint-app',
    props: {
        showHint: {
            type: Boolean,
            default: false
        },
    },
    
}
</script>
 
<style scoped>
 .wrap-hint{
    position: absolute;
    background: white;
    padding: 8px;
    max-width: 200px;
    min-width: 200px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    left: 105%; 
    top: 0; 

 }

 .wrap-hint.visible {
  visibility: visible;
  opacity: 1;
}

</style>
