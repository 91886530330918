<template>
    
    <div class="container-header">
        <div class="content-header">
            <div class="breadcrumbs">
                <breadcrumbs-menu></breadcrumbs-menu>
            </div>
            <div class="title">
                <h3 class="namePage">{{ pageTitle }}</h3>
                <my-button class="btn" 
                v-show="!showButtonAddNew" 
                @click="openCreateNewProject">+ Add New</my-button>
            </div>
        </div>
    </div>
    <router-view v-show="showProject"  :videoId="selectedVideoId"></router-view>
    <!-- eslint-disable-next-line vue/no-multiple-template-root -->
    <div class="wrap-projects" v-show="isVisible">
        <div class="header-wrap">
            <div class="project-header">
                <p>Projects list</p>
                <div class="action">
                    <p>Action with selected</p>
                    <!-- <secondary-button class="downloadButton" 
                        :is-active="isListEmpty">
                        Download
                    </secondary-button> -->
                    <secondary-button class="deleteButton"  
                        :is-active="isListEmpty" 
                        @click="deleteSelectedRows">
                        Delete
                    </secondary-button>
                </div>
            </div>
        </div>
        <div class="projectList" >
                <project-list  
                v-if="showProjectList" 
                ref="childComponent" 
                @edit-video="handleEditVideo"/>
        </div>

        <div class="spase" v-if="!isSpaseArea">
            <div class="spase-area" >
                <p>Let create something good</p>
                <my-button class="createButton" @click="openCreateNewProject">Create Now</my-button>
            </div>
        </div>
    </div>
</template>

<script>

import BreadcrumbsMenu from './BreadcrumbsMenu.vue';
import ProjectList from './ProjectList.vue';


export default {
    name: 'MyProjectPage',
    components: {
        BreadcrumbsMenu,
        ProjectList
    },
    data() {
        return {
            isLoading: false,
            showProject: false,
            isVisible: true,
            isButtonDisabled: true,
            selectedVideoId: null
        }
    },

    created(){
        console.log('created my projects')
        this.$store.dispatch('video/fetchVideoList');
    },
    mounted() {
        console.log('mounted my projects')
        // console.log(this.$route.path )
        if (this.$route.path === '/home/projects/newproject') {
            this.showProject = true;
            this.isVisible = false;
        } else if (this.$route.path.includes('/home/projects/editproject/')) {
            this.showProject = true;
            this.isVisible = false;
        }            
        // console.log('mounted - Is component visible:', this.isVisible);
        // this.$store.dispatch('video/fetchVideoList');
        },

    computed: {
        isListEmpty() {
            // console.log('computed isListEmpty')
            return this.$store.state.video.videoList.length === 0;
        },
        showProjectList() {
            // console.log('computed showProjectList')
            return this.$store.state.video.videoList.length > 0;
        },
        pageTitle() {
            // console.log('computed')
        return this.$route.meta.label;
        },
        showButtonAddNew() {
            // console.log('computed showButtonAddNew')
            return this.$route.path === '/home/projects/newproject' || this.$route.path.includes('/home/projects/editproject/') || this.$store.state.video.videoList.length === 0;
        },
        isSpaseArea() {
            // console.log('computed isSpaseArea', this.isSpaseArea)
            return this.$store.state.video.videoList.length > 0 || this.$route.path === '/home/projects/newproject' || this.$route.path.includes('/home/projects/editproject/')
        }

    },


    methods: {
        // async fetchVideoList() {
        //     await this.$store.dispatch('video/fetchVideoList').then(()=>{
        //     console.log('fetchVideoList methods')
        //     // const tableLength = this.$store.state.video.videoList.length
        //     });
        // },

        handleEditVideo(videoId) {
            console.log('handleEditVideo', videoId)
            this.selectedVideoId = videoId;
            this.isVisible = false;
            this.showProject = true; 
            this.$router.push({ name: 'EditProject', params: { videoId: videoId } });
        },

        deleteSelectedRows() {
            if(this.isListEmpty){
                console.log('this.isListEmpty', this.isListEmpty)
                return
            } else {
                console.log('this.isListEmpty', this.isListEmpty)
                this.$refs.childComponent.promptDeleteVideos(); //треба
            }
        },

        openCreateNewProject() {
            this.showProject = true;
            this.isVisible = false;
            this.$router.push({ name: 'NewProject' });
            console.log('open new project');
            },

        checkTableLength(){
            this.$store.dispatch('video/fetchVideoList');
            const tableLength = this.$store.state.video.videoList.length
            console.log('checkTableLength - tableLength', tableLength)
        }
    },
    
    beforeRouteUpdate(to, from, next) {
        console.log('Befor route update')
        if (from.name === 'NewProject' || from.name === 'EditProject') {
        this.showProject = false;
        this.isVisible = true;
        } 
        this.checkTableLength()
        next();
    },
    
};
</script>

<style scoped>
.wrap-projects {
    padding: 0px;
    margin: 0;
}
.header-wrap{
    padding: 0px;
    margin: 24px 24px 5px 24px;
    height: 64px;
    background-color: white;
    
}
.project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 24px;
    padding-right: 24px;
}

/* .addNew{
    height: 32px;
    background: #1890FF; 
    border: 1px solid #1890FF; 
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    color: #FFFFFF;
    font-size: 14px;
} */

.project-header p{
    font-size: 16px;

}
.projectList {
    padding: 0px;
    margin: 24px 24px 5px 24px
}
.action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    /* width: 318px; */
    height: 32px;
}
.spase {
    height: 230px;
    background-color: white;

    
}
.spase-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 5px;
    gap: 8px;
    /* width: 203px; */
    height: 137.51px;
    padding-top: 47px;
}

/* .createButton {
    height: 32px;
    width: 105px;
    background-color: #1890FF;
    color: white;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
} */

/* .downloadButton{
    height: 32px;
    cursor: pointer;
    display: block;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    background-color: white;
    color: #1890FF;
} */

/* .deleteButton {
    height: 32px;
    cursor: pointer;
    display: block;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    background-color: white;
    color: #1890FF;
} */

/* .disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: gray;
  border: 1px solid gray;
} */
.container-header {
    box-sizing: border-box;
    height: 100px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 16px 24px;
    gap: 8px;
}

.content-header{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}
.namePage{
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    height: 28px;
    padding: 0;
    margin: 0;
    text-align: start;
}

.breadcrumbs{
    height: 22px;
    /* z-index: 999; */
}

.title{
    height: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
}

@media screen and (max-width: 650px) {
  .project-header {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
  }

  .header-wrap {
    height: auto;
    padding-bottom: 15px;
  }
}

</style>