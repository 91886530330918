<template>
    <div>
        <div class="container-header">
            <div class="title">
                <h3>Workspase</h3>
            </div>
            <div class="content-title">
                <div class="img-workspase">
                    <img src="../icon/workspase.svg"/>
                </div>
                <div class="text-wokspase">
                    
                    <h3>Hello, my friend, have a great day!</h3>
                    <p>Achieving high-quality translation of video and audio into any language of the world is just three steps away.</p>

                </div>
                <div class="result">
                    <div class="projectResult">
                        <router-link to="/home/projects"><p class="name">Projects</p></router-link>
                        <p class="number">{{ countProjects }}</p>
                    </div>
                    <!-- <div class="arhiveResult">
                        <router-link to="/home/arhive"><p class="name">Arhive</p></router-link>
                        <p class="number">34</p>
                    </div> -->
                    <div class="translationResult">
                        <router-link to="/home/projects"><p class="name">Translated</p></router-link>
                        <p class="number">{{ translatedProjects }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="wrapper-workspase">
            <div class="message"><p>Please pay attention!</p>
            <p>This is a workflow for translating videos.</p></div>
            <div class="content-workspase">
                <div class="steps"> 
                    <div class="step">
                        <div class="stepHead">
                            <div class="stepIcon"><p>1</p></div>
                            <div class="stepTitle">Upload</div>
                            <div class="stepLine"><div class="line"></div></div>
                        </div>
                        <div class="stepDescription">Upload a video file</div>
                    </div>
                    <div class="step">
                        <div class="stepHead">
                            <div class="stepIcon"><p>2</p></div>
                            <div class="stepTitle">Settings</div>
                            <div class="stepLine"><div class="line"></div></div>                    </div>
                        <div class="stepDescription">Choose the translation language and the announcer's voice</div>
                    </div>
                    <div class="step">
                        <div class="stepHead">
                            <div class="stepIcon"><p>3</p></div>
                            <div class="stepTitle">Download</div>
                        </div>
                        <div class="stepDescription">Download translated audio or video file</div>
                    </div>
                </div>
            </div>
            <div class="diliver"><div class="line2"></div></div>
            <div class="buttons">
                <!-- <button class="button1">Watch the video</button> -->
                <router-link to="/home/projects/newproject" class="notDecoration"><my-button class="button2">Let’s translate!</my-button></router-link>
            </div>
        
        </div>
        <router-view></router-view>
        <!-- <plashka-modal 
        v-if="shouldShowPlashka"
        :show-reminder="plashkaVisible" 
        @update:show-reminder="plashkaVisible = $event"> 
            <p>The key expires in {{dateExpiration}}</p>
        </plashka-modal> -->

    </div>
</template>

<script>
// import MyButton from './UI/MyButton.vue';
// import PlashkaModal from './UI/PlashkaModal.vue';


export default {
//   components: { MyButton },
    name: 'HomePage',
    components:{ 
        // PlashkaModal
    },
    data() {
    return {
        // plashkaVisible: true


    };
    
    },
    created() {
        console.log('created home page')
        this.$store.dispatch('video/fetchVideoList')
        this.$store.getters['video/countProjects'];
        this.$store.getters['video/translatedProjects'];
        this.$store.dispatch('video/getDateExpiration')
        // this.$store.getters['video/dateExpiration'];
    },

    mounted() {
        // this.$store.dispatch('video/fetchVideoList')
        console.log('mounted home page')
    },

    computed: {
        countProjects(){
            return this.$store.getters['video/countProjects'];
        },

        translatedProjects(){
            return this.$store.getters['video/translatedProjects']
        },
        // dateExpiration() {
        //     return this.$store.getters['video/dateExpiration'];
        // },
        // shouldShowPlashka(){
        //     // return this.calculateDaysRemaining(this.dateExpiration) > 100 //
        //     return this.dateExpiration
        // }

    },
    methods: {
        // calculateDaysRemaining(expirationDate) {
        //     // Логіка для розрахунку залишених днів до експірації
        //     const currentDate = new Date();
        //     const expDate = new Date(expirationDate);
        //     console.log(expDate)
        //     const timeDiff = expDate - currentDate;
        //     const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
        //     return daysRemaining;
        // }
    }
    




}
</script>

<style scoped>
    .container-header {
        height: 100px;
        background-color: #FFFFFF;
        padding: 0;
        margin: 0;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        height: 156px;
        gap: 12px;
        padding: 12px 24px 16px;
    }

    .content-title{
        display: flex;
        flex-direction: row;
        height: 72px;
        gap: 12px;
    }

    .title{
        height: 44px;
        color: rgba(0, 0, 0, 0.85);
    }
    .title h3 {
        font-size: 20px;
        height: 28px;
        color: rgba(0, 0, 0, 0.85);
        padding: 0px;
        margin: 0;
        text-align: start;
    }

   .img-workspase{
        height: 72px;
        width: 72px;
   }

   .text-wokspase{
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0px;
    gap: 12px;
    height: 62px;
    
   }

   .text-wokspase h3{
    font-size: 20px;
    height: 28px;
    color: rgba(0, 0, 0, 0.85);
    padding: 0px;
    margin: 0;
    text-align: start;
   }

   .text-wokspase p{
    font-size: 14px;
    color: #000000;
    opacity: 85%;
    height: 22px;
    padding: 0px;
    margin: 0;
    text-align: start;

   }
   .result {
    display: flex;
    flex-direction: row;
    align-items: flex-end;;
    padding: 0px;
    gap: 32px;
    height: 64px;
   }
   .name{
    font-size: 14px;
    margin: 0;
   }
   .number {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
    text-align: center;
   }
   .projectResult,
   .arhiveResult,
   .translationResult {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 62px;
    align-items: center;
   }
   .wrapper-workspase{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 24px;
    gap: 10px;
    /* width: 1345px; */
    /* height: 308px; */
    background: #FFFFFF;
    margin: 16px;
    
   }
   
   .sreps {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 0px;
    gap: 16px;
    /* width: 1297px; */
    height: 108px;
   }

   .content-workspase{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px;
        /* width: 1136px; */
        /* height: 252px; */
   }

   .steps{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    /* width: 1000px; */
    height: 76px;
    box-sizing: border-box;
    margin: 40px 92px 40px 92px;
    flex-grow:  0;
   }
   .step{
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 0px;
    flex-grow: 1;
    flex-basis: 0;
   }
    .stepHead {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0px;
        gap: 8px;
        /* width: 322.67px; */
        height: 32px;
    }
    .stepIcon{
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #1890FF;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .stepTitle{
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
    }
    .stepLine{
        display: flex;
        justify-self: center;
        align-items: center;
    }
    .line {
        height: 0;
        border: 1px solid #1890FF;
        min-width: 150px;
    }
    .stepDescription {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        padding-left: 40px;
        text-align: start;
    }

    .buttons{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: 271px;
        height: 32px;   
        margin-bottom: 30px;  
    }
    .button1{
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        height: 32px;  
        padding: 5px;
        width: 130px;
    }
    /* .button2{
        background: #1890FF;
        border: 1px solid #1890FF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        color: #FFFFFF;
        font-size: 14px;
        height: 32px;  
        padding: 5px 16px;
        width: 130px;
    } */
    .message{
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        margin-top: 30px;
        padding: 0px 0px 16px 0px;
        height: 56px;
        /* margin-right: auto; */
    }
    .message p{
        font-size: 16px;
        color: #1890FF;
        padding: 0;
        margin: 0;
    }
    .diliver{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 40px 0px 24px; */
        gap: 10px;
        /* width: 1136px; */
        height: 30px;
        margin: auto;
        width: 100%;
    }
    .line2{
        border: 1px solid rgba(0, 0, 0, 0.03);
        align-self: stretch;
        width: 100%;
    }

    /* .button2:hover{
        cursor: pointer;
    } */

.notDecoration {
    text-decoration: none;
}

@media screen and (max-width: 920px) {
  .content-title {
    height: auto;
    flex-wrap: wrap;
  }

  .text-wokspase {
    height: auto;
  }

  .text-wokspase h3 {
    height: auto;
  }

  .result {
    height: auto;
  }

  .steps {
    flex-direction: column;
    margin: 40px 40px 40px 40px;
  }

  .diliver {
    margin-top: 80px;
  }

  .stepHead {
    height: auto;
  }

  .container-header {
    height: auto;
  }

  .text-wokspase p {
    height: auto;
  }

  .text-wokspase {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 600px) {
  .buttons {
    flex-direction: column;
  }
}

</style>