<template>
    <ul class="parts-list" >
        <li class="parts-item" v-for="(el, segmentIndex) in filteredVideoTexts" 
        :key="segmentIndex" 
        >
                <div class="parts-number"><div class="parts-number-inside"> {{ `${Number(segmentIndex)+1}` }}</div></div>
                <div class="parts-box">
                    <div class="parts-contetn">
                    <div class="parts-header">
                        <div class="parts-time-wrapper">
                        <div class="parts-time parts-start">{{ formatTime(el.start) }}</div>
                        <div class="parts-arrow">
                            <svg
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            class="Subtitles_ArrowIcon__3kxBy"
                            >
                            <path
                                d="M7.481 4.268a.45.45 0 1 1 .636-.636l2.55 2.55a.45.45 0 0 1 0 .636l-2.55 2.55a.45.45 0 0 1-.636-.636L9.263 6.95H1.649a.45.45 0 1 1 0-.9h7.614L7.48 4.268Z"
                                fill="currentColor"
                            ></path>
                            </svg>
                        </div>
                        <div class="parts-time parts-end">{{ formatTime(el.stop) }}</div>
                        </div>
                        
                        <div class="parts-drop-wrapper">
                            <div class="parts-speaker" >
                                Speaker: 
                                <div class="iconMath" @click="minusSpeaker(el)"><img src="../icon/reduce.png" class="addIcon"/></div>
                                {{ el.speaker }}
                                <div class="iconMath" @click="addSpeaker(el)"><img src="../icon/add.png" class="addIcon"/></div>
                            </div>
                            <div class="gender-block">
                                <div class="parts-drop-title">Gender:</div>
                                <div class="dropdown" ref="'dropdown1' + segmentIndex">
                                    <div class="dropdown-btn" @click="toggleSexDropdown(segmentIndex)">
                                        <p class="dropdown-btn-p">{{  getCurrentGender(el) }}</p> 
                                        <img src="../icon/arrow_down.png" class="arrowImg"/>
                                    </div>
                                        <div class="dropdown-content" v-show="isSexDropdownVisible(segmentIndex)">
                                            <div v-for="(item, index) in getGenderList(el)" :key="index" class="dropdown-content-item">
                                                <input
                                                type="radio"
                                                :id="`radio1-${segmentIndex}-${index}`"
                                                v-model="videoTexts[segmentIndex].gender"
                                                :value="item"
                                                @click.stop="updateVoiceForGender(videoTexts[segmentIndex], item); closeSexDropdown(segmentIndex)"
                                                :checked="el.gender === item"
                                                :disabled="readOnly"
                                                />
                                                <label :for="`radio1-${segmentIndex}-${index}`">{{ item }}</label>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="voice-block">
                                <div class="parts-drop-title" >Voice:</div>
                                <div class="dropdown" ref="dropdown2" >
                                    <div class="dropdown-btn-voice" @click="toggleVoiceDropdown(segmentIndex)">
                                        <p class="dropdown-btn-voice-p">{{  el.current_voice? el.current_voice: "choose voice" }}</p> 
                                        <img src="../icon/arrow_down.png" class="arrowImg"/>
                                    </div>
                                    <div class="dropdown-content-voice" v-show="isVoiceDropdownVisible(segmentIndex)">
                                        <div v-for="(item, index) in getVoiceList(el)" :key="index" class="dropdown-content-item">
                                            <input
                                            type="radio"
                                            :id="`radio2-${segmentIndex}-${index}`"
                                            v-model="videoTexts[segmentIndex].current_voice"
                                            :value="item"
                                            @click.stop="closeVoiceDropdown(segmentIndex, item)"
                                            :checked="el.current_voice === item"
                                            :disabled="readOnly"
                                            />
                                            <label :for="`radio2-${segmentIndex}-${index}`">{{ item }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="audio" >
                                    <img src="../icon/audio.png" class="audio-img" @click="playAudio(el.current_voice)"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-act">
                        <div class="button-act-sec">
                            <secondary-button 
                                @click="addPause(el, segmentIndex)" 
                                :isActive="readOnly"
                                @mouseenter="showTooltip(segmentIndex, 'pause')"
                                @mouseleave="hideTooltip(segmentIndex)"
                            >
                                Add pause
                            </secondary-button>
                            <hint-app 
                                :showHint="activeTooltipIndex === segmentIndex && activeTooltipType === 'pause'"
                            >
                                Put the cursor in the place where you want to add a pause
                            </hint-app>

                        </div>
                        <div class="button-act-sec">
                            <secondary-button 
                                @click="splitSegmentModal(el, segmentIndex)" 
                                :isActive="readOnly"
                                v-if="el.words"
                                @mouseenter="showTooltip(segmentIndex, 'split')"
                                @mouseleave="hideTooltip(segmentIndex)"

                            >
                                Split segment
                            </secondary-button>
                            <hint-app 
                                :showHint="activeTooltipIndex === segmentIndex && activeTooltipType === 'split'" 
                            >
                            To split a segment into two segments, click the "Split segment" button and select the word after which to split from the list
                            </hint-app>

                        </div>
                        <div class="button-act-sec">
                            <secondary-button
                                @click="combineSegmentModal(el, segmentIndex, filteredVideoTexts[segmentIndex-1])"
                                :isActive="readOnly"
                                v-if="el.words && segmentIndex !== 0"
                                @mouseenter="showTooltip(segmentIndex, 'combine')"
                                @mouseleave="hideTooltip(segmentIndex)"
                            >
                                Combine segments
                            </secondary-button>
                            <hint-app 
                                :showHint="activeTooltipIndex === segmentIndex && activeTooltipType === 'combine'" 
                            >
                                Click the button to combine this segment with the previous one
                            </hint-app>

                        </div>

                        <div class="speed-block" v-if="el.current_voice && !el.current_voice.startsWith('EL_')">
                            <p>Speed:</p>
                            <slider-app 
                            @updateVal="updateValueSegment" 
                            :segmentIndex="Number(segmentIndex)" 
                            :elem="el" 
                            :readonly="readOnly"
                            :value="valueSpeed(el)"
                            />
                        </div>

                        <div class="voice-segment-block">
                                <secondary-button 
                                @click="sendToVoiceSegment(el, segmentIndex)" 
                                :isActive="readOnly"
                                >Voice the segment</secondary-button>
                            </div>
                    </div>

                    <div class="autogrow-textarea">
                        <textarea
                        class="parts-textarea"
                        :class="{'rtl': translateLanguage == 'Hebrew' || translateLanguage == 'Arabic' || translateLanguage == 'Hindi' || translateLanguage == 'Urdu'}"
                        ref="textarea"
                        v-model="el.translated_text"
                        :readonly="readOnly"
                        />
                        <img src="../icon/copy.png" class="copyIcon" alt="Copy text" @click="copyText(el.translated_text)"/>
                    </div>
                    <div class="parts-OriginalText"                         
                    :class="{'rtl': translateLanguage == 'Hebrew' || translateLanguage == 'Arabic' || translateLanguage == 'Hindi' || translateLanguage == 'Urdu'}"
                    >
                        {{ el.original_transcribed_text }}
                    </div>
                    </div>
                    <img src="../icon/delete.png" class="delete" @click="deleteSegment(segmentIndex)" :class="{ 'readonly': readOnly}"/>
                </div>
                <div v-if="showModal" class="modal-background" @click.self="showModal = false">
                    <div class="modal-content">
                        <h2>Confirm Delete</h2>
                        <p>Are you sure you want to delete this text?</p>
                        <div class="modal-button">
                            <button @click="confirmDelete()">Confirm</button>
                            <button @click="showModal = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <div v-if="showModal1" class="modal-background" @click.self="showModal1 = false">
                    <div class="modal-content">
                        <p>After which word to break the segment?</p>
                        <div class="modal-drop">
                            <dropdown-list :list="words" @updateItem="getWordIndex" :imagePath="imagePath" :title="title" />
                        </div>
                        <div class="modal-button">
                            <button @click="splitSegment(el)">Split</button>
                            <button @click="showModal1 = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <div v-if="showModal2" class="modal-background" @click.self="showModal2 = false">
                    <div class="modal-content">
                        <h2>Confirm combine</h2>
                        <p>Are you sure you want to combine segments?</p>
                        <div class="modal-button">
                            <button @click="confirmCombine(el)">Confirm</button>
                            <button @click="showModal2 = false">Cancel</button>
                        </div>
                    </div>
                </div>
                <plashka-modal v-if="showModal3"
                    :show-reminder="showModal3"
                    @update:show-reminder="showModal3 = $event"
                >
                    No previus segment
                </plashka-modal>

        </li>
    </ul>

</template>

<script>
import SecondaryButton from './UI/SecondaryButton.vue';
import { apiUrl } from '../components/api'


export default {
    name: 'segments-text',
    components: {
        SecondaryButton
    },
    props: {
        videoTextTranslated: {
            type: Object,
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        baseLanguage: {
            type: String
        },
        translateLanguage: {
            type: String 
        },
        videoId: {
            type: String
        },

    },

    data() {
        return {
            textarea: "",
            sexShow1: {},
            sexItems1: ["male", "female"],
            sexSelected1: {},
            sexShow2: {},
            sexItems2: [],
            sexSelected2: {},
            // isReadOnly: false,
            // videoTexts: {},
            voiceLists: [],
            selectedGender: "",
            showModal: false,
            segment: null,
            showModal1:false,
            showModal2: false,
            showModal3: false,
            words: [],
            imagePath: require('../icon/arrow_down.png'),
            title: 'choose',
            indexWord: null,
            el: null,
            elPrev: null,
            showHint: false,
            activeTooltipIndex: null,
            activeTooltipType: null,
            timeoutId: null,

            hide: false,
        }

    },
    mounted() {
        // console.log('segments', this.videoTextTranslated)
        // console.log('videoTexts', this.videoTexts)
    },



    computed: {
        filteredVideoTexts() {
            if (!this.videoTextTranslated) {
                return [];
            }
            const filteredItems = Object.values(this.videoTextTranslated).filter(
            (el) => el
            );
            this.$emit('countSegments', filteredItems.length)
            // console.log('filteredItems computed', filteredItems)
            console.log('filteredItems length computed', filteredItems.length)

            return filteredItems;
        }, 
        valueSpeed(){
            return(el)=>{ 
            let val = 10
            if (el) {
                // console.log('this.el', el)
                const minVoiceSpeed = el.min_voice_speed;
                const maxVoiceSpeed = el.max_voice_speed;
                if (minVoiceSpeed == -0.05 && maxVoiceSpeed == 0.05){
                    // console.log('val', val)
                    val= 1;
                } else if (minVoiceSpeed == -0.1 && maxVoiceSpeed == 0.1){ 
                    val= 2;
                } else if (minVoiceSpeed == -0.15 && maxVoiceSpeed == 0.15) {
                    val= 3;
                } else if(minVoiceSpeed == -0.2 && maxVoiceSpeed == 0.2){
                    val= 4;
                } else if (minVoiceSpeed == -0.25 && maxVoiceSpeed == 0.25){
                    val= 5;
                } else if (minVoiceSpeed == -0.3 && maxVoiceSpeed == 0.3){
                    val= 6;
                } else if (minVoiceSpeed == -0.35 && maxVoiceSpeed == 0.35){
                    val= 7;
                } else if (minVoiceSpeed == -0.4 && maxVoiceSpeed == 0.4){
                    val= 8;
                } else if (minVoiceSpeed == -0.45 && maxVoiceSpeed == 0.45){
                    val= 9;
                } else if (minVoiceSpeed == -0.5 && maxVoiceSpeed == 0.5){
                    val= 10;
                    // console.log('10',val)

            }
        }
        return val
        }
    }
    },

    watch: {
        videoTextTranslated: {
            immediate: true, // Виконати обробку під час першого визначення пропсу
            handler(newValue) {
                this.videoTexts = newValue; // Оновлюємо дані після отримання нового значення пропсу
            },
        },
        
    },
    
    methods: {
        updateValueSegment(value, segmentIndex, el) {
            console.log('updateValue', value, segmentIndex, el)
            console.log('min', el.min_voice_speed)
            const val = Number(value)
            console.log('val', val)
            el.min_voice_speed = value/100
            console.log('min', el.min_voice_speed)
            console.log(el)
            let speed = this.setLevel(value)
            el.min_voice_speed = speed.min
            el.max_voice_speed = speed.max
            console.log(el, el.min_voice_speed, el.max_voice_speed)
        },
        setLevel(value){
            console.log(value)
            switch (value) {
                case 1:
                    return {min: -0.05, max: 0.05};
                case 2:
                    return  {min: -0.1, max: 0.1};
                case 3:
                    return {min: -0.15, max: 0.15};
                case 4:
                    return {min: -0.2, max: 0.2};
                case 5:
                    return {min: -0.25, max: 0.25};
                case 6:
                    return {min: -0.3, max: 0.3};
                case 7:
                    return {min: -0.35, max: 0.35};
                case 8:
                    return {min: -0.4, max: 0.4};
                case 9:
                    return {min: -0.45, max: 0.45};
                case 10:
                    return {min: -0.5, max: 0.5};
                default:
                    return 'No value';
            }
        },

        showTooltip(index, type) {
            // console.log('навели курсор')
            this.tooltipTimeoutId = setTimeout(() => {
                this.activeTooltipIndex = index;
                this.activeTooltipType = type;
                this.startTimeout()
            }, 500);
        },

        startTimeout() {
            this.timeoutId = setTimeout(() => {
            this.activeTooltipIndex = null;
            this.activeTooltipType = null;
            // console.log('tooltip clear')
            }, 2000);
        },
        clearTimeout() {
            // console.log('clearTimeout')
            clearTimeout(this.timeoutId);    
            clearTimeout(this.tooltipTimeoutId)
        },    
        hideTooltip() {
            // console.log('прибрали курсор')
            // console.log('activeTooltipIndex',  this.activeTooltipIndex)
                this.clearTimeout()
                this.activeTooltipIndex = null;
                this.activeTooltipType = null;
                // console.log('activeTooltipIndex',  this.activeTooltipIndex)
        },

        addPause(el, index){
            const text1 = this.$refs.textarea[index]
            const text = el.translated_text
            const cursorPosition = text1.selectionStart;
            if(this.readOnly) {
                return
            } else {
                const newText = text.slice(0, cursorPosition) + '<break time="1s"/>'  + text.slice(cursorPosition);
                el.translated_text = newText
            }
        },
        getWordIndex(item, index) {
            console.log('getWordIndex', item, index)
            this.title = item
            this.indexWord = index
        },
        combineSegmentModal(el, segmentIndex, elPrev){
            if(this.readOnly) {
                return
            } else if(!elPrev ) {
                this.showModal3 = true
                console.log('no previus element')
            } else {
                this.showModal2 = true
                const text = el.original_transcribed_text
                this.words = text.split(/\s+/)
                this.segment = segmentIndex
                this.el = el
                console.log('this.el', this.el)
                this.elPrev = elPrev
                console.log('this.elPrev ', this.elPrev )
        }
        },

        confirmCombine(el) {
            if(el.words) {
        this.showModal2 = false
        const endTime = this.el.stop
        const newWords = this.elPrev.words
        this.el.words.map(word => newWords.push(word))
        const textTranscribed =this.elPrev.original_transcribed_text +' ' + this.el.original_transcribed_text
        const textTranslate = this.elPrev.translated_text +' ' + this.el.translated_text
        const segmentPrev = this.segment-1
        const newSegment = {
            ...JSON.parse(JSON.stringify(this.elPrev)),
            // ...segmentToSplitCopy,
            original_transcribed_text: textTranscribed, 
            translated_text: textTranslate,
            stop: endTime,
            words: newWords
        };

        console.log('combine newSegment', newSegment)
        this.filteredVideoTexts.splice(segmentPrev, 2, newSegment);
        // console.log('this.filteredVideoTexts', this.filteredVideoTexts)
        this.$emit('updateVideoText', this.filteredVideoTexts)
            } else {
            return
        }

        },

        splitSegmentModal(el, segmentIndex) {
            if(this.readOnly) {
                return
            } else {
                this.showModal1 = true
                const text = el.original_transcribed_text
                // this.words = text.split(/\s+/)
                this.words = text.trim().split(' ').filter(word => word !== '');
                this.segment = segmentIndex
                this.el = el
            }
        },
   
    splitSegment(el) {
        if(el.words) {
        // console.log('el.words', el.words)
        this.showModal1 = false
        this.title = 'choose'
        // console.log('el', this.el.stop)
        const endTimeSecondPart = this.el.stop
        console.log('endTimeSecondPart', endTimeSecondPart)

            const text = this.el.original_transcribed_text
            // console.log('text', text)
            // this.words = text.split(/\s+/)
            this.words = text.trim().split(' ').filter(word => word !== '');
            console.log('this.words', this.words, this.words[2])

            const firstPart = this.words.slice(0, this.indexWord+1).join(' ')
            // console.log('firstPart', firstPart, this.indexWord)
            this.el.original_transcribed_text = firstPart
            
            // const arr1 = firstPart.trim().split(' ')
            // console.log('arr1', arr1)
            const secondPart = this.words.slice(this.indexWord+1).join(' ')
            // console.log('secondPart', secondPart)
            const endTimeFirst = this.el.words[this.indexWord].time_end
            // console.log('endTimeFirst', endTimeFirst)
            this.el.stop = parseInt((endTimeFirst*1000).toFixed(), 10)
            console.log('this.el.stop', this.el.stop)

        const segmentToSplit = this.filteredVideoTexts[this.segment];
        console.log('segmentToSplit', segmentToSplit)
        const newSegment = {
            ...JSON.parse(JSON.stringify(segmentToSplit)),
            // ...segmentToSplitCopy,
            original_transcribed_text: secondPart, 
            start: parseInt((this.el.words[this.indexWord + 1].time_begin * 1000).toFixed(), 10),

            // start: this.el.words[this.indexWord+1].time_begin*1000, 
            stop: endTimeSecondPart
        };
        console.log('newSegment', newSegment)

        this.filteredVideoTexts.splice(this.segment, 1, segmentToSplit, newSegment);
        this.$emit('updateVideoText', this.filteredVideoTexts)
            } else {
            return
        }
    },

        addSpeaker(el){
        console.log('addSpeaker' , el.speaker)
            const add = el.speaker
            console.log(add)
            el.speaker = add+1
        },
        minusSpeaker(el) {
            const minus = el.speaker
            console.log(minus)
            if(minus == 0){
                el.speaker = 0
            } else {
                el.speaker = minus-1
            }
        },
 //===       
        async sendToVoiceSegment(el, segmentIndex) {
            console.log('el', el)
            const segmentInfo = {
                segment_id: segmentIndex,
                content: el.translated_text,
                gender: el.gender,
                voice_name: el.current_voice,
                target_language_full_code: el.target_language,
                voice_sources: el.voice_sources,
                min_voice: el.min_voice_speed,
                max_voice: el.max_voice_speed
            }
            try {
            const sessionKey = window.sessionStorage.getItem('auth_key')
            console.log('sessionKey actions', sessionKey)

            if (!sessionKey) throw new Error("No session key available.");

            const response = await fetch(`${apiUrl}/api/get-sample-voice-for-segment`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey, video_id: this.videoId, segment_info: segmentInfo }),
            });
            // console.log('response', response)

            if (response.ok) {
                // const data = await response.json();
                const data = await response.blob();
                this.audioData = URL.createObjectURL(data); 
                this.isVoiceSegment = false
                // const audioBlob = await response.blob();
                // const url = URL.createObjectURL(audioBlob);
                const a = document.createElement("a");
                a.href = this.audioData;
                a.download = `${this.videoId}_translated_file_name.mp3`;
                
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            //     if (data.success) {
            //         console.log('data', data)
            //         this.isVoiceSegment = false
            //     } else {
            //         console.error("Backend Error: ", data.error);
            //     }
            // } else {
            //     console.error("Network Response Error");
            }
        } catch (err) {
            console.error(err);
        }
    },

        playAudio(voice) {
            console.log(voice)
            const audioUrl = process.env.BASE_URL + `voices/${voice}.mp3`;
            
            fetch(audioUrl)
                .then(response => {
                    if (response.status === 200) {
                        const audio = new Audio(audioUrl);
                        audio.autoplay = true;
                        audio.muted = false;
                        audio.play();
                    } else {
                        console.log('Аудіофайл не знайдено на сервері');
                    }
                })
                .catch(error => {
                    console.error('Помилка при завантаженні аудіофайлу', error);
                });
            },
        

        updateVoiceForGender(segment, currentGender) {

            console.log(segment, currentGender)
            const selectedGender = currentGender.toUpperCase();
            console.log('selectedGender', selectedGender)
            console.log(segment.all_voices[selectedGender][0])
            segment.current_voice = segment.all_voices[selectedGender][0];
        
            // console.log('updateVoiceForGender', segment.all_voices[selectedGender][0])
            // console.log('updateVoiceForGender', currentGender)
        },

        getCurrentGender(segment) {
            if (segment.all_voices.FEMALE.length !==0 && segment.all_voices.MALE.length !==0) {
                return segment.gender
            } else if( segment.all_voices.FEMALE.length !==0 && segment.all_voices.MALE.length ===0){
                return "female"
            } else if(segment.all_voices.FEMALE.length ===0 && segment.all_voices.MALE.length !==0){
                return "male"
            } else {
                console.log('have not all voices')
                return
            }
        },

        getGenderList(segment){
            // console.log('getGenderList')
            if(segment.all_voices) { 
                if (segment.all_voices.FEMALE.length !==0 && segment.all_voices.MALE.length !==0) {

                    return ["male", "female"]
                } else if( segment.all_voices.FEMALE.length !==0 && segment.all_voices.MALE.length ===0){
                    return [ "female"]
                } else if(segment.all_voices.FEMALE.length ===0 && segment.all_voices.MALE.length !==0){
                    return [ "male"]
                } 
            } else {
                console.log('have not all voice')
                    return 
            }
        },

        getVoiceList(segment) {
            // console.log('getVoiceList')
            if(segment.all_voices) { 
                if(segment.gender == 'female') {
                    if(segment.all_voices.FEMALE.length !== 0) {
                        return segment.all_voices.FEMALE
                    } else {
                        return segment.all_voices.MALE
                    }
                } if (segment.gender == 'male') {
                    if(segment.all_voices.MALE.length !== 0) {
                        return segment.all_voices.MALE
                    } else {
                        return segment.all_voices.FEMALE
                    }
                }
            } else {
                console.log('have not all voice')
                    return 
            }
        },
        closeSexDropdown(segmentIndex) {
        this.sexShow1[segmentIndex] = false;
        },
        
        closeVoiceDropdown(segmentIndex, item) {
            console.log('item', item)
            this.sexShow2[segmentIndex] = false;
        },

        deleteSegment(segmentIndex) {
           console.log('segmentIndex', segmentIndex)
           if(this.readOnly){
            return
           } else {
                this.segment = segmentIndex
                this.showModal = true
                console.log(this.filteredVideoTexts)
           }
        },

        confirmDelete () {
            this.showModal = false
            console.log('confirmDelete')
            const filteredItems = this.filteredVideoTexts.filter(
            (el, index) => index !==this.segment
            );
            console.log('filteredItems deleteSegment', filteredItems)
            this.$emit('updateVideoText', filteredItems)

        },

        copyText(text) {
            if (!navigator.clipboard) {
            console.log('navigator.clipboard не підтримується');
            return;
            }
            console.log('Текст для копіювання: ', text);

            navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Текст скопійовано в буфер обміну: ', text);
            })
            .catch((error) => {
                console.error('Помилка при копіюванні тексту: ', error);
            });
        },

            formatTime(timeInMilliseconds) {
                const date = new Date(timeInMilliseconds);
                const hours = date.getUTCHours().toString().padStart(2, '0');
                const minutes = date.getUTCMinutes().toString().padStart(2, '0');
                const seconds = date.getUTCSeconds().toString().padStart(2, '0');
                // const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
                // const firstTwoDigits = milliseconds.slice(0, 3);
                return `${hours}:${minutes}:${seconds}`;

            },

            toggleSexDropdown(segmentIndex) {
                // При кліку на кнопку відкриття списку "Sex", переключаємо стан для відповідного сегменту
                
                this.sexShow1[segmentIndex] = !this.sexShow1[segmentIndex];
                console.log('toggleSexDropdown', this.sexShow1[segmentIndex])
            },
            isSexDropdownVisible(segmentIndex) {
                // Перевіряємо, чи відповідний dropdown для сегменту видимий
                // console.log('isSexDropdownVisible', this.sexShow1[segmentIndex])
                return this.sexShow1[segmentIndex] === true;
            },

            toggleVoiceDropdown(segmentIndex) {
                // При кліку на кнопку відкриття списку "Sex", переключаємо стан для відповідного сегменту
                
                this.sexShow2[segmentIndex] = !this.sexShow2[segmentIndex];
                console.log('toggleSexDropdown', this.sexShow2[segmentIndex])
            },
            isVoiceDropdownVisible(segmentIndex) {
                // Перевіряємо, чи відповідний dropdown для сегменту видимий
                // console.log('isSexDropdownVisible', this.sexShow2[segmentIndex])
                return this.sexShow2[segmentIndex] === true;
            },

     }


    }


</script>

<style scoped>
 .parts-wrapper {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 12px;
    margin-left: 24px;
    margin-right: 24px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    margin-bottom: 12px;
    }

.parts-list {
    position: relative;
    list-style: none;
    padding-left: 25px;
    width: 100%;
    box-sizing: border-box;
}

.part-bt {
        box-sizing: border-box;
        display: flex;
        
        flex-direction: row;
        justify-content: start;
        padding: 0px 24px;
        width: 100%;
        margin-bottom: 10px
    }
     .bt-voice {
        height: 32px;
        cursor: pointer;
        display: block;
        border: 1px solid #1890FF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        background-color: #1890FF;
        color: white;

     }


.parts-item {
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;

}

.parts-item:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: calc(100%);
    height: calc(100%); 
    border-bottom: 2px solid #e1e8f6;
    pointer-events: none;
}


.parts-number {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: rgba(42, 42, 42, 0.5);
  background: #e1e8f6;
  padding: 10px;
}
.parts-number-inside {
    text-align: center;
    width: 25px;
}

.parts-box {
  position: relative;
  padding: 15px;
  background-color: #f9f9ff;
  width: 100%;
  box-sizing: border-box;
}

.parts-contetn {
  position: relative;
}

.parts-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.parts-time {
  color: #2a2a2a80;
  font-size: 12px;
}

.parts-arrow svg {
  width: 12px;
  height: 12px;
  opacity: 0.6;
  margin: 0 15px;
}

.parts-textarea {
  width: 100%;
  padding: 2px 20px 2px 4px;
  font-size: 14px;
  line-height: 1.4;
  resize: vertical;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  display: block;
  /* min-width: 320px; */
  height: 40px;
  min-height: 20px;
  overflow-y: hidden;
  
}

.rtl {
    direction: rtl;
}
.autogrow-textarea {
    position: relative;
}

.parts-textarea:focus {
  background: #fff;
  border-color: #00002b33;
  /* height: auto; */
  overflow-y: auto;
}


.copyIcon {
    height: 15px;
    opacity: 85%;
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
}
.parts-OriginalText {
  margin-top: 10px;
  font-size: 12px;
  color: #2a2a2a80;
  /* max-width: 320px; */
  text-align: left;
}

.main-title {
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;    
}

.part-main-title{
    color: #2a2a2a80;
    font-size: 16px;
    text-align: left;
}

.part-main-title-active {
    color: #000000;
    opacity: 85%;
}


.parts-time-wrapper {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.dropdown-btn {
    cursor: pointer;
    width: 100px;
    /* height: 27px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;
}
.dropdown-btn-p {
    margin: 0;
    padding: 2px;
}
.dropdown-btn-voice {
    cursor: pointer;
    width: 200px;
    /* height: 27px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;

}
.dropdown-btn-voice-p {
    margin: 0;
    padding: 2px;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100px;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 3px 5px;
    margin-bottom: 5px;
    z-index: 99;
}

.dropdown-content-voice {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 200px;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 3px 5px;
    margin-bottom: 5px;
    z-index: 99;
    overflow: scroll;
    max-height: 340px;
}


.dropdown-content-item {
    padding: 3px 0px;
}
.parts-drop-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

/* .parts-drop-wrapper .dropdown {
  margin-right: 20px;
} */

.parts-drop-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-right: 5px; */
}

.delete {
    height: 15px;
    width: 18px;
    position: absolute;
    right: 14px;
    bottom: 10px;
    cursor: pointer;
}

.readonly{
    height: 15px;
    width: 18px;
    position: absolute;
    right: 14px;
    bottom: 10px;
    cursor: not-allowed;
    opacity: 75%;

}

.clicked{
    background-color: lightgray;
    color: gray;
    border: 1px solid rgba(217, 217, 217, 1);
    cursor: not-allowed;
}

    
.arrowImg {
    display: block;
    height: 20px;
    width: 20px;
    opacity: 30%;
}

.audio {
    display: flex;
    align-items: center;
    justify-content: center;
}
.speed-block{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px
}

.voice-segment-block {
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    justify-content: space-between;

}
.audio-img {
    opacity: 85%;
    height: 25px;
    width: 25px;
    cursor: pointer;
}
.audio-img1{
    opacity: 85%;
    height: 25px;
    width: 25px;
    cursor: pointer;
 
}
.no-active {
    opacity: 45%;
    /* height: 25px;
    width: 25px; */
    cursor: not-allowed;
}


.parts-speaker {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    margin-right: 20px;
}

.iconMath {
    /* font-size: 22px; */
    cursor: pointer;
    padding: 0px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 20px;
    width: 20px; */
}

.addIcon{
    height: 15px;
 width: 15px;
}

.modal-background {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.02);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid gray;
    border-radius: 5px;
    transition: all 0.3s ease;
    font-size: 16px;
}

.modal-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.button-act{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
    margin-bottom: 10px;
}
.button-act-sec {
    position: relative;

}
.button-act button{
 min-width: 100px;
}
.modal-drop {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   margin-bottom: 10px;
}
.modal-button button {
    min-width: 80px;
}

.gender-block,
.voice-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}



@media screen and (max-width: 1080px) {
    .parts-header{
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .parts-drop-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    /* .parts-speaker, .dropdown, .audio {
        margin-bottom: 20px;
    } */

    .download-info {
        flex-direction: column;
    }

    .download {
        height: 140px;
    }

    .main-title {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;

    }

    .part-bt {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
    }

    .commonGender, .commonVoice {
        margin-bottom: 20px;
    }

    .parts-list {
        padding-left: 0;
    }

    .parts-number {
        padding: 2px;
    }
    
    .secondary-button {
        margin-top: 20px;
    }
    .button-act{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
    }
}

</style>
