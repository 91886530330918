<template>
    <div class="slider-container">
      <span class="slider-value">min </span>
      <div class="slider-block">
        <p>{{ sliderValue }}</p>
        <input
            type="range"
            class="slider"
            v-model="sliderValue"
            :min="minValueLocal"
            :max="maxValueLocal"
            :step="step"
            @change="updateValue"
            :disabled="readonly"
        />
      </div>
    <!-- <input
      type="range"
      class="slider"
      v-model="sliderValueMax"
      :min="value"
      :max="maxValueLocal"
      :step="step"
      @change="updateMaxValue"
      :disabled="readonly"
    /> -->
      <span class="slider-value">max </span>
    </div>
  </template>
  
  <script>
  export default {
    name: 'slider-app',
    props: {
        readonly: {
            type: Boolean,
        },
        segmentIndex: {
            type: Number,
        },
        elem: {
            type: Object,
        },
        value:{
            type: Number,
            // required: true,
            default: 7
        } ,
    },

        data() {
      return {
        minValueLocal: 1,
        maxValueLocal: 10,
        step: 1,
        sliderValue: this.value,
      };
    },
 methods: {
    updateValue() {
        console.log(this.sliderValue)
        this.$emit('updateVal', Number(this.sliderValue), Number(this.segmentIndex), this.elem)
    },

 },
  };
  </script>
  
  <style scoped>
  .slider-container {
    display: flex;
    align-items: center;
  }
  
  .slider {
    width: 100%;
    margin-right: 10px;
    
  }
  
  .slider-value {
    font-weight: bold;
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px;
  }
  .slider-block{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 0;
  }
  .slider-block p{
    padding: 0;
    margin: 0;
  }
  </style>
  

  <!-- <input
  type="range"
  class="slider"
  v-model="sliderValue"
  :min="minValueLocal"
  :max="maxValueLocal"
  :step="step"
  @change="updateValue"
  :disabled="readonly"
/> -->
