<template>
  <button :class="['btn', { 'disabled': isActive, 'click': isClicked }]" @mousedown="setButtonDown" @mouseup="setButtonUp">
    <slot></slot>
  </button>
</template>

<script>
export default {
    name: 'my-button',
    props: {
    isActive: Boolean,
  },
  data(){
    return {
        isClicked: false
    }
  },
  methods: {
    setButtonDown() {
        console.log('click button')
      this.isClicked = true;
    },
    setButtonUp() {
        console.log('click button')
      this.isClicked = false;
    }
  }

}
</script>

<style scoped>
    .btn{
        background: #1890FF;
        border: 1px solid #1890FF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        color: #FFFFFF;
        font-size: 14px;
        height: 32px;  
        padding: 5px 16px;
        /* width: 130px; */

        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: gray;
  border: 1px solid gray;
  background: lightgrey;
}

.click {
    background: #0257a7;
}




</style>