<template>
    <div class="wrap-newProject">
        <div class="select" :class="{ afterSelect: !dropzone }">
        <div class="title">
            <p>Video file</p>
        </div>

        <DropzoneVue
            @drop.prevent="handleDrop"
            @change="handleFile"
            v-if="dropzone && !uploadSuccessful"
        />

        <div v-if="isFileLoaded && !uploadSuccessful" class="progress-bar-block">
            <div class="img"></div>
            <div class="infoLoading">
           
            <div class="descr-file">
                    <h3 class="nameFile">{{ fileName }}</h3>
                    <p class="sizeFile">{{ fileSize }} MБ</p>
                </div>
                <ProgressBarVue :fileSize="parseFloat(fileSize)" :progressValue="progressValue"/>
            </div>
            <img class="img-cansel" src="../icon/cancel.png" @click="canselUpload"/>
        </div>
        <div class="message" v-if="uploadSuccessful || errorUpload">
            <div class="descr-file">
                <h3 class="nameFile">{{ fileName }}</h3>
                <p class="sizeFile">{{ fileSize }} MБ</p>
            </div>
            <p v-if="uploadSuccessful" class="successUpload">{{ successMessageUpload }}</p>
            <p v-if="errorUpload" class="errorUpload">{{ errorMessageUpload }}</p>
        </div>
        </div>


        <!-- SETTIngs block -->
        <div class="settings" :class="{ activeSettings: showSettingsBlock }">
        <div class="headBlock">
            <p class="headSetting" :class="{ activeHead: showSettingsBlock }">
            Settings
            </p>
            <div class="waiting" v-show="waitingSetting">
                <p>Waiting</p>
                <loading-dots/>
            </div>
        </div>
        <div class="settingActive" v-show="showSettingsBlock">
            <div class="descriptionFile">
            <div class="textBlock">
                <p class="text">Original text</p>
                <div class="aboutFile">
                <p class="lang">{{ originalLang }}</p>
                <!-- <p>{{ fileNameSet }}</p> -->
                </div>
            </div>
            <!-- <button class="editButton">Edit text</button> -->
            </div>
            <div class="translation">
                <p class="text">Translation</p>
                <div class="beforTranslate" v-show="beforTranslate">
                    <DropdownLanguageList
                    class="dropdownLanguageList"
                    v-model="selectedLang"
                    @languageSelected="handleLanguageSelected"
                    />
                    <my-button class="translateButton" v-bind:is-active="!isLanguageSelected" @click="translateFile">Translate</my-button>
                </div>
                <div class="afterTranslate" v-show="afterTranslate">
                    <div class="aboutFile">
                        <p class="langTarget">{{ selectedLang }}</p>
                        <!-- <p>{{ fileName }}</p> -->
                    </div>
                    <!-- <button class="deleteButton">Delete</button>
                    <button class="editButton">Edit text</button> -->
                </div>
            </div>
            <div v-if="successTranslait || errorTranslait" class="messageBlock">
                <p v-if="successTranslait" class="successTranslait">{{ successMessageTranslait }}</p>
                <p v-if="errorTranslait" class="errorTranslait">{{ errorMessageTranslait }}</p>
            </div>

            <ProgressBarVue :fileSize="fileSize" v-show="isProgressBar2" />

            </div>
        </div>

        <!-- Translation text -->
        <div class="parts-wrapper" >
            <div class="main-title" :class="{ 'main-title-active': isTranscriptionBlock && this.countSegmentsText>0}">
                <p class="part-main-title" :class="{'part-main-title-active': isTranscriptionBlock && this.countSegmentsText>0}">Transcription</p>
                <div class="part-bt" v-show="isTranscriptionBlock && this.countSegmentsText>0">
                    <div class="part-bt-line-block">
                        <!-- <div class="need-align-block">
                            <input id="needAlign" type="checkbox" v-model="needAlign" @change="updateNeedAlign" />
                            <label for="needAlign">Auto align</label>
                        </div> -->
                        <div class="speed-for-all-block">
                            <p>Speed for all:</p>
                            <slider-app
                                :readonly="isReadOnly"
                                @updateVal="updateSpeedForAll"
                            />
                        </div>
                    </div>

                    <div class="part-bt-line-block">
                        <div class="commonGender">
                            Gender for all:
                            <dropdown-list
                            :list="genderList"
                            :title="title1"
                            :readOnly="isReadOnly"
                            @updateItem="handleGender"
                            :imagePath="imagePath"/>
                        </div>
                        <div class="commonVoice">
                            Voice for all:
                            <dropdown-list
                            class="drop-voice"
                            :buttonClass="buttonClass1"
                            :buttonClass1="buttonClass2"
                            :list="voiceList"
                            :title="title2"
                            :readOnly="isReadOnly"
                            @updateItem="handleVoice"
                            :imagePath="imagePath"/>
                        </div>
                    </div>

                    <div class="part-bt-line-block">
                        <dropdown-list 
                            :title="title3"
                            :list="listFormat"
                            :imagePath="imagePath"
                            @updateItem="updateFormatTextDownload"
                            :buttonClass="buttonClassBtn"
                            :buttonClass1="buttonClassContent"
                        />

                        <dropdown-list 
                            :title="title4"
                            :list="listXls"
                            :imagePath="imagePath"
                            @updateItem="updateAlignTextDownload"
                            :buttonClass="buttonClassBtn"
                            :buttonClass1="buttonClassContent"
                        />
                    </div>
                    <div class="buton-google">
                        <a 
                            v-if="isGoogleSheet"
                            :href="'https://docs.google.com/spreadsheets/d/' + isGoogleSheet + '/edit'"
                            target = "_blank">The google sheet
                        </a>

                        <secondary-button 
                            @click="openGoogleSheet"
                            v-if="!isGoogleSheet"
                            :isActive="isActiveSecondaryButton"

                        >
                            Open google sheets
                        </secondary-button>
                        <secondary-button 
                            v-if="isGoogleSheet"
                            @click="startSynchronize"
                            :isActive="isActiveSynchronizeButton"

                        >
                            Synchronize with the table
                        </secondary-button>
                        <secondary-button
                            v-if="isGoogleSheet"
                            @click="updateGoogleSheets"
                            :isActive="isActiveUpdateButton"

                        >
                            Update google sheets
                        </secondary-button>


                    </div>

                </div>

                <div class="waiting" v-show="waitingTranslate">
                    <p>Waiting</p>
                    <loading-dots/>
                </div>

            </div>

            <segments-text 
                :videoTextTranslated="videoTextTranlate"
                :key="videoTextTranlate"
                :readOnly="isReadOnly"
                v-show="isTranscriptionBlock"
                @updateVideoText="updateVideoText"
                @countSegments="handleCountSegments"
                :baseLanguage="originalLang"
                :translateLanguage="selectedLang"
                :videoId="videoId"
            />

            <div class="part-bt-under" v-show="isTranscriptionBlock && this.countSegmentsText>0">
                <my-button class="save-project-button"
                    :class="{'clicked': isVoiceSend}"
                    @click="saveInfo"
                    :isActive="isSaveInfo"
                >
                    Save project without montage
                    <img class="img-save" :class="{'img-save-no-active': isVoiceSend}" src="../icon/save_white.png"/>
                </my-button>
                <my-button 
                    class="bt-voice" @click="confirmAct"
                    :class="{'clicked': isVoiceSend}">
                    Start video montage
                </my-button>
            </div>

            <div v-if="successConvert || errorConvert" class="messageBlock">
                <p v-if="successConvert" class="successConvert">{{ successMessageConvert }}</p>
                <p v-if="errorConvert" class="errorConvert">{{ errorMessageConvert }}</p>
            </div>

        </div>

        <plashka-modal v-if="showPlashka"
            :show-reminder="plashkaVisible" 
            @update:show-reminder="plashkaVisible = $event"
        > 
            Choose voise, please
        </plashka-modal>

        <plashka-confirm v-if="showConfirm"
            :showConfirm="showConfirm"
            @actConfirm="sendDataToConvert"
            @update:show-reminder="showConfirm = $event"
            >
                Do you really want to mount the project?
        </plashka-confirm>


        <div class="download" :class="{'download-active': isDownload}" >
            <div class="headBlock">
                <p class="headDownload" :class="{'headDownload-active': isDownload}">Download</p>
                <div class="waiting" v-show="waitingVoice">
                    <p>Waiting</p>
                    <loading-dots/>
                </div>
                <div class="download-info" v-if="isDownload">
                    <button class="download-button" @click="downloadAudio" v-if="fileType ==='audio' || fileType ==='video'">Download audio</button>
                    <button class="download-button" @click="downloadVideo" v-if="fileType ==='video'">Download video</button>
                </div>
                <div class="waitingFile" v-show="waitingDownloadFile">
                    <p class="weaitFile">Please wait, the file is being prepared for downloading</p>
                </div>
                <div v-show="waitingDownloadFile">
                    <loading-spinner/>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import DropdownLanguageList from "./DropdownLanguageList.vue";
    import ProgressBarVue from "./ProgressBar.vue";
    import DropzoneVue from "./DropzoneVue.vue";
    import axios from 'axios'
    import { apiUrl } from './api'
    import SegmentsText from './SegmentsText.vue';
    import PlashkaModal from './UI/PlashkaModal.vue';
    import DropdownList from './UI/DropdownList.vue';
import MyButton from './UI/MyButton.vue';


    export default {
        name: "NewProject",
        components: {
            DropdownLanguageList,
            ProgressBarVue,
            DropzoneVue,
            SegmentsText,
            PlashkaModal,
            DropdownList,
            MyButton
        },
    data() {
        return {
            access: false,
            errorMessageUpload: "",
            successMessageUpload: "",
            showProgress: true,
            isFileLoaded: false,
            uploadSuccessful: false,
            errorUpload: false,
            dropzone: true,
            authHeader: "",
            authKey: "",
            file: null,
            fileName: "",
            fileSize: 0,
            fileType: "",
            videoId: '',
            progressValue: 0,
            progressPercentage: 0,
            videoNameSet: "",
            originalLang: "",
            videoDuration: "",
            showSettingsBlock: false, //off
            isLanguageSelected: false, // off
            isProgressBar2: false,
        //   isVoiceSelected: false,
            beforTranslate: true,
            afterTranslate: false,
            isSelectVoice: false,
            dropzoneFile: "",
            textarea: "",
            sexShow1: {},
            sexItems1: ["male", "female"],
            sexSelected1: "",
            sexShow2: false,
            sexSelected2: "",
            selectedLang: null,
            fileNameSet: "",
            translaitLang: "",
            isTranscriptionBlock: false,
            videoTextTranlate: {},
            successTranslait: false,
            errorTranslait: false,
            successMessageTranslait: "",
            errorMessageTranslait: "",
            successConvert: false,
            errorConvert: false,
            successMessageConvert: "",
            errorMessageConvert: "",
            status: null,
            isVoiceSend: false,
            isDownload: false, // off!
            isReadOnly: false,
            waitingSetting: false,
            waitingTranslate: false,
            waitingVoice: false,
            countSegmentsText: 0,
            showPlashka: false,
            plashkaVisible: true,
            imagePath: require('../icon/arrow_down.png'),
            title1: 'choose',
            title2: 'choose',
            buttonClass1: 'dropdown-btn-width',
            buttonClass2: 'dropdown-btn-content-width',

            listFormat: ['basic text', 'translated text'],
            title3: 'Download .srt',
            buttonClassBtn: 'dropdown-btn-srt',
            buttonClassContent: 'dropdown-btn-content-srt',

            title4: "Download .xls",
            listXls: ["row oriented", "column oriented"],

            male: 0, 
            female: 0,

            hide: false,
            needAlign: true,
            controller: null,
            intervalId: null,
            showConfirm: false,
            isGoogleSheet: null,
            isComponentActive: false,
            isActiveSecondaryButton: false,
            isActiveSynchronizeButton: false,
            isActiveUpdateButton: false,
            isSaveInfo: false



        };
    },
    created() {
        const authKeyCookie = document.cookie
        .split("; ")
        .find((cookie) => cookie.startsWith("auth_key="));
        console.log("authKeyCookie", authKeyCookie);
        const authKeySession = window.sessionStorage.getItem("auth_key");
        console.log("authKeySession", authKeySession);
        if (authKeySession) {
        this.authKey = authKeySession.substring(0, 10);
        console.log("this.authKey", this.authKey);
        } else if (authKeyCookie) {
        const keyValue = authKeyCookie.split("=")[1];
        this.authKey = keyValue.substring(0, 10);
        console.log("this.authKey", this.authKey);
        } else {
        this.error =
            "Frontend: No key. Please enter the key.";
        }
    },

    mounted() {
        // this.grow();
        document.addEventListener("click", this.outsideClickListener);
        console.log('this.status', this.status)
        this.isComponentActive = false;
        console.log('mounted this.isComponentActive', this.isComponentActive)
    },

    beforeUnmount() {
                        // При видаленні компонента зупиняємо інтервал, щоб уникнути витоку ресурсів
                clearInterval(this.intervalId);
                this.isComponentActive = false;
                console.log('beforeUnmount', this.isComponentActive, this.intervalId )

            },

    // beforeRouteLeave(to, from, next) {
    //             this.isComponentActive = false;
    //             next();
    //         },

    computed: {
        
        waitingDownloadFile() {
            console.log('waitingDownloadFile', this.$store.state.video.waitingDownloadFile)
            return this.$store.state.video.waitingDownloadFile
        },
        voiceList() {
            const uniqueSegments = [];
            const segmentArray = Object.values(this.videoTextTranlate);
            const title1UpperCase = this.title1.toUpperCase();

            segmentArray.forEach(item => {
                if (item.all_voices[title1UpperCase]) {
                const targetVoice = item.all_voices[title1UpperCase];
                
                targetVoice.forEach(segment => {
                    const isUnique = !uniqueSegments.some(existingSegment => {
                    return JSON.stringify(existingSegment) === JSON.stringify(segment);
                    });

                    if (isUnique) {
                    uniqueSegments.push(segment);
                    }
                });
                }
            });
            console.log('unique segments List', uniqueSegments);
            return uniqueSegments;
        },
        genderList() {
            const segmentArray = Object.values(this.videoTextTranlate)
            segmentArray.map(item=> {
                if(item.all_voices.FEMALE.length == 0 ) { 
                    this.female++
                    console.log('this.female', this.female)
                } else if(item.all_voices.MALE.length == 0){
                    this.male++
                    console.log('this.male',this.male)
                }
            })
            if (this.male ==0 && this.female ==0) {
                return ['male', 'female']
            } else if (this.male > 0 && this.female ==0) {
                return ['female']
            } else  return ['male']
            
        }

    },
    watch: {
        status: function(newStatus, oldStatus) {
            console.log('oldStatus', oldStatus)
            if (newStatus === 6 || newStatus === 9) {
            console.log('this.status 6 || 9', newStatus);
            this.intervalId = setInterval(this.saveInfo, 30000);
            } else {
            // Відміняємо інтервал, якщо статус не дорівнює 6 або 9
            clearInterval(this.intervalId);
            }        
        }

    },




    methods: {
        delayTime(sec) {
            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
            return delay(sec * 1000); 
        },

        confirmAct() {
                if(!this.isVoiceSend){
                    this.showConfirm = true
                } else {
                    console.log('not active button')
                    return
                }
            },

        async openGoogleSheet() {
            if(!this.isActiveSecondaryButton) { 
                this.isActiveSecondaryButton = true

                try{

                    const sessionKey = sessionStorage.getItem("auth_key");
                    if (!sessionKey) throw new Error("No session key available.");
            
                    const response = await fetch(`${apiUrl}/api/open-google-sheets`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ auth_key: sessionKey, video_id: this.videoId }),
                    });
                    if (response.ok) {
                        const data = await response.json();
                        console.log('openGoogleSheet ok data', data)
                        // this.isActiveSecondaryButton = true

                        const googleSheetId = data.google_sheet_id; 
                        this.isGoogleSheet = googleSheetId
                        const googleSheetLink = `https://docs.google.com/spreadsheets/d/${googleSheetId}/edit`;
                        this.linkOnTheTable = googleSheetLink
                        console.log(this.isGoogleSheet, this.linkOnTheTable);
                        // Vue.set(this, 'isGoogleSheet', 'googleSheetId');

                    } else {
                        console.error("Backend Error: ", response.statusText);
                        this.isActiveSecondaryButton = false
                    }
                 
                }catch(error){
                    console.error(error);
                    this.isActiveSecondaryButton = false

                }
            } else {
                console.log('this.isActiveSecondaryButton', this.isActiveSecondaryButton)
                return
                }
            },

            async updateGoogleSheets(){
                if((this.status === 6 || this.status === 9) && !this.isActiveUpdateButton) {
                    this.isActiveUpdateButton = true
                    console.log('updateGoogleSheets')
                    try{

                        const sessionKey = sessionStorage.getItem("auth_key");
                    if (!sessionKey) throw new Error("No session key available.");
            
                    const response = await fetch(`${apiUrl}/api/update-video-json-to-google-sheet`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ auth_key: sessionKey, video_id: this.videoId }),
                    });
                    if(response.ok) {
                        console.log('response.ok')
                        const data = await response.json();
                        console.log('updateGoogleSheets data - ', data)
                        await this.delayTime(5)
                        this.isActiveUpdateButton = false

                    } else {
                        console.log('error')
                        this.isActiveUpdateButton = false

                    }
                } catch (error) {
                    console.error(error)
                    this.isActiveUpdateButton = false

                }
            } else {
                console.log('no, staus not 6 || 9')
                return
            }
            },

            async startSynchronize() {
                if((this.status === 6 || this.status === 9) && !this.isActiveSynchronizeButton) {
                    console.log('startSynchronize')
                    this.isActiveSynchronizeButton = true

                try {

                    const sessionKey = sessionStorage.getItem("auth_key");
                    if (!sessionKey) throw new Error("No session key available.");
            
                    const response = await fetch(`${apiUrl}/api/update-video-json-from-google-sheet`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({ auth_key: sessionKey, video_id: this.videoId }),
                    });
                    if(response.ok) {
                        console.log('response.ok')
                        const data = await response.json();
                        console.log('startSynchronize data - ', data)
                        this.videoTextTranlate = data.updated_video_json
                        await this.delayTime(5)
                        this.isActiveSynchronizeButton = false
                    } else {
                        console.og('error startSynchronize')
                        this.isActiveSynchronizeButton = false
                    }

                }catch(error) {
                    console.error(error);
                    this.isActiveSynchronizeButton = false
                }
            } else {
                console.log('no, staus not 6 || 9 && !this.isActiveSynchronizeButton')
                return
            }

            },


        async saveInfo(){
                console.log('saveInfo')
                if(!this.isVoiceSend && !this.isSaveInfo){ 
                try{    
                
                    const sessionKey = window.sessionStorage.getItem('auth_key')
                    // console.log('sessionKey actions', sessionKey)
                    if (!sessionKey) throw new Error("No session key available.");
                    // console.log('videoTextTranlate', this.videoTextTranlate)
                        const dataToSend = {
                        video_info: this.videoTextTranlate,
                        auth_key: sessionKey, 
                        video_id: this.videoId
                    };
                
                    const response = await fetch(`${apiUrl}/api/save-info-translated`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(dataToSend)
                    });
                
                    if (response.ok) {
                        const data = await response.json();
                        console.log('Data saved:', data);
                        
                        if (data.success) {
                        //     this.waitingVoice = true
                        //     this.isVoiceSend = true
                        this.isSaveInfo = true
                            this.successConvert = true
                            this.errorConvert = false
                            this.successMessageConvert = data.message
                            this.errorMessageConvert = ""

                            await this.delayTime(3)
                            this.successMessageConvert = ""

                            await this.delayTime(10)
                            this.isSaveInfo = false

                        } else {
                            console.error("Backend Error: ", data.error);
                            this.errorConvert = true
                            this.successConvert = false
                            this.errorMessageConvert = data.error
                            this.successMessageConvert = ""
                        }
                    }
                } catch (error) {
                    console.error('Помилка під час відправки даних на сервер:', error);
                    this.errorMessageConvert = "Network Response Error"
                    this.errorConvert = true
                    this.successConvert = false
                    this.successMessageConvert = ""


                }
            } else {
                return
            }

            },

        updateSpeedForAll(item){
                console.log(item)
                console.log(this.videoTextTranlate)
                let speed = this.setLevel(item)
                const segmentArray = Object.values(this.videoTextTranlate)
                segmentArray.map(el=> {el.min_voice_speed = speed.min
                    el.max_voice_speed = speed.max})
                console.log('segmentArray', segmentArray)
                this.updateVideoText(segmentArray)

                console.log(this.videoTextTranlate)

            },
        setLevel(value){
            console.log(value)
            switch (value) {
                case 1:
                    return {min: -0.05, max: 0.05};
                case 2:
                    return  {min: -0.1, max: 0.1};
                case 3:
                    return {min: -0.15, max: 0.15};
                case 4:
                    return {min: -0.2, max: 0.2};
                case 5:
                    return {min: -0.25, max: 0.25};
                case 6:
                    return {min: -0.3, max: 0.3};
                case 7:
                    return {min: -0.35, max: 0.35};
                case 8:
                    return {min: -0.4, max: 0.4};
                case 9:
                    return {min: -0.45, max: 0.45};
                case 10:
                    return {min: -0.5, max: 0.5};
                default:
                    return 'No value';
            }
        },

        updateNeedAlign() {
                    console.log('updateNeedAlign-', this.needAlign)
            },
        updateAlignTextDownload(item) {
                console.log(item)
                this.title4 = item
                this.downloadXls(item)
            },

        updateFormatTextDownload(item) {
                console.log(item)
                this.title3 = item
                this.downloadSrt(item)
            },

        handleVoice(item) {
                    this.title2 = item
                    this.updateVoiceVideoText(item)
                },
        handleGender (item) {
            console.log('item', item)
            this.title1 = item
            this.title2 = 'choose'
            this.updateGenderVideoText(item)
            this.updateVoiceVideoText(this.title2)

        },
        updateVoiceVideoText(item){
            console.log('updateGenderVideoText', item)
            const segmentArray = Object.values(this.videoTextTranlate)
            segmentArray.map(el=> {el.current_voice = item})
            this.updateVideoText(segmentArray)
        },

        updateGenderVideoText(item){
            console.log('updateGenderVideoText', item)
            const segmentArray = Object.values(this.videoTextTranlate)
            segmentArray.map(el=> {el.gender = item})
            this.updateVideoText(segmentArray)
        },

        handleCountSegments(count){
                    this.countSegmentsText = count
                    console.log('this.countSegmentsText', this.countSegmentsText)
                },

        updateVideoText(videoText) {
            console.log('updateVideoText', videoText)
            const obj = {};

            videoText.forEach(function(item, index) {
            const key =  index;
            obj[key] = item;
            });
            this.videoTextTranlate = obj   
            console.log('updateVideoText', obj)
        }, 

        copyText(text) {
                    const textToCopy = text;
                    console.log('textToCopy', textToCopy)

                    navigator.clipboard.writeText(textToCopy)
                        .then(() => {
                            console.log("Текст скопирован в буфер обмена");
                        })
                        .catch((err) =>{
                            console.error("Ошибка копирования текста", err);
                            alert("не удалось скопировать текст, попробуйте еще разю")
                        })
                },

        formatTime(timeInMilliseconds) {
            const date = new Date(timeInMilliseconds);
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            const seconds = date.getUTCSeconds().toString().padStart(2, '0');
            // const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
            // const firstTwoDigits = milliseconds.slice(0, 2);
            return `${hours}:${minutes}:${seconds}`;
        },

        toggleSexDropdown(segmentIndex) {
            this.sexShow1[segmentIndex] = !this.sexShow1[segmentIndex];
            // console.log('toggleSexDropdown', this.sexShow1[segmentIndex])
        },
        isSexDropdownVisible(segmentIndex) {
            // Перевіряємо, чи відповідний dropdown для сегменту видимий
            // console.log('isSexDropdownVisible', this.sexShow1[segmentIndex])
            return this.sexShow1[segmentIndex] === true;
        },


        handleFile(event) {
        //selectsd file on input and prepare for loading
        console.log("handleFile");
        let file;
        if (event.target) {
            file = event.target.files[0];
        } else {
            file = event;
        }
        this.file = file;
        this.fileName = file.name;
        this.fileSize = (file.size / (1024 * 1024)).toFixed(2);
        console.log("fileSize", this.fileSize);
        this.isFileLoaded = true;
        this.videoName = file.name.split(".").slice(0, -1).join(".");

        if (event.target) {
            this.uploadFile(file);
        }
        },
        handleFileDownload(file) {
        //file is prepared  for loading
        this.file = file;
        this.fileName = file.name;
        this.fileSize = parseFloat((file.size / (1024 * 1024)).toFixed(2));
        this.videoName = file.name.split(".").slice(0, -1).join("."); // add try
        this.uploadFile(file);
        },
        handleDrop(event) {
        //drag-and-drop file
        console.log("handleDrop");
        console.log(event);
        // event.preventDefault();
        const files = event.dataTransfer.files;
        console.log(files);
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (this.isAcceptedFileType(file)) {
            console.log("Допустимый файл:", file.name);
            this.handleFileDownload(file); // здесь вызывается функция загрузки файла
            } else {
            console.log("Недопустимый файл:", file.name);
            alert("Invalid file format")
            }
        }
        },
        isAcceptedFileType(file) {
            //checking type of file for drag-and-drop
            console.log("isAcceptedFileType", file);
            const acceptedExtensions = [".mp4", ".mov", ".mp3", ".wav"];
            const fileExtension = "." + file.name.split(".").pop().toLowerCase();
            console.log(fileExtension);
            console.log(acceptedExtensions.includes(fileExtension))

            return (
                acceptedExtensions.includes(fileExtension) 
            );
        },

        async uploadFile(file) {
            console.log('uploadFile');
            this.dropzone = false;
            this.isFileLoaded = true;
            this.file = file;
            this.fileName = file.name;
            this.fileSize = parseFloat(file.size / (1024 * 1024)).toFixed(2);
            console.log('this.fileSize', this.fileSize);

            const maxFileSizeMB = 1000; // Максимальный размер файла в мегабайтах

            if (this.fileSize > maxFileSizeMB) {
                console.error('File is too large');
                this.errorMessageUpload = `The file size exceeds the maximum limit of ${maxFileSizeMB} MB.`;
                this.uploadSuccessful = false;
                this.errorUpload = true;
                this.isFileLoaded = false;
                return; // Прекратить выполнение функции, если файл слишком большой
            }

            // Создание URL из файла для проверки длительности
            const objectURL = URL.createObjectURL(file);
            const mediaElement = document.createElement(file.type.startsWith('audio') ? 'audio' : 'video');
            mediaElement.src = objectURL;

            mediaElement.addEventListener('loadedmetadata', async () => {
                const duration = mediaElement.duration; // Длительность в секундах
                const maxDurationSeconds = 32 * 60; // 31 минута

                if (duration > maxDurationSeconds) {
                    console.error('File is too long');
                    this.errorMessageUpload = `The file duration exceeds the maximum limit of 30 minutes.`;
                    this.uploadSuccessful = false;
                    this.errorUpload = true;
                    this.isFileLoaded = false;
                    URL.revokeObjectURL(objectURL); // Освобождаем память
                    return;
                }

                // Если файл прошел все проверки, продолжаем загрузку
                URL.revokeObjectURL(objectURL); // Освобождаем память

                const formData = new FormData();
                formData.append('file', this.file);
                formData.append('auth_key', this.authKey);
                formData.append('video_name', this.videoName);

                this.controller = new AbortController();

                const config = {
                    onUploadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.updateProgressBar(progress);
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    signal: this.controller.signal
                };

                try {
                    const response = await axios.post(`${apiUrl}/api/upload-video`, formData, config);
                    const data = response.data;

                    console.log("Server response:", data);
                    if (data.success) {
                        this.successMessageUpload = data.message;
                        this.waitingSetting = true;
                        this.errorMessageUpload = "";
                        this.uploadSuccessful = true;
                        this.videoName = data.video_name;
                        this.videoId = data.video_id;
                        this.getVideoInfo() // Запускаем функцию для определения языка оригинала
                        await this.delayTime(3)
                        this.successMessageUpload = ""

                    } else {
                        console.log('1 error');
                        this.errorMessageUpload = data.error;
                        this.errorUpload = true;
                        this.isFileLoaded = false;
                        this.uploadSuccessful = false;
                        this.successMessage = "";
                    }
                } catch (error) {
                    console.error('Error catch:', error);
                    if (error.name === 'CanceledError') {
                        console.log('Завантаження скасовано.');
                        this.errorMessageUpload = "Download canceled by user";
                        this.uploadSuccessful = false;
                        this.errorUpload = true;
                        this.isFileLoaded = false;
                        this.successMessageUpload = "";
                    } else {
                        console.log('2 error catch-', error);
                        this.uploadSuccessful = false;
                        this.errorUpload = true;
                        this.isFileLoaded = false;
                        this.errorMessageUpload = error.message;
                        this.successMessageUpload = "";
                    }
                } finally {
                    this.controller = null;
                }
            });
        },
        updateProgressBar(progress) {
            this.progressValue = progress;
            console.log('this.progressValue', this.progressValue)
        },
        canselUpload() {
            if(this.progressValue<100) {
            if(this.controller){
                console.log('canselUpload')
            this.controller.abort()
            } else {
                return
            }
        } else {
            console.log('file is downloaded')
            return
        }
        },
        

        handleLanguageSelected(selectedLanguage) { // обираємо мову для перекладу
        this.isLanguageSelected = true;
        this.selectedLang = selectedLanguage;
        //   this.translaitLang = selectedLanguage
        // console.log("Вибрана мова:", selectedLanguage);
        },

        async translateFile() { 
            if( !this.selectedLang) {
                // console.log('!this.selectedLang')
                return
            } else { 
                    // відправляємо файл на переклад тектовий
                try {
                    // this.isProgressBar2 = true;
                    const sessionKey = window.sessionStorage.getItem('auth_key')
                    // console.log('sessionKey actions', sessionKey)
                    // console.log('Вибрана мова:', this.selectedLang);

                    if (!sessionKey) throw new Error("No session key available.");

                const response = await fetch(`${apiUrl}/api/translate-video`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        video_id: this.videoId,
                        target_lang: this.selectedLang,
                        auth_key: sessionKey
                    }),
                })
                    if (response.ok) {
                        const data = await response.json();
                        console.log('data', data)
                        if (data.success) {
                            this.waitingTranslate = true
                            this.responseData = data;
                            this.errorMessageTranslait = ""
                            this.errorTranslait = false
                            this.translaitLang = data.target_lang
                            this.successMessageTranslait = data.message
                            this.successTranslait = true
                            this.beforTranslate = false
                            this.afterTranslate = true
                            console.log('data file language', data)

                            this.getVideoInfo();  // запускаємо вивід оригінального тексту і перекладу, блок транскрипшн
                            await this.delayTime(3)
                            this.successMessageTranslait = ""

                        } else {
                            console.error("Backend Error: ", data.error);
                            this.errorMessageTranslait = data.error
                            this.errorTranslait = true
                            this.successMessageTranslait = ""
                            this.successTranslait = false

                        }
                        } else {
                        console.error("Network Response Error");
                            this.errorMessageTranslait = "Network Response Error"
                            this.errorTranslait = true
                            this.successMessageTranslait = ""
                            this.successTranslait = false

                        }
                    } catch (err) {
                        console.error('catch', err);
                    }
                }
            },

            async sendDataToConvert() {    //send translation text for voicing
                    try{
                        const sessionKey = window.sessionStorage.getItem('auth_key')
                        console.log('sessionKey actions', sessionKey)
                        if (!sessionKey) throw new Error("No session key available.");
                        console.log('videoTextTranlate', this.videoTextTranlate)

                        const segmentArray = Object.values(this.videoTextTranlate)
                        const segments = segmentArray.filter(item=> item.current_voice =="" || item.current_voice =="choose voice" ||item.current_voice == null ||item.current_voice == 'choose')
                        console.log('segments', segments, segments.length)
                        if (segments.length>0) {
                            // alert('choose voice!!!')
                            this.plashkaVisible = true
                            this.showConfirm = false

                        } else {
                            this.isVoiceSend = true;
                            this.isReadOnly = true
                            this.isDownload = false
                            this.showConfirm = false

                        const dataToSend = {
                            video_info: this.videoTextTranlate,
                            auth_key: sessionKey, 
                            video_id: this.videoId,
                            need_align: this.needAlign
                        };

                        const response = await fetch(`${apiUrl}/api/save-info-about-video`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(dataToSend)
                    });
                    if (response.ok) {   //що має запустити відкриття загрузки файлу - статус?
                        this.isVoiceSend = true
                        this.isReadOnly = true
                        const data = await response.json();
                        console.log('Дані для озвучки успішно відправлені на сервер:', data);

                        if (data.success) {
                            this.waitingVoice = true
                            this.successConvert = true
                            this.errorConvert = false
                            this.successMessageConvert = data.message
                            this.errorMessageConvert = ""
                            this.getVideoInfo();
                            await this.delayTime(3)
                            this.successMessageConvert = ""
                        } else {
                            console.error("Backend Error: ", data.error);
                            this.errorConvert = true
                            this.successConvert = false
                            this.errorMessageConvert = data.error
                            this.successMessageConvert = ""
                        }
                        } else {
                            console.error("Network Response Error");
                            this.errorConvert = true
                            this.successConvert = false
                            this.errorMessageConvert = "Network Response Error"
                            this.successMessageConvert = ""

                        }
                    } 
                    }
                    catch (error) {
                        console.error('Помилка під час відправки даних на сервер:', error);
                        this.errorMessageConvert = "Network Response Error"
                    }
                },
                
                async getVideoInfo() {
                    this.isComponentActive = true
                    try{

                        const sessionKey = window.sessionStorage.getItem('auth_key')
                        console.log('sessionKey actions', sessionKey)
                        if (!sessionKey) throw new Error("No session key available.");

                        const response = await fetch(`${apiUrl}/api/get-full-info-about-video`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ 
                                auth_key: sessionKey, 
                                video_id: this.videoId }),
                        });
                        if (response.ok) {
                            const data = await response.json();
                            // console.log('data', data)
                        if (data.success) {
                            // this.fileName = data.video_name;
                            // this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                            console.log('data getVideoInfo', data.status)

                            if(data.status == 1 || data.status == 2){
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.showSettingsBlock = false
                                this.waitingSetting = true
                                this.fileType = data.file_type
                                await this.delayTime(10);
                                if(this.isComponentActive) {
                                    this.getVideoInfo() 
                                } else {
                                    console.log('stop api')
                                    return
                                }
                                
                            }
                            if(data.status == 3){
                                this.showSettingsBlock = true
                                this.waitingSetting = false
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.originalLang = data.video_basic_lang
                            }
                            if(data.status == 4 || data.status == 5){
                                await this.delayTime(20);
                                if(this.isComponentActive) {
                                    this.getVideoInfo() 
                                } else {
                                    console.log('stop api')
                                    return
                                }
                                this.waitingTranslate = true
                                this.selectedLang = data.video_target_lang


                            //     this.showSettingsBlock = true
                            //     this.beforTranslate = false
                            //     this.afterTranslate = true
                            //     this.fileName = data.video_name;
                            //     this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                            //     this.basicLang = data.video_basic_lang
                            //     this.translaitLang = data.video_target_lang
                            }
                            if(data.status == 6){ // отримали сегменти
                                // this.showSettingsBlock = true
                                // this.beforTranslate = false
                                // this.afterTranslate = true
                                // this.fileName = data.video_name;
                                // this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                // this.basicLang = data.video_basic_lang
                                // this.translaitLang = data.video_target_lang
                                this.isTranscriptionBlock = true
                                // this.videoTextTranlate = data.video_info
                                const decoderTranslated = data.video_info
                                const lists = data.video_info
                                this.$store.dispatch('video/getSegmentLists', lists)
                                this.$store.dispatch('video/getAllVoices', lists)
                                // console.log(data.video_info)
                                this.decoderSimbol(decoderTranslated)
                                this.status = data.status
                                console.log(this.status)
                                this.waitingTranslate = false
                            }
                            if(data.status == 7 || data.status == 8){ // відправили і процес озвучки
                                await this.delayTime(10);
                                if(this.isComponentActive) {
                                    this.getVideoInfo() 
                                } else {
                                    console.log('stop api')
                                    return
                                }

                            //     this.showSettingsBlock = true
                            //     this.beforTranslate = false
                            //     this.afterTranslate = true
                            //     this.fileName = data.video_name;
                            //     this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                            //     this.basicLang = data.video_basic_lang
                            //     this.translaitLang = data.video_target_lang
                            //     this.isTranscriptionBlock = true
                            //     this.isVoiceSend = true
                            //     this.isReadOnly = true
                            //     this.videoTextTranlate = data.video_info
                            //     this.status = data.status
                            //     console.log(this.status)
                            }
                            if(data.status == 9){ // є голоте відео для скачування
                                this.status = data.status
                                console.log(this.status)
                                this.waitingVoice = false
                                // this.showSettingsBlock = true
                                // this.beforTranslate = false
                                // this.afterTranslate = true
                                // this.fileName = data.video_name;
                                // this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                // this.basicLang = data.video_basic_lang
                                // this.translaitLang = data.video_target_lang
                                this.isTranscriptionBlock = true
                                // this.videoTextTranlate = data.video_info
                                const decoderTranslated = data.video_info
                                // console.log(data.video_info)
                                this.decoderSimbol(decoderTranslated)
                                this.isVoiceSend = false // разблокувати
                                this.isReadOnly = false // разблокувати
                                this.isDownload = true
                                
                            }
                        } else {

                            console.error("Backend Error: ", data.error);
                        }
                        } else {
                            console.error("Network Response Error");
                        }
                        } catch (err) {
                            console.error(err);
                        }
                },

                decoderSimbol(decoderTranslated) {
                    for (let value in decoderTranslated){
                        const coder = decoderTranslated[value].translated_text
                        console.log('coder',coder)
                        if(coder){
                            console.log('have coder')
                            if(coder.includes('&#39;')){
                                console.log('coder includes "&#39;"')
                                const coder1 = coder.replace(/&#39;/g, "'")
                                console.log(coder.replace(/&#39;/g, "'"))
                                decoderTranslated[value].translated_text = coder1
                            } else {
                                console.log('coder do not includes simbols')
                            }    
                        }
                    }
                    this.videoTextTranlate = decoderTranslated
                },


                // async downloadVideo() {
                //     try {
                //         // this.isVoiceSend = true;
                //         const sessionKey = window.sessionStorage.getItem('auth_key')
                //         console.log('sessionKey actions', sessionKey)

                //         if (!sessionKey) throw new Error("No session key available.");
                //         console.log('videoTextTranlate', this.videoTextTranlate)
                //         // const dataToSend = {
                //         //     // auth_key: sessionKey, 
                //         //     video_id: this.videoId
                //         // };

                //         const response = await fetch(`${apiUrl}/download-video/${this.video_id}`, {
                //         method: 'GET',
                //         headers: {
                //             "Content-Type": "application/json",
                //             "Authorization": `Bearer ${sessionKey}`, 
                //         },
                //     });
                //     if (!response.ok) {
                //         throw new Error('Failed to download video');
                //         }

                //         const blob = await response.blob();
                //         const url = window.URL.createObjectURL(blob);
                //         const link = document.createElement('a');
                //         link.href = url;
                //         link.setAttribute('download', `video_${this.videoId}.mp4`);
                //         document.body.appendChild(link);
                //         link.click();
                //         window.URL.revokeObjectURL(url);
                        
                //     } catch (error) {
                //         console.error(error.message);
                //     }
                // },
                async downloadAudio() {
                    try {
                    await this.$store.dispatch('video/downloadAudio', this.videoId);
                    } catch (error) {
                    console.error(error);
                    }
                },

                async downloadVideo() {
                    try {
                        console.log('downloadVideo', this.videoId )
                    await this.$store.dispatch('video/downloadVideo', this.videoId);
                    } catch (error) {
                    console.error(error);
                    }
                },
                async downloadXls(item) {
                try {
                    console.log('downloadXls', this.videoId, item )
                await this.$store.dispatch('video/downloadXls', { videoId: this.videoId, type_of_align: item });
                } catch (error) {
                console.error(error);
                }

            },
            async downloadSrt(item) {
                    try {
                        console.log('downloadSrt', this.videoId, item )
                    await this.$store.dispatch('video/downloadSrt', { videoId: this.videoId, text: item });
                    } catch (error) {
                    console.error(error);
                    }

                },

        // outsideClickListener() {
        //   if (!this.$refs.dropdown1.contains(event.target)) {
        //     this.sexShow1 = false;
        //   }
        //   if (!this.$refs.dropdown2.contains(event.target)) {
        //     this.sexShow2 = false;
        //   }
        // },
    // },
    }
    }
</script>

<style scoped>

.waiting {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-right: 40%;
}

.waitingFile {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: left;
    gap: 5px;
}

.weaitFile {
    display: flex;
    flex-direction: row;
    /* position: absolute; */
    align-items: center;
    
}

.dropdownLanguageList {
  position: relative;
}
.select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 12px;
  height: 276px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  margin: 12px 24px 12px 24px;
}
.afterSelect {
  height: 130px;
}
.settings {
  /* height: 72px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 12px;
  margin-left: 24px;
  margin-right: 24px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  margin-bottom: 12px;
}
.activeSettings {
  height: 273px;
  display: flex;
  flex-direction: column;
}

.settingActive {
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.headSetting {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
}
.activeHead {
  color: rgba(0, 0, 0, 0.85);
}
.descriptionFile {
  box-sizing: border-box;
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.aboutFile {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.lang {
  color: #20EF4D;
}

.beforTranslate {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: left;
  align-items: center;
}

.translation {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }

.textBlock {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 10px;
}
.text {
    font-size: 16px;
    color: #000000;
    opacity: 45%;
    width: 120px;
    text-align: left;
}

.aboutFile {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

/* .translateButton {
  height: 32px;
  width: 130px;
  background-color: rgba(24, 144, 255, 1);
  border: 1px solid rgba(24, 144, 255, 1);
  border-radius: 2px;
  color: white;
  cursor: pointer;
  font-size: 14px;
} */

/* .translateButton:disabled {
  background-color: lightgray;
  color: gray;
  border: 1px solid rgba(217, 217, 217, 1);
  cursor: not-allowed;
} */
 
.messageBlock {
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

.message {
    box-sizing: border-box;
    width: 100%;
    margin-left: 12px;
    margin-right: 12px;
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.successUpload,
.successTranslait,
.successConvert {
    color: green;
    text-align: center;
}
.errorUpload,
.errorTranslait,
.errorConvert {
    color: red;
    text-align: center;

}

/* ========== */
.title {
  height: 56px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: start;
  width: 100%;
}

#fileElem {
  display: none;
}

.progress-bar-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  gap: 12px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 4px 16px #eae2fd;
  border-radius: 8px;
  box-sizing: border-box;
  width: 100%;
}
.title {
  box-sizing: border-box;
  width: 100%;
}
.img {
  width: 48px;
  height: 56px;
  background: #e9e3f8;
  border-radius: 4px;
}
.nameFile {
  font-size: 14px;
  height: 18px;
  padding: 0;
  margin: 0;
  text-align: left;
}
.infoLoading {
  width: 100%;
  box-sizing: border-box;
  height: 56px;
}
.sizeFile {
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: #857e95;
  padding: 0;
  margin: 0;
  text-align: left;
}
.descr-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.langTarget {
    color: #8720EF;
}
/* ========== download */
.download{
        height: 52px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px;
        margin-left: 24px;
        margin-right: 24px;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 2px;
        margin-bottom: 10px;
    }

.headDownload{
    font-size: 16px;
    color: #000000;
    opacity: 45%;
    text-align: left;
}

.headBlock {
    height: 56px;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
}        
.download-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    box-sizing: border-box;
    width: 100%;
    /* height: 56px; */
    padding: 10px 24px;
    align-items: center;
}
.download-button {
    height: 32px;
    cursor: pointer;
    display: block;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    background-color: #1890FF;
    color: white;
    height: 32px;
    font-size: 14px;
    padding: 3px 12px;
}


.download-info-text,
.headDownload-active,
.headDownload-active {
    color: #000000;
    opacity: 85%;
}
/* ========== parts */
.parts-wrapper {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px;
  /* gap: 12px; */
  margin-left: 24px;
  margin-right: 24px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  margin-bottom: 12px;
}

.parts-list {
    position: relative;
    list-style: none;
    padding-left: 24px;
    width: 100%;
    box-sizing: border-box;
}

.parts-item {
    position: relative;
    display: flex;
    width: 100%;
    box-sizing: border-box;

}
.parts-item:not(:last-child)::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: calc(100%);
    height: calc(100%); 
    border-bottom: 1px solid #e1e8f6;
    pointer-events: none;
}

.parts-number {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: rgba(42, 42, 42, 0.5);
  background: #e1e8f6;
  padding: 10px;
}

.parts-number-inside {
    text-align: center;
    width: 25px;
}


.parts-box {
    position: relative;
    padding: 15px;
    background-color: #f9f9ff;
    width: 100%;
    box-sizing: border-box;
}

.parts-contetn {
    position: relative;
}

.parts-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.parts-time {
    color: #2a2a2a80;
    font-size: 12px;
}

.parts-arrow svg {
    width: 12px;
    height: 12px;
    opacity: 0.6;
    margin: 0 15px;
}

.parts-textarea {
    width: 100%;
    padding: 2px 4px;
    font-size: 14px;
    line-height: 1.4;
    resize: vertical;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
    display: block;
    min-width: 320px;
    height: 40px;
    min-height: 20px;
    overflow-y: hidden;
}

.parts-textarea:focus {
    background: #fff;
    border-color: #00002b33;
    /* height: auto; */
    overflow-y: auto;
}

.copyIcon {
    height: 15px;
    opacity: 85%;
    cursor: pointer;
    position: absolute;
    top: 33px;
    right: 6px;
}


.parts-OriginalText {
    margin-top: 10px;
    font-size: 12px;
    color: #2a2a2a80;
    /* max-width: 320px; */
    text-align: left;
}

.main-title {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;    

}
.main-title-active{
    box-sizing: border-box;
    width: 100%;
    height: auto;
    /* padding-left: 24px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;    

}

.parts-time-wrapper {
    display: flex;
    align-items: center;
    margin-right: 25px;
}

.dropdown-btn {
    cursor: pointer;
    width: 100px;
    height: 27px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100px;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 3px 5px;
    margin-bottom: 5px;
    z-index: 99;
}

.parts-drop-wrapper {
    display: flex;
}

.parts-drop-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.part-main-title{
    color: #2a2a2a80;
    font-size: 16px;
    text-align: left;
}

.part-main-title-active {
    color: #000000;
    opacity: 85%;
}


/* .parts-drop-wrapper .dropdown {
    margin-right: 10px;
} */


.dropdown-content-item {
    padding: 3px 0px;
}

/* .dropdown-content div {
    margin-bottom: 5px;
} */

.part-bt {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* padding-left: 24px; */
        gap: 20px;
    }
.bt-voice {
    height: 32px;
    cursor: pointer;
    display: block;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    background-color: #1890FF;
    color: white;
}
.img-save{
        height: 20px;
        width: 20px;
     }
     .img-save-no-active {
        opacity: 60%;
        cursor: not-allowed;
     }

    .save-project-button {
        display: flex;
        gap: 3px;
     }


.clicked{
    background-color: lightgray;
    color: gray;
    border: 1px solid rgba(217, 217, 217, 1);
    cursor: not-allowed;
}

.commonGender,
.commonVoice {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 3px;
}

.img-cansel {
    height: 25px;
    width: 25px;
    cursor: pointer;
}
.need-align-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
}
.speed-for-all-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.part-bt-under{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 24px;

}
.part-bt-line-block{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.buton-google {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}



@media screen and (max-width: 1197px) {
    .part-bt{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* padding-left: 24px; */
        gap: 20px;
        flex-wrap: wrap;
    } 
}

@media screen and (max-width: 1080px) {
  .parts-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .img-cansel {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }

  .parts-drop-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .parts-speaker,
  .parts-drop-title,
  .dropdown {
    margin-bottom: 20px;
  }

  .download-info {
        flex-direction: column;
        width: auto;
        padding: 0;
        gap: 20px;
    }

  .download-active {
    height: 140px;
  }


  .part-bt {
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

  }


  .parts-list {
    padding-left: 24px;
  }

  .parts-number {
    padding: 2px;
  }

  .secondary-button {
    margin-top: 20px;
  }
  .headBlock {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

    }

}
@media screen and (max-width: 673px) {
    .part-bt-line-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}
.buton-google {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}


}

@media screen and (max-width: 559px) {
    .dropzone{
        height: 180px;
    }
}
@media screen and (max-width: 426px) {
    .dropzone{
        height: 200px;
    }
    .afterSelect{
        height: 160px;
    }
    .translation {
        flex-direction: column;
        justify-content: flex-start;
    }
    .activeSettings{
        height: 380px;
    }
    .part-bt{
        padding: 0;
    }
    .part-bt-under{
        flex-direction: column;
        gap: 20px;
    }


}

</style>