<template>
    <div>
            <!-- Header часть -->
            <div class="container-header">
                <div class="content-header">
                    <div class="breadcrumbs">
                        <breadcrumbs-menu></breadcrumbs-menu>
                    </div>
                    <div class="title">
                        <h3 class="namePage">{{ pageTitle }}</h3>
                    </div>
                </div>
            </div>
            <div class="wrap-content-voiceacting">
                <div>
                    Name project: 
                    <input type="text"
                    v-model="nameProject"/>
                </div>
                <div class="lists">
                    <dropdown-language-list-vue
                        @languageSelected="getLanguage"
                    />
                    <dropdown-list
                    :list="listGender"
                    :imagePath="imagePath"
                    @updateItem="updateItemGender"
                    />
                    <dropdown-list
                    :list="listVoices"
                    :imagePath="imagePath"
                    @updateItem="updateItemVoice"
                    />
                </div>
                <div v-for="(textarea, index) in textareas" :key="index" class="segments">
                    <div class="segment">
                        <div class="segment-number">
                            {{ index +1 }}
                        </div>
                        <textarea
                            class="textarea"
                            v-model="textarea.text"
                        ></textarea>
                    </div>
                    <!-- Додайте обробник кліку для кнопки "plus" -->
                    <img
                    class="plus"
                    src="../icon/plus.png"
                    @click="addTextarea"
                    />
                </div>                    
                    <my-button @click="saveProject">Save project</my-button>
                    <my-button>Send text for voice acting</my-button>
            </div>

    </div>
  
</template>

<script>
import BreadcrumbsMenu from './BreadcrumbsMenu.vue';
import DropdownLanguageListVue from './DropdownLanguageList.vue';
import { apiUrl } from '../components/api'



export default {
    name: 'VoiceActing',
    data() {
        return {
            textareas: [{ text: null }],
            language: null,
            nameProject: null,
            imagePath: require('../icon/arrow_down.png'),
            listGender: null,
            listVoices: null

        }
        


    },
    components: {
            BreadcrumbsMenu,
            DropdownLanguageListVue,
        },
    computed: {
            pageTitle() {
            return this.$route.meta.label;
            }
        },
    methods: {
        async getLanguage(item) {
            console.log(item)
            console.log(this.text)
            this.language = item
            //запит поверне статі+голоси для обраної мови
            try {
                const sessionKey = window.sessionStorage.getItem('auth_key')
        
                if (!sessionKey) throw new Error("No session key available.");

                const response = await fetch(`${apiUrl}/api/get-voices-lists`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey, lang: this.language}),
                });
                if(response.ok) {
                    const data = await response.json();
                    if (data.success) {
                        this.list = data.list // edit
                        this.voices = data.voices // edit
                    } else {
                    console.error("Backend Error: ", data.error);
                    }
                } else {
                    console.error("Network Response Error");
                }

                } catch (err){
                console.error(err)
            }
        },
        updateItemVoice(item) {
            console.log(item)
        },
        updateItemGender(item){
            console.log(item)
        },
        addTextarea() {
            console.log('addTextarea')
            this.textareas.push({ text: null });

        },
        saveProject() {
            console.log('save', this.nameProject, this.textareas, this.language)
        }
    }


}
</script>
<style scoped>
    .container-header {
        box-sizing: border-box;
        height: 100px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 16px 24px;
        gap: 8px;
    }

    .content-header{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .breadcrumbs{
        height: 22px;
        z-index: 999;
    }

    .title{
        height: 40px;
    }

    .wrap-content-voiceacting{
        background-color: #FFFFFF;
        margin: 12px 24px 12px 24px;
        padding: 24px 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }
    .textarea {
        width: 100%;

    }
    .plus {
        height: 25px;
        width: 25px;
        cursor: pointer;
    }
    .segment {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        background-color: #add8e6;

    }
    .segment-number {
        padding: 3px;
        background-color: #add8e6;
        height: 100%;
        box-sizing: border-box;
    }
    .segments {
        width: 100%;
    }

    .lists {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
    }
</style>