<template>
    <div class="dropdown">
        <div class="dropdownWrap">
            <div class="dropdownButton" @click="showDropdownList">
                <input class="input" type="search" v-model="searchTerm" :placeholder="placeholderText" 
                @input="filterLanguages" 
                :class="{ selectedText: hasSelectedLanguage }"/>

                <img src="../icon/arrow_down.png" class="arrowImg" v-if="!isDropdownList"/>
                <img src="../icon/search.svg" class="searchImg" v-else/>

            </div>

            <ul class="dropList" v-show="isDropdownList" >
                <li class="dropdownList-item" 
                v-for="item in filteredLanguages" :key="{item}" 
                    @click="selectLanguage(item)">{{ item }}</li>
            </ul>
        </div>
    </div>
</template>

<script>
// import languageList from './test/languageList.json'

export default {
    name: 'DropdownLanguageList',
    data() {
        return {
            isDropdownList: false,
            selectedLanguage: '',
            language: [],
            searchTerm: '',
            

        }
    },
    created() {
        // console.log('created lang')
        this.$store.dispatch('languages/getTargetLangsList');
    },
    mounted() {
        // console.log('mounted fetchLanguageList')
        this.fetchLanguageList()
        
    },

    computed: {
        languageList() {
            // console.log('computed languageList')
            return this.$store.state.languages.languageList;
        },
        filteredLanguages() {
            if (this.searchTerm) {
                return this.language.filter(item =>
                item.toLowerCase().startsWith(this.searchTerm.toLowerCase())
                );
            } else {
                return this.language;
            }
        },
        placeholderText() {
            return this.selectedLanguage ? this.selectedLanguage : 'Language';
            },
        hasSelectedLanguage() {
            return this.selectedLanguage !== '';
            }
        },
    methods: {
        fetchLanguageList() {
            // console.log('fetchLanguageList')
            this.$store.dispatch('languages/getTargetLangsList')
            .then(() => {
                // console.dir(this.languageList)
                const arr = []
                if(this.languageList.length>0){
                    Object.values(this.languageList[0].language_names).map(item => arr.push(item))
                    // console.log(arr)
                    this.language = arr.sort()
                } else {
                    return
                }
                });

        },
        showDropdownList() {
            this.isDropdownList = !this.isDropdownList
        },

        selectLanguage(item) {
            // console.log(item)
            this.selectedLanguage = item;
            this.searchTerm = item;
            // console.log(this.selectedLanguage)
            this.$emit('languageSelected', this.selectedLanguage);
            this.isDropdownList = false;
            },
        filterLanguages() {
            this.isDropdownList = true;
            },

        }

}
</script>

<style>
    .dropdownButton {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        font-size: 16px;
        border: 1px solid rgba(217, 217, 217, 1);
        border-radius: 2px;
        background-color: #fff;
        width: 200px;
        height: 40px;

        cursor: pointer;
        overflow: hidden;

    }

    .dropdownButton:hover {
        border: 1px solid rgba(64, 169, 255, 1);
    }

    .input {
        width: 100%;
        font-size: 16px;
        border: none;
        outline-style: none;
        cursor: pointer;
    }

    .searchImg,
    .arrowImg {
        display: block;
        height: 20px;
        width: 20px;
        opacity: 30%;
    }

    .dropdownWrap{
        position: relative;
    }

    .dropList {
        position: absolute;
        margin: 0;
        padding: 0;
        list-style-type: none;
        left: 0;
        max-height: 200px;

        background: white;
        box-shadow: 1px -1px 6px 2px rgba(0, 0, 0, 0.05);

        border-radius: 2px;
        /* width: 100%; */
        width: 222px;
        z-index: 99;
        overflow: scroll;
    }


    .dropdownList-item {
        margin: 0;
        padding: 0;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-bottom: 0;
        padding: 10px;
        cursor: pointer;

    }

    .dropdownList-item:first-child {
        border-radius:  0 0 2px 2px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }

    .dropdownList-item:hover{
        background: rgba(245, 245, 245, 1);
    }

    .dropdownButton:focus{
        outline: none;
        box-shadow: 0px 0px 0px 4px rgba(64, 169, 255, 0.179);
    }

    .selectedText {
        color: black
    }
    

</style>