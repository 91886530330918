<template>
    <nav class="breadcrumbs">
        <ol>
            <li>
                <router-link to="/home" :class="{ active: isCurrentRoute('/home') }">Home</router-link>
            </li>
            <!-- eslint-disable-next-line vue/no-multiple-template-root -->
            <template v-for="(crumb, index) in breadcrumbs" >
                <li v-if="index > 0" :key="index">
                    <span class="breadcrumb-separator">/</span>
                </li>
                <li v-if="index > 0 && breadcrumbsLength > 1" :key="crumb.to">
                    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
                    <router-link :to="crumb.to" v-html="crumb.breadcrumb" :class="{ active: isCurrentRoute(crumb.to) }"  ></router-link>
                </li>
            </template>
        </ol>
    </nav>
</template>

<script>

export default {
    name: 'BreadcrumbsMenu',
    computed: {
        breadcrumbs() {
            const matchedRoutes = this.$route.matched;
            // console.log(this.$route)
            // console.log(matchedRoutes)
            const rout = matchedRoutes.map(route => ({
                to: route.path,
                breadcrumb: route.meta.breadcrumb,
            }))
            // console.log(rout)
            return rout;
        },
        breadcrumbsLength(){
            // console.log(this.breadcrumbs.length)
            return this.breadcrumbs.length
        }
    },
    methods: {
        isCurrentRoute(path) {
            return this.$route.path === path;
        },
    }
}

</script>

<style scoped>
.breadcrumbs {
    display: flex;
    align-items: center;
}

.breadcrumbs ol {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
}

.breadcrumbs li {
    display: flex;
    align-items: center;
}

.breadcrumbs li .breadcrumb-separator {
    margin: 0 0.5rem;
}
.active {
  /* для підсвічування поточного посилання */
    font-weight: bold;
}
</style>






