<template>
    <div>
        <!-- Header часть -->
        <div class="container-header">
            <div class="content-header">
                <div class="breadcrumbs">
                    <breadcrumbs-menu></breadcrumbs-menu>
                </div>
                <div class="title">
                    <h3 class="namePage">{{ pageTitle }}</h3>
                </div>
            </div>
        </div>

        <!-- Основной контент -->
        <div class="content-container">
            <!-- Левая часть -->
            <div class="left-side">

                <div class="donate-options">
                    <h1>Thank you for being so supportive!</h1>
                    <h2>Message from the development team:</h2>
                    <div>
                        We are grateful to everyone who wants to support our project. Your contribution to any amount comfortable for you will help all participants of the Creative Society to use this project to disseminate information. Previously, we paid the bills for this project ourselves, but the number of users is growing, and the number of expenses is growing proportionally. Now, our funds are not enough. Of course, we will continue to participate financially in this project with you.
                    </div>
                    <div>
                        Transfer to an account in Europe:<br />
                        <span>IBAN: LT463110043224326203 (Lituania)</span><br />
                        <span>SWIFT/BIC: MNNELT21XXX</span><br />
                        <span>FIC: 31100</span><br />
                        <span>BeneficiaryBeneficiary: VIKTOR ANDRIICHUK</span><br />
                        <span>Please use Euros to avoid transfer fees.</span>
                    </div>
                    <div>
                        Transfer to an account in Ukraine:<br />
                        <span>IBAN: UA093220010000026203339292820</span><br />
                        <span>ІПН/ЄДРПОУ: 2918619650</span><br />
                        <span>Recipient: Андрійчук Віктор Васильович</span><br />
                        <span>Purpose of payment: Поповнення рахунку</span><br />
                    </div>
                    <div>
                        Transfer cryptocurrency:<br />
                        <span>Binance Pay ID: 257629908</span>
                    </div>
                </div>
            </div>

            <!-- Правая часть -->
            <div class="right-side">
                <h3>Attendance statistics for October:</h3>
                <img width="600" src="@/assets/matomo_1.png" alt="Visitors Analytics" /><br />
                <img width="600" src="@/assets/matomo_2.png" alt="Visitors Analytics" /><br />
                <img width="600" src="@/assets/matomo_3.png" alt="Visitors Analytics" /><br />

                <h3>Expense statistics for October:</h3>
                <img width="600" src="@/assets/google_budget_2023_10.png" alt="Google Budget" /><br />
                <img width="600" src="@/assets/azure_analytics.png" alt="Azure Budget" /><br />
                <img width="600" src="@/assets/server_balance.png" alt="Server Budget" />

            </div>
        </div>
    </div>
</template>

<script>
import BreadcrumbsMenu from './BreadcrumbsMenu.vue';

    export default {
        name: "DonateApp",
        components: {
            BreadcrumbsMenu,
        },
        computed: {
                pageTitle() {
                return this.$route.meta.label;
                }
        },

    };
</script>


<style>
    .container-header {
        box-sizing: border-box;
        height: 100px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 16px 24px;
        gap: 8px;
    }

    .content-header{
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
    .namePage{
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
        height: 28px;
        padding: 0;
        margin: 0;
        text-align: start;
    }

    .breadcrumbs{
        height: 22px;
        z-index: 999;
    }

    .title{
        height: 40px;
    }

    .content-container {
        display: flex;
        justify-content: space-between;
        padding: 16px;
    }

    .left-side, .right-side {
        flex: 1;
        padding-right: 16px;
        background-color: rgb(255, 255, 255); /* добавляем белый фон */
        margin: 24px 24px 5px; /* Добавляем отступы, как у .header-wrap */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Добавляем небольшое тень для эффекта "поднятия" блока */
    }

    .right-side {
        padding-left: 16px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .left-side div, .right-side div {
    padding: 10px; /* Добавляем отступы со всех сторон для div */
    }

    .donate-options > div {
        margin-top: 16px;
        text-align: left
    }

    /* Мобильная адаптивность */
    @media (max-width: 768px) {
        .content-container {
            flex-direction: column;
        }

        .left-side, .right-side {
            flex: none; /* Отключаем flex на мобильных устройствах */
            width: 100%; /* Занимаем всю ширину */
            padding: 16px 0; /* Добавляем отступы сверху и снизу */
        }

        .right-side {
            border-left: none; /* Убираем левую границу */
            border-top: 1px solid rgba(0, 0, 0, 0.1); /* Добавляем верхнюю границу */
        }
    }

</style>