<template>
    <button :class="['button', { 'disabled': isActive, 'click': isClicked }]" @mousedown="setButtonDown" @mouseup="setButtonUp">
      <slot></slot>
    </button>
  </template>
  
  <script>
  export default {
      name: 'secondary-button',
      props: {
      isActive: Boolean,
    },
    data(){
    return {
        isClicked: false
    }
  },
  methods: {
    setButtonDown() {
        console.log('click button')
      this.isClicked = true;
    },
    setButtonUp() {
        console.log('click button')
      this.isClicked = false;
    }
  }

  }
  </script>
  
  <style scoped>
.button{
    height: 32px;
    cursor: pointer;
    display: block;
    border: 1px solid #1890FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    background-color: white;
    color: #1890FF;
}
  
.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: gray;
    border: 1px solid gray;
    background: white;
  }
  
  .click {
    border: 1px solid #0257a7;
    background-color: rgb(196, 209, 251);

}

  
  
  
  </style>