<template>
  <div>
    <h1>Upload your video</h1>

    <div v-if="access">
      <input type="file" @change="onFileChange" ref="videoInput" />
      <button @click="submitVideo">Upload</button>
    </div>
    <div v-if="currentDate">
      <p>Текущая дата и время: {{ currentDate }}</p>
      <p>Дата и время истечения ключа: {{ expirationDate }}</p>
      <p>Не волнуйтесь. После истечения срока годности текущего ключа Вы сможете получить его снова тем же способом. Это сделано в целях безопасности.</p>
    </div>

    <div v-if="error" style="color: red;">
      {{ error }}
    </div>

    <div v-if="successMessage" style="color: green;">
      {{ successMessage }}
    </div>

    <h2 v-if="!access"> <router-link to="/settings">Settings</router-link> </h2> 
    <h2> <router-link to="/home/profile">Profile</router-link> </h2>
    <h2> <router-link to="/">Login</router-link> </h2>

  </div>
</template>

<script>
export default {
  name: "UploadVideo",
  data() {
    return {
        access: false,
        error: "",
        successMessage: "",
        videoFile: null,
        authHeader: "",
        authKey: "",
    };
  },

  created() {
    const authKeyCookie = document.cookie
      .split("; ")
      .find((cookie) => cookie.startsWith("auth_key="));
    if (authKeyCookie) {
      const keyValue = authKeyCookie.split("=")[1];
      const [, timestamp] = keyValue.split("|");

      this.currentDate = new Date();
      this.expirationDate = new Date(Number(timestamp));
      this.expirationDate.setDate(this.expirationDate.getDate() + 3);
      this.keyTimestamp = timestamp;

      if (this.currentDate <= this.expirationDate) {
        this.access = true;
      } else {
        this.error = "Frontend: Ключ устарел. Пожалуйста, обновите ключ.";
      }
    } else {
      this.error = "Frontend: Ключ отсутствует. Пожалуйста, перейдите в настройки для ввода ключа.";
    }
  },

  methods: {
    onFileChange(e) {
      this.videoFile = e.target.files[0];
    },

    async submitVideo() {
      if (!this.videoFile) {
        alert("Frontend: Пожалуйста, выберите файл для загрузки.");
        return;
      }

      const formData = new FormData();
      formData.append("video", this.videoFile);

       // Получаем значение ключа из куки
  const authKeyCookie = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith("auth_key="));
  const keyValue = authKeyCookie.split("=")[1];

  // Сохраняем значения в данных компонента
  this.authKey = keyValue;
  this.authHeader = `Bearer ${keyValue}`;

      try {
        const response = await fetch("http://localhost:5001/api/upload-video", {
          method: "POST",
          body: formData,
          credentials: "include",
          headers: {
            // Добавляем заголовок Authorization
            Authorization: `Bearer ${keyValue}`,
          },
        });

        const responseData = await response.json();

        if (response.ok) {
            this.successMessage = responseData.message;
            this.fileSize = responseData.file_size;
            this.error = "";
            this.$refs.videoInput.value = null;
            this.videoFile = null;
        } else if (response.status === 413) {
            this.error = responseData.error;
            this.successMessage = "";
        } else {
            this.error = responseData.message;
            this.successMessage = "";
        }
      } catch (error) {
            this.error = `Frontend: Произошла ошибка при загрузке видео: ${error.message}`;
            this.successMessage = "";
        }
    },

  },
};
</script>
