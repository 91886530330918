<template>
  <div>
    <h1>Enter your 10-character authorization key</h1>
    <form @submit.prevent="submitAuthKey">
      <input type="text" v-model="authKey" minlength="10" maxlength="10" required />
      <button type="submit">Save</button>
    </form>
      <div v-if="currentDate">
      <p>Текущая дата и время: {{ currentDate }}</p>
      <p>Дата и время истечения ключа: {{ expirationDate }}</p>
      <p>Не волнуйтесь. После истечения срока годности текущего ключа Вы сможете получить его снова тем же способом. Это сделано в целях безопасности.</p>
    </div>
    <h2>[ <router-link to="/upload">UploadVideo</router-link> ]</h2> 
    <h2>[ <router-link to="/profile">Profile</router-link> ]</h2>
    <p v-if="error" style="color: red;">{{ error }}</p>
  </div>
</template>

<script>
export default {
    name: "AppSettings",
    data() {
      return {
        authKey: "",
        error: "",
      };
    },

    created() {
    const authKeyCookie = document.cookie
      .split("; ")
      .find((cookie) => cookie.startsWith("auth_key="));
    if (authKeyCookie) {
      const keyValue = authKeyCookie.split("=")[1];
      const [, timestamp] = keyValue.split("|");

      this.currentDate = new Date();
      this.expirationDate = new Date(Number(timestamp));
      this.expirationDate.setDate(this.expirationDate.getDate() + 3);

      if (this.currentDate <= this.expirationDate) {
        this.access = true;
      } else {
        this.error = "Frontend: Ключ устарел. Пожалуйста, обновите ключ.";
      }
    } else {
      this.error = "Frontend: Ключ отсутствует. Пожалуйста, перейдите в настройки для ввода ключа.";
    }
  },

  methods: {
    async submitAuthKey() {
      if (this.authKey.length !== 10) {
        this.error = "Frontend: Ключ должен состоять из 10 символов.";
        return;
      }
      try {
        const response = await fetch("http://localhost:5001/api/save-auth-key", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ auth_key: this.authKey }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Создание временной метки
            const timestamp = new Date().getTime();

            // Установка куки с временной меткой на фронтенде
            document.cookie = `auth_key=${this.authKey}|${timestamp}; path=/;`;
            this.$router.push("/");
          } else {
            this.error = data.error;
          }
        } else {
          this.error = "Frontend: Произошла ошибка при сохранении ключа.";
        }
      } catch (err) {
        console.error("Frontend Error:", err); // Добавьте эту строку для вывода ошибки в консоль
        this.error = "Frontend: Произошла ошибка при сохранении ключа.";
      }
    },
  },
};
</script>
