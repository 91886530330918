<template>
    <div class="wrap-newProject">
        <div class="select" >
            <div class="title">
                <p>Video or audio file</p>
            </div>
            <div class="fileInfo"> 
                <h3 class="nameFile"> {{ fileName }}</h3>
                <p class="sizeFile"> {{ fileSize}} MБ</p>
            </div>
        </div>

        <!-- SETTINGS -->
        <div class="settings" :class="{ 'activeSettings': showSettingsBlock, 'settings6': this.status >= 6}" >
            <div class="headBlock">
                <p class="headSetting" :class="{'activeHead': showSettingsBlock}">Settings</p>
                <div class="waiting" v-show="waitingSetting">
                    <p>Waiting</p>
                    <loading-dots/>
                </div>

            </div>
            <div class="settingActive" v-if="showSettingsBlock">
                <div class="descriptionFile">
                    <div class="textBlock">
                        <p class="text">Original text</p>
                        <div class="aboutFile">
                            <p class="lang">{{ basicLang }}</p>
                            <!-- <p>{{ fileName }}</p> -->
                        </div> 
                    </div>
                    <!-- <button class="editButton">Edit text</button> -->
                </div>
                <div class="translation">
                    <div class="textBlock">
                        <p class="text">Translation</p>
                        <div class="beforTranslate" v-show="beforTranslate">
                            <DropdownLanguageList 
                            class="dropdownLanguageList"
                            @languageSelected="handleLanguageSelected"/>
                            <my-button class="translateButton" v-bind:is-active="!isLanguageSelected" @click="translateFile">Translate</my-button>
                        </div>
                        <div class="afterTranslate" v-show="afterTranslate">
                            <div class="aboutFile">
                                <p class="langTarget">{{ translaitLang }}</p>
                            </div> 
                        </div>

                    </div>

                </div>
                <div v-if="successTranslate || errorTranslate" class="messageBlock">
                    <p v-if="successTranslate">{{ successMessageTranslate }}</p>
                    <p v-if="errorTranslate">{{ errorMessageTranslate }}</p>
                </div>

                <!-- <ProgressBarVue :fileSize="fileSize" v-show="isProgressBar2"/> -->

            </div>
        </div>

            <!-- Translation text -->
    <div class="parts-wrapper" >
      <div class="main-title" :class="{ 'main-title-active': isTranscriptionBlock && this.countSegmentsText>0}">
            <p class="part-main-title" :class="{'part-main-title-active': isTranscriptionBlock && this.countSegmentsText>0}">Transcription</p>
            <div class="part-bt" v-show="isTranscriptionBlock && this.countSegmentsText>0">
                <div class="part-bt-line-block">
                    <!-- <div class="need-align-block">
                        <input id="needAlign" type="checkbox" v-model="needAlign" @change="updateNeedAlign" />
                        <label for="needAlign">Auto align</label>
                    </div> -->
                    <div class="speed-for-all-block">
                        <p>Speed for all:</p>
                        <slider-app
                            :readonly="isReadOnly"
                            @updateVal="updateSpeedForAll"
                        />
                    </div>
                </div>

                <div class="part-bt-line-block">
                    <div class="commonGender">
                        Gender for all:
                        <dropdown-list
                        :list="genderList"
                        :title="title1"
                        :readOnly="isReadOnly"
                        @updateItem="handleGender"
                        :imagePath="imagePath"/>
                    </div>
                    <div class="commonVoice">
                        Voice for all:
                        <dropdown-list
                        class="drop-voice"
                        :buttonClass="buttonClass1"
                        :buttonClass1="buttonClass2"
                        :list="voiceList"
                        :title="title2"
                        :readOnly="isReadOnly"
                        @updateItem="handleVoice"
                        :imagePath="imagePath"/>
                    </div>
                </div>

                <div class="part-bt-line-block">
                    <dropdown-list 
                        :title="title3"
                        :list="listFormat"
                        :imagePath="imagePath"
                        @updateItem="updateFormatTextDownload"
                        :buttonClass="buttonClassBtn"
                        :buttonClass1="buttonClassContent"
                    />

                    <dropdown-list 
                        :title="title4"
                        :list="listXls"
                        :imagePath="imagePath"
                        @updateItem="updateAlignTextDownload"
                        :buttonClass="buttonClassBtn"
                        :buttonClass1="buttonClassContent"
                    />
                </div>
                <div class="buton-google">
                    <a 
                        v-if="isGoogleSheet"
                        :href="'https://docs.google.com/spreadsheets/d/' + isGoogleSheet + '/edit'"
                        target = "_blank">The google sheet
                    </a>

                    <secondary-button 
                        @click="openGoogleSheet"
                        v-if="!isGoogleSheet"
                        :isActive="isActiveSecondaryButton"
                    >
                        Open google sheets
                    </secondary-button>
                    <secondary-button 
                        v-if="isGoogleSheet"
                        @click="startSynchronize"
                        :isActive="isActiveSynchronizeButton"

                    >
                        Synchronize with the table
                    </secondary-button>
                    <secondary-button
                        v-if="isGoogleSheet"
                        @click="updateGoogleSheets"
                        :isActive="isActiveUpdateButton"

                    >
                        Update google sheets
                    </secondary-button>

                </div>



            </div>

            <div class="waiting" v-show="waitingTranslate">
                <p>Waiting</p>
                <loading-dots/>
            </div>
        </div>


        <segments-text 
            :videoTextTranslated="videoTextTranlate"
            :key="videoTextTranlate"
            :readOnly="isReadOnly"
            v-show="isTranscriptionBlock"
            @updateVideoText="updateVideoText"
            @countSegments="handleCountSegments"
            :baseLanguage="basicLang"
            :translateLanguage="translaitLang"
            :videoId="videoId"

        />
      
        <div class="part-bt-under" v-show="isTranscriptionBlock && this.countSegmentsText>0">
            <my-button class="save-project-button"
                :class="{'clicked': isVoiceSend}"
                @click="saveInfo"
                :isActive="isSaveInfo"
            >
                Save project without montage
                <img class="img-save" :class="{'img-save-no-active': isVoiceSend}" src="../icon/save_white.png"/>
            </my-button>

            <my-button 
                class="bt-voice" @click="confirmAct"
                :class="{'clicked': isVoiceSend}">
                Start video montage
            </my-button>

        </div>

        <div v-if="successConvert || errorConvert" class="messageBlock">
            <p v-if="successConvert" class="successConvert">{{ successMessageConvert }}</p>
            <p v-if="errorConvert" class="errorConvert">{{ errorMessageConvert }}</p>
        </div>



    </div>
    <plashka-modal v-if="showPlashka"
        :show-reminder="plashkaVisible" 
        @update:show-reminder="plashkaVisible = $event"
        > 
            Choose voise, please
    </plashka-modal>

    <plashka-confirm v-if="showConfirm"
    :showConfirm="showConfirm"
    @actConfirm="sendDataToConvert"
    @update:show-reminder="showConfirm = $event"
    >
        Do you really want to mount the project?
    </plashka-confirm>


        <div class="download" :class="{'download-active': isDownload}" >
            <div class="headBlock">
                <p class="headDownload" :class="{'headDownload-active': isDownload}">Download</p>
                <div class="waiting" v-show="waitingVoice">
                    <p>Waiting</p>
                    <loading-dots/>
                </div>

                <div class="download-info" v-show="isDownload">
                    <button class="download-button" @click="downloadAudio" v-if="fileType ==='audio' || fileType ==='video'">Download audio</button>
                    <button class="download-button" @click="downloadVideo" v-if="fileType ==='video'">Download video</button>
                </div>
                <div class="waitingFile" v-show="waitingDownloadFile">
                    <p class="weaitFile">Please wait, the file is being prepared for downloading</p>
                </div>
                <div v-show="waitingDownloadFile">
                    <loading-spinner/>
                </div>

            </div>
        </div>



    </div>
</template>

<script>
    import DropdownLanguageList from './DropdownLanguageList.vue';
    import { apiUrl } from './api'
    import SegmentsText from './SegmentsText.vue';
    import MyButton from './UI/MyButton.vue';
    import PlashkaModal from './UI/PlashkaModal.vue';
    import DropdownList from './UI/DropdownList.vue';




    export default {
        name: 'EditProject',
        components: {
            DropdownLanguageList,
            SegmentsText,
            MyButton,
            PlashkaModal,
            DropdownList
        },
        data() {
            return {
                fileName: "",
                fileSize: 0,
                fileType: "",
                uploadPercentage: 0,
                videoName: "",
                showSettingsBlock: false,
                isLanguageSelected: false,
                isProgressBar2: false,
                beforTranslate: true,
                afterTranslate: false,
                isSelectVoice: false,
                basicLang: "",
                translaitLang: "",
                videoList: [],
                textarea: "",
                sexShow1: {},
                sexItems1: ["male", "female"],
                sexSelected1: {},
                sexShow2: {},
                sexItems2: ["Voice 1", "Voice 2", "Voice 3", "Voice 4"],
                sexSelected2: {},
                selectedLanguage: null,
                videoId: "",
                responseData: null,
                successMessageTranslate: "",
                errorMessageTranslate: "",
                successTranslate: false,
                errorTranslate: false,
                isTranscriptionBlock: false,
                videoTextTranlate: {},
                status: null,
                isVoiceSend: false,
                isDownload: false, 
                isReadOnly: false,
                successConvert: false,
                errorConvert: false,
                successMessageConvert: "",
                errorMessageConvert: "",
                waitingSetting: false,
                waitingTranslate: false,
                waitingVoice: false,
                countSegmentsText: 0,
                showPlashka: false,
                plashkaVisible: true,
                imagePath: require('../icon/arrow_down.png'),
                title1: 'choose',
                title2: 'choose',
                buttonClass1: 'dropdown-btn-width',
                buttonClass2: 'dropdown-btn-content-width',

                male: 0, 
                female: 0,

                listFormat: ['basic text', 'translated text'],
                title3: 'Download .srt',
                buttonClassBtn: 'dropdown-btn-srt',
                buttonClassContent: 'dropdown-btn-content-srt',

                title4: "Download .xls",
                listXls: ["row oriented", "column oriented"],

                hide: false,
                needAlign: true,
                intervalId: null,

                showConfirm: false,
                isGoogleSheet: null,
                linkOnTheTable: "",
                isActiveSecondaryButton: false,
                isActiveSynchronizeButton: false,
                isActiveUpdateButton: false,
                isSaveInfo: false

            }
        },
            created() {
                console.log('APP_ENV:', process.env.VUE_APP_ENV);
                this.getVideoInfo()
    
            },

            mounted() {
                console.log('mounted editproject')

            },
            beforeUnmount() {
                        // При видаленні компонента зупиняємо інтервал, щоб уникнути витоку ресурсів
                clearInterval(this.intervalId);

            },
            watch: {
                status: function(newStatus, oldStatus) {
                    console.log('oldStatus', oldStatus)
                    if (newStatus === 6 || newStatus === 9) {
                    console.log('this.status 6 || 9', newStatus);
                    this.intervalId = setInterval(this.saveInfo, 30000);
                    } else {
                    
                    clearInterval(this.intervalId);
                    }        
                }
            },


            computed: {
                waitingDownloadFile() {
                    console.log('waitingDownloadFile', this.$store.state.video.waitingDownloadFile)
                    return this.$store.state.video.waitingDownloadFile
                },


                voiceList() {
                    const uniqueSegments = [];
                    const segmentArray = Object.values(this.videoTextTranlate);
                    const title1UpperCase = this.title1.toUpperCase();

                    segmentArray.forEach(item => {
                        if (item.all_voices[title1UpperCase]) {
                        const targetVoice = item.all_voices[title1UpperCase];
                        
                        targetVoice.forEach(segment => {
                            const isUnique = !uniqueSegments.some(existingSegment => {
                            return JSON.stringify(existingSegment) === JSON.stringify(segment);
                            });

                            if (isUnique) {
                            uniqueSegments.push(segment);
                            }
                        });
                        }
                    });
                    console.log('unique segments List', uniqueSegments);
                    return uniqueSegments;
                },
                        
                genderList() {
                    const segmentArray = Object.values(this.videoTextTranlate)
                    segmentArray.map(item=> {
                        if(item.all_voices.FEMALE.length == 0 ) { 
                            this.female++
                            console.log('this.female', this.female)
                        } else if(item.all_voices.MALE.length == 0){
                            this.male++
                            console.log('this.male',this.male)
                        }
                    })
                    if (this.male ==0 && this.female ==0) {
                        return ['male', 'female']
                    } else if (this.male > 0 && this.female ==0) {
                        return ['female']
                    } else  return ['male']
                    
                }
            },

            methods: {
                delayTime(sec) {
                    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
                    return delay(sec * 1000); 
                },
                confirmAct() {
                    if(!this.isVoiceSend){
                        this.showConfirm = true
                    } else {
                        console.log('not active button')
                        return
                    }
                },
                async saveInfo(){
                    console.log('saveInfo')
                    if(!this.isVoiceSend && !this.isSaveInfo){ 

                    try{                    
                        const sessionKey = window.sessionStorage.getItem('auth_key')
                        // console.log('sessionKey actions', sessionKey)
                        if (!sessionKey) throw new Error("No session key available.");
                        // console.log('videoTextTranlate', this.videoTextTranlate)
                            const dataToSend = {
                            video_info: this.videoTextTranlate,
                            auth_key: sessionKey, 
                            video_id: this.videoId
                        };
                    
                        const response = await fetch(`${apiUrl}/api/save-info-translated`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(dataToSend)
                        });
                    
                        if (response.ok) {
                            const data = await response.json();                            
                            if (data.success) {
                            //     this.waitingVoice = true
                            //     this.isVoiceSend = true
                            this.isSaveInfo = true
                                this.successConvert = true
                                this.errorConvert = false
                                this.successMessageConvert = data.message
                                this.errorMessageConvert = ""

                                await this.delayTime(3)
                                this.successMessageConvert = ""

                                await this.delayTime(10)
                                this.isSaveInfo = false

                            } else {
                                console.error("Backend Error: ", data.error);
                                this.errorConvert = true
                                this.successConvert = false
                                this.errorMessageConvert = data.error
                                this.successMessageConvert = ""
                            }

                        }
                    } catch (error) {
                        console.error('Помилка під час відправки даних на сервер:', error);
                        this.errorMessageConvert = "Network Response Error"
                        this.errorConvert = true
                        this.successConvert = false
                        this.successMessageConvert = ""

                    }
                } else {
                    console.log('not active button')
                    return
                }
                },

                updateSpeedForAll(item){
                    console.log(item)
                    console.log(this.videoTextTranlate)
                    let speed = this.setLevel(item)
                    const segmentArray = Object.values(this.videoTextTranlate)
                    segmentArray.map(el=> {el.min_voice_speed = speed.min
                        el.max_voice_speed = speed.max})
                    console.log('segmentArray', segmentArray)
                    this.updateVideoText(segmentArray)

                    console.log(this.videoTextTranlate)

                },
                setLevel(value){
                console.log(value)
                switch (value) {
                    case 1:
                        return {min: -0.05, max: 0.05};
                    case 2:
                        return  {min: -0.1, max: 0.1};
                    case 3:
                        return {min: -0.15, max: 0.15};
                    case 4:
                        return {min: -0.2, max: 0.2};
                    case 5:
                        return {min: -0.25, max: 0.25};
                    case 6:
                        return {min: -0.3, max: 0.3};
                    case 7:
                        return {min: -0.35, max: 0.35};
                    case 8:
                        return {min: -0.4, max: 0.4};
                    case 9:
                        return {min: -0.45, max: 0.45};
                    case 10:
                        return {min: -0.5, max: 0.5};
                    default:
                        return 'No value';
                }
            },

                updateNeedAlign() {
                        console.log('updateNeedAlign-', this.needAlign)
                },
                updateAlignTextDownload(item) {
                    console.log(item)
                    this.title4 = item
                    this.downloadXls(item)
                },

                updateFormatTextDownload(item) {
                    console.log(item)
                    this.title3 = item
                    this.downloadSrt(item)
                },
                handleVoice(item) {
                    this.title2 = item
                    this.updateVoiceVideoText(item)
                },
                handleGender (item) {
                    console.log('item', item)
                    this.title1 = item
                    this.title2 = 'choose'
                    this.updateGenderVideoText(item)
                    this.updateVoiceVideoText(this.title2)

                },
                updateVoiceVideoText(item){
                    // console.log('updateGenderVideoText', item)
                    const segmentArray = Object.values(this.videoTextTranlate)
                    segmentArray.map(el=> {el.current_voice = item})
                    this.updateVideoText(segmentArray)
                    
                },

                updateGenderVideoText(item){
                    console.log('updateGenderVideoText', item)
                    const segmentArray = Object.values(this.videoTextTranlate)
                    segmentArray.map(el=> {el.gender = item})
                    this.updateVideoText(segmentArray)
                },
                handleCountSegments(count){
                    this.countSegmentsText = count
                    console.log('this.countSegmentsText', this.countSegmentsText)
                },
                updateVideoText(videoText) {
                    console.log('updateVideoText', videoText)
                    const obj = {};

                    videoText.forEach(function(item, index) {
                    const key =  index;
                    obj[key] = item;
                    });
                    this.videoTextTranlate = obj   
                    console.log('updateVideoText', obj)
                }, 
                copyText(text) {
                    if (!navigator.clipboard) {
                    console.log('navigator.clipboard не підтримується');
                    return;
                    }
                    console.log('Текст для копіювання: ', text);

                    navigator.clipboard.writeText(text)
                    .then(() => {
                        console.log('Текст скопійовано в буфер обміну: ', text);
                    })
                    .catch((error) => {
                        console.error('Помилка при копіюванні тексту: ', error);
                    });
                },

                formatTime(timeInMilliseconds) {
                    const date = new Date(timeInMilliseconds);
                    const hours = date.getUTCHours().toString().padStart(2, '0');
                    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
                    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
                    // const milliseconds = date.getUTCMilliseconds().toString().padStart(3, '0');
                    // const firstTwoDigits = milliseconds.slice(0, 3);
                    return `${hours}:${minutes}:${seconds}`;

                },

                toggleSexDropdown(segmentIndex) {
                    this.sexShow1[segmentIndex] = !this.sexShow1[segmentIndex];
                    console.log('toggleSexDropdown', this.sexShow1[segmentIndex])
                },
                isSexDropdownVisible(segmentIndex) {
                    // Перевіряємо, чи відповідний dropdown для сегменту видимий
                    // console.log('isSexDropdownVisible', this.sexShow1[segmentIndex])
                    return this.sexShow1[segmentIndex] === true;
                },

                toggleVoiceDropdown(segmentIndex) {
                    // При кліку на кнопку відкриття списку "Sex", переключаємо стан для відповідного сегменту
                    
                    this.sexShow2[segmentIndex] = !this.sexShow2[segmentIndex];
                    console.log('toggleSexDropdown', this.sexShow2[segmentIndex])
                },
                isVoiceDropdownVisible(segmentIndex) {
                    // Перевіряємо, чи відповідний dropdown для сегменту видимий
                    // console.log('isSexDropdownVisible', this.sexShow2[segmentIndex])
                    return this.sexShow2[segmentIndex] === true;
                },

                
                async downloadAudio() {
                    try {
                    await this.$store.dispatch('video/downloadAudio', this.videoId);
                    } catch (error) {
                    console.error(error);
                    }
                },

                async downloadVideo() {
                    try {
                        console.log('downloadVideo', this.videoId )
                    await this.$store.dispatch('video/downloadVideo', this.videoId);
                    } catch (error) {
                    console.error(error);
                    }
                },
                async downloadXls(item) {
                    try {
                        console.log('downloadXls', this.videoId, item )
                    await this.$store.dispatch('video/downloadXls', { videoId: this.videoId, type_of_align: item });
                    } catch (error) {
                    console.error(error);
                    }

                },
                async downloadSrt(item) {
                        try {
                            console.log('downloadSrt', this.videoId, item )
                        await this.$store.dispatch('video/downloadSrt', { videoId: this.videoId, text: item });
                        } catch (error) {
                        console.error(error);
                        }

                    },

                    async openGoogleSheet() {
                    if(!this.isActiveSecondaryButton) { 
                        this.isActiveSecondaryButton = true

                    try{
                        const sessionKey = sessionStorage.getItem("auth_key");
                        if (!sessionKey) throw new Error("No session key available.");
                
                        const response = await fetch(`${apiUrl}/api/open-google-sheets`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ auth_key: sessionKey, video_id: this.videoId }),
                        });
                        if (response.ok) {
                            // this.isActiveSecondaryButton = true
                            const data = await response.json();
                            console.log('openGoogleSheet ok data', data)
                            // const videoBlob = await response.blob();
                            // const url = URL.createObjectURL(videoBlob);
                            // const a = document.createElement("a");
                            // a.href = url;
                            // a.download = `${videoId}_segments.xls`;
                            // document.body.appendChild(a);
                            // a.click();
                            // URL.revokeObjectURL(url);
                            // const videoId = "your_video_id"; // Замініть це на ваш ID відео

                            const googleSheetId = data.google_sheet_id; // Замініть це на ID вашої Google Таблиці
                            this.isGoogleSheet = googleSheetId
                            const googleSheetLink = `https://docs.google.com/spreadsheets/d/${googleSheetId}/edit`;
                            this.linkOnTheTable = googleSheetLink
                            console.log(this.isGoogleSheet, this.linkOnTheTable)
                            

                            // const a = document.createElement("a");
                            // a.href = googleSheetLink;
                            // a.target = "_blank"; // Відкрити у новій вкладці
                            // a.textContent = "Відкрити Google Таблицю";

                            // document.body.appendChild(a);

                        } else {
                            console.error("Backend Error: ", response.statusText);
                            this.isActiveSecondaryButton = false

                        }
                    }catch(error){
                        console.error(error);
                        this.isActiveSecondaryButton = false

                    }
                }else {
                        console.log('this.isActiveSecondaryButton', this.isActiveSecondaryButton)
                        return
                        }
                },

                async updateGoogleSheets(){
                    if((this.status === 6 || this.status === 9) && !this.isActiveUpdateButton) {
                        console.log('updateGoogleSheets')
                        this.isActiveUpdateButton = true
                        try{
                            const sessionKey = sessionStorage.getItem("auth_key");
                        if (!sessionKey) throw new Error("No session key available.");
                        const dataToSend = { 
                            video_info: this.videoTextTranlate,
                            auth_key: sessionKey, 
                            video_id: this.videoId,
                            need_align: this.needAlign
                        };
                        console.log('dataToSend', dataToSend)
                
                        const response = await fetch(`${apiUrl}/api/update-video-json-to-google-sheet`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify( dataToSend ),
                        });
                        if(response.ok) {
                            console.log('response.ok')
                            const data = await response.json();
                            console.log('updatedGoogleSheets data - ', data)

                            await this.delayTime(5)
                            this.isActiveUpdateButton = false


                        } else {
                            console.log('error')
                            this.isActiveUpdateButton = false
                        }


                    } catch (error) {
                        console.error(error)
                        this.isActiveUpdateButton = false
                    }
                } else {
                    console.log('no, staus not 6 || 9')
                    return
                }
                },

                async startSynchronize() {
                    if((this.status === 6 || this.status === 9) && !this.isActiveSynchronizeButton) {
                        console.log('startSynchronize')
                        this.isActiveSynchronizeButton = true
                    try {
                        const sessionKey = sessionStorage.getItem("auth_key");
                        if (!sessionKey) throw new Error("No session key available.");
                
                        const response = await fetch(`${apiUrl}/api/update-video-json-from-google-sheet`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ auth_key: sessionKey, video_id: this.videoId }),
                        });
                        if(response.ok) {
                            const data = await response.json();
                            console.log('startSynchronize data - ', data)
                            this.videoTextTranlate = data.updated_video_json

                            await this.delayTime(5)
                            this.isActiveSynchronizeButton = false


                        } else {
                            console.log('error')
                            this.isActiveSynchronizeButton = false

                        }

                    } catch (error) {
                        console.error(error)
                        this.isActiveSynchronizeButton = false

                    }
                } else {
                    console.log('no, staus not 6 || 9')
                    return
                }

                },

                handleLanguageSelected(selectedLanguage) {
                    this.isLanguageSelected = true;
                    this.selectedLanguage = selectedLanguage
                    console.log('Вибрана мова:', selectedLanguage);
                    },

                async sendDataToConvert() {
                    try{
                        console.log('sendDataToConvert')
                        
                        const sessionKey = window.sessionStorage.getItem('auth_key')
                        console.log('sessionKey actions', sessionKey)
                        if (!sessionKey) throw new Error("No session key available.");
                        console.log('videoTextTranlate', this.videoTextTranlate)

                        const segmentArray = Object.values(this.videoTextTranlate)
                        const segments = segmentArray.filter(item=> item.current_voice =="" || item.current_voice =="choose voice" ||item.current_voice == null ||item.current_voice == 'choose')
                        console.log('segments', segments, segments.length)
                        if (segments.length>0) {
                            this.plashkaVisible = true
                            this.showConfirm = false
                        } else {
                            this.showConfirm = false
                            this.isVoiceSend = true;

                        const dataToSend = {
                            video_info: this.videoTextTranlate,
                            auth_key: sessionKey, 
                            video_id: this.videoId,
                            need_align: this.needAlign
                        };
                    
                        const response = await fetch(`${apiUrl}/api/save-info-about-video`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(dataToSend)
                        });
                
                        if (response.ok) {
                            const data = await response.json();
                            console.log('Дані для озвучки успішно відправлені на сервер:', data);
                            
                            if (data.success) {
                                this.waitingVoice = true
                                this.isVoiceSend = true
                                this.successConvert = true
                                this.errorConvert = false
                                this.successMessageConvert = data.message
                                this.errorMessageConvert = ""
                                this.getVideoInfo();
                                await this.delayTime(3)
                                this.successMessageConvert = ""
                                this.errorMessageConvert = ""


                            } else {
                                console.error("Backend Error: ", data.error);
                                this.errorConvert = true
                                this.successConvert = false
                                this.errorMessageConvert = data.error
                                this.successMessageConvert = ""
                            }
                            } else {
                                console.error("Network Response Error");
                                this.errorMessageConvert = "Network Response Error"
                            }
                            }
                    } catch (error) {
                        console.error('Помилка під час відправки даних на сервер:', error);
                        this.errorMessageConvert = "Network Response Error"
                    }
                },

                async getVideoInfo() {
                    try{

                        const sessionKey = window.sessionStorage.getItem('auth_key')
                        // console.log('sessionKey actions', sessionKey)
                        if (!sessionKey) throw new Error("No session key available.");

                        const url = new URL(window.location.href);
                        const pathname = url.pathname;
                        const videoIdFromUrl = pathname.substring(pathname.lastIndexOf('/') + 1);
                        // console.log(videoIdFromUrl)
                        this.videoId = videoIdFromUrl

                        const response = await fetch(`${apiUrl}/api/get-full-info-about-video`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ 
                                auth_key: sessionKey, 
                                video_id: this.videoId }),
                        });
                        if (response.ok) {
                            const data = await response.json();
                            console.log('data', data)
                        if (data.success) {
                            if(data.status == 1 || data.status == 2){
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.showSettingsBlock = false
                                this.waitingSetting = true
                                this.status = data.status
                                this.fileType = data.file_type
                                await this.delayTime(10);
                                this.getVideoInfo()
                            }
                            if(data.status == 3){
                                this.showSettingsBlock = true
                                this.waitingSetting = false
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.basicLang = data.video_basic_lang
                                this.status = data.status
                                this.fileType = data.file_type
                            }
                            if(data.status == 4 || data.status == 5){
                                this.showSettingsBlock = true
                                this.beforTranslate = false
                                this.afterTranslate = true
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.basicLang = data.video_basic_lang
                                this.translaitLang = data.video_target_lang
                                this.status = data.status
                                this.fileType = data.file_type
                                this.waitingTranslate = true
                                await this.delayTime(10);
                                this.getVideoInfo()

                            }
                            if(data.status == 6){ // отримали сегменти
                                this.waitingTranslate = false
                                this.showSettingsBlock = true
                                this.beforTranslate = false
                                this.afterTranslate = true
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.basicLang = data.video_basic_lang
                                this.translaitLang = data.video_target_lang
                                this.isTranscriptionBlock = true
                                const decoderTranslated = data.video_info
                                const lists = data.video_info
                                this.fileType = data.file_type
                                this.isGoogleSheet = data.google_sheet_id
                                // this.$store.commit('setSegmentLists', lists)
                                this.$store.dispatch('video/getSegmentLists', lists)
                                this.$store.dispatch('video/getAllVoices', lists)
                                // console.log(data.video_info)
                                this.decoderSimbol(decoderTranslated)
                                // for (let value in decoderTranslated){
                                //     const coder = decoderTranslated[value].translated_text
                                //     console.log('coder',coder)
                                //     if(coder){
                                //         console.log('have coder')
                                //         if(coder.includes('&#39;')){
                                //             console.log('coder includes "&#39;"')
                                //             const coder1 = coder.replace(/&#39;/g, "'")
                                //             console.log(coder.replace(/&#39;/g, "'"))
                                //             decoderTranslated[value].translated_text = coder1
                                //         } else {
                                //             console.log('coder do not includes simbols')
                                //         }    
                                        
                                //     }
                                // }
                                this.status = data.status
                                console.log(this.status)
                            }
                            if(data.status == 7 || data.status == 8){ // відправили і процес озвучки
                                this.showSettingsBlock = true
                                this.beforTranslate = false
                                this.afterTranslate = true
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.basicLang = data.video_basic_lang
                                this.translaitLang = data.video_target_lang
                                this.isTranscriptionBlock = true
                                this.isVoiceSend = true
                                this.isReadOnly = true
                                this.fileType = data.file_type
                                this.isGoogleSheet = data.google_sheet_id
                                // this.videoTextTranlate = data.video_info
                                const decoderTranslated = data.video_info
                                const lists = data.video_info
                                this.$store.dispatch('video/getSegmentLists', lists)
                                this.$store.dispatch('video/getAllVoices', lists)
                                // console.log(data.video_info)
                                this.decoderSimbol(decoderTranslated)
                                this.status = data.status
                                console.log(this.status)
                                this.isDownload = false
                                this.waitingVoice = true
                                await this.delayTime(10);
                                this.getVideoInfo()

                            }
                            if(data.status == 9){ // є голоте відео для скачування
                                this.status = data.status
                                console.log(this.status)
                                this.waitingVoice = false
                                this.showSettingsBlock = true
                                this.beforTranslate = false
                                this.afterTranslate = true
                                this.fileName = data.video_name;
                                this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                                this.basicLang = data.video_basic_lang
                                this.translaitLang = data.video_target_lang
                                this.isTranscriptionBlock = true
                                this.fileType = data.file_type
                                this.isGoogleSheet = data.google_sheet_id
                                // this.videoTextTranlate = data.video_info
                                // console.log('videoTextTranlate', this.videoTextTranlate)
                                const decoderTranslated = data.video_info
                                this.$store.dispatch('video/getSegmentLists', decoderTranslated)
                                this.$store.dispatch('video/getAllVoices', decoderTranslated)

                                // console.log(data.video_info)
                                this.decoderSimbol(decoderTranslated)
                                this.isVoiceSend = false //разблокувати
                                this.isReadOnly = false  //разблокувати
                                this.isDownload = true // відкрили кнопку скачати
                            }
                        } else {
                            console.error("Backend Error: ", data.error);
                        }
                        } else {
                            console.error("Network Response Error");
                        }
                        } catch (err) {
                            console.error(err);
                        }
                },
                decoderSimbol(decoderTranslated) {
                    for (let value in decoderTranslated){
                        const coder = decoderTranslated[value].translated_text
                        // console.log('coder',coder)
                        if(coder){
                            // console.log('have coder')
                            if(coder.includes('&#39;')){
                                console.log('coder includes "&#39;"')
                                const coder1 = coder.replace(/&#39;/g, "'")
                                console.log(coder.replace(/&#39;/g, "'"))
                                decoderTranslated[value].translated_text = coder1
                            } else {
                                // console.log('coder do not includes simbols')
                            }    
                        }
                    }
                    this.videoTextTranlate = decoderTranslated
                    console.log('this.videoTextTranlate', this.videoTextTranlate)
                },


                // async fetchVideo() {
                //     try {
                //         const sessionKey = window.sessionStorage.getItem('auth_key')
                //         console.log('sessionKey actions', sessionKey)

                //         if (!sessionKey) throw new Error("No session key available.");
                    
                //         const url = new URL(window.location.href);
                //         const pathname = url.pathname;
                //         const videoIdFromUrl = pathname.substring(pathname.lastIndexOf('/') + 1);
                //         console.log(videoIdFromUrl)
                //         this.videoId = videoIdFromUrl

                //         const response = await fetch(`${apiUrl}/api/get-video-basic-info`, {
                //             method: "POST",
                //             headers: {
                //                 "Content-Type": "application/json",
                //             },
                //             body: JSON.stringify({ 
                //                 auth_key: sessionKey, 
                //                 video_id: this.videoId }),
                //         });
                //         console.log('response', response)

                //         if (response.ok) {
                //             const data = await response.json();
                //             console.log('data', data)
                //         if (data.success) {
                //             // this.showSettingsBlock = true; // open settings block!
                //             this.fileName = data.video_name;
                //             this.fileSize = parseFloat(data.video_size / (1024 * 1024)).toFixed(2);
                //             // this.responseData = data;
                //             console.log('data file edit', data)

                //             const timerId = setInterval(() => {
                //             if (this.$route.path.includes('/home/projects/editproject/')) {
                //                 this.fetchDataUploadedfile(); 
                //             } else {
                //                 clearInterval(timerId); 
                //             }
                //             }, 10000);

                //         } else {
                //             console.error("Backend Error: ", data.error);
                //         }
                //         } else {
                //             console.error("Network Response Error");
                //         }
                //     } catch (err) {
                //         console.error(err);

                //     }
                // },

                // async fetchDataUploadedfile() {
                //     try {
                //         const sessionKey = window.sessionStorage.getItem('auth_key')
                //         console.log('sessionKey actions', sessionKey)

                //         if (!sessionKey) throw new Error("No session key available.");

                //         const response = await fetch(`${apiUrl}/api/get-video-orig-lang`, {
                //         method: "POST",
                //         headers: {
                //             "Content-Type": "application/json",
                //         },
                //         body: JSON.stringify({ 
                //             auth_key: sessionKey, 
                //             video_id: this.videoId }),
                //         });
                //         console.log('response', response)

                //         if (response.ok) {
                //         const data = await response.json();
                //         console.log('data', data)
                //         if (data.success) {
                //             this.showSettingsBlock = true; // open settings block!
                //             this.basicLang = data.basic_lang;
                //             this.fileNameSet = data.video_name;
                //             this.responseData = data;
                //             console.log('data file language', this.responseData)
                //         } else {
                //             console.error("Backend Error: ", data.error);
                //         }
                //         } else {
                //         console.error("Network Response Error");
                //         }
                //     } catch (err) {
                //         console.error(err);
                //     }
                // },
            

                async translateFile() {
                    if(!this.selectedLanguage) {
                        console.log('!this.selectedLanguage')
                        return
                    } else {
                try {
                    // this.isProgressBar2 = true;
                    const sessionKey = window.sessionStorage.getItem('auth_key')
                    console.log('sessionKey actions', sessionKey)
                    console.log('Вибрана мова:', this.selectedLanguage);

                    if (!sessionKey) throw new Error("No session key available.");

                const response = await fetch(`${apiUrl}/api/translate-video`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        video_id: this.videoId,
                        target_lang: this.selectedLanguage,
                        auth_key: sessionKey
                    }),
                })
                    if (response.ok) {
                        const data = await response.json();
                        console.log('data', data)
                        if (data.success) {
                            this.waitingTranslate = true
                            this.responseData = data;
                            this.beforTranslate = false
                            this.afterTranslate = true
                            this.errorTranslate = false
                            this.translaitLang = this.selectedLanguage
                            this.successMessageTranslate = data.message
                            console.log('data.message', data.message)
                            this.successTranslate = true
                            console.log('data file translait', data)

                            this.getVideoInfo();
                        } else {
                            console.error("Backend Error: ", data.error);
                        }
                        } else {
                        console.error("Network Response Error");
                        }
                    } catch (err) {
                        console.error(err);
                    }                  
                }
                },

                // async getTextTranscription() {
                //     try{
                //         const sessionKey = window.sessionStorage.getItem('auth_key')
                //         console.log('sessionKey actions', sessionKey)
                //         if (!sessionKey) throw new Error("No session key available.");

                // const response = await fetch(`${apiUrl}/api/get-info-about-video`, {
                //     method: 'POST',
                //     headers: {
                //         "Content-Type": "application/json",
                //     },
                //     body: JSON.stringify({
                //         video_id: this.videoId,
                //         // target_lang: this.selectedLanguage,
                //         auth_key: sessionKey
                //     }),

                // })
                // if (response.ok) {
                //         const data = await response.json();
                //         console.log('data', data)
                //         if (data.success) {
                //             if(data.info == false){
                //                 this.isTranscriptionBlock = false //open transcription block

                //             } else{
                //                 this.isTranscriptionBlock = true //open transcription block
                //                 this.waitingTranslate = false
                //                 this.videoTextTranlate = data.info

                //             }
                            
                //             console.log('data file info', data)
                //         } else {
                //             console.error("Backend Error: ", data.error);
                //         }
                //         } else {
                //         console.error("Network Response Error");
                //         }
                //     } catch (err) {
                //         console.error(err);
                //     }
                
                // },

                // grow() {
    //                 console.log('grow')
    //   if (this.$refs.textarea) {
    //     // this.$refs.textarea.style.height = "auto";
    //     this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + "px";
    //   }
    // },

            }

    }
  
</script>

<style scoped>

.waiting {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    margin-right: 40%;
}

.waitingFile {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    justify-content: left;
    gap: 5px;
}

.weaitFile {
    display: flex;
    flex-direction: row;
    /* position: absolute; */
    align-items: center;
    
}
.loading {
    position: relative;
    right: -10px;
    
}

.dropdownLanguageList {
    position: relative;
}
    .select{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px 16px;
        gap: 12px;
        height: 100px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 2px;
        margin: 12px 24px 12px 24px;
    }
    .settings{
        /* height: 72px; */
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px;
        gap: 12px;
        margin-left: 24px;
        margin-right: 24px;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 2px;
        margin-bottom: 12px;
    }
    .activeSettings {       
        /* height: 273px; */
        display: flex;
        flex-direction: column;
    }
    /* .settings6 {
        height: 190px;
    } */

    .settingActive {
        box-sizing: border-box;
        width: 100%;
        /* height: 56px; */
        padding: 0px 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
   
    
    .headSetting,
    .headDownload{
        font-size: 16px;
        color: #000000;
        opacity: 45%;
        text-align: left;
    }
    .activeHead {
        color: #000000;
        opacity: 85%;
    }
    .descriptionFile{
        box-sizing: border-box;
        width: 100%;
        height: 72px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }
    .translation {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }

    
    .beforTranslate {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    }

    /* .afterTranslate {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: space-between;
        align-items: center;
    } */

    .textBlock {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 10px;
    }
    .text{
        font-size: 16px;
        color: #000000;
        opacity: 45%;
        width: 120px;
        text-align: left;
    }

    .aboutFile {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        gap: 5px;
    }
    

.fileInfo {
    box-sizing: border-box;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    align-items: center;
}

/* .translateButton {
    height: 32px;
    width: 130px;
    background-color: rgba(24, 144, 255, 1);
    border: 1px solid rgba(24, 144, 255, 1);
    border-radius: 2px;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.translateButton:disabled {
  background-color: lightgray;
  color: gray;
  border: 1px solid rgba(217, 217, 217, 1);
  cursor: not-allowed;
} */
.messageBlock {
    /* color: green; */
    font-size: 16px;
    box-sizing: border-box;
    width: 100%;

}
.successConvert {
        color: green;
}
.errorConvert {
    color: red;
}

    
    /* ========== */
    .editButton {
        height: 32px;
        cursor: pointer;
        display: block;
        border: 1px solid #1890FF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        background-color: #1890FF;
        color: white;
        height: 32px;
        font-size: 14px;
    }
    .title{
        box-sizing: border-box;
        height: 56px;
        padding: 0px 24px;
        text-align: start;
        width: 100%;
    }
    .file{
        box-sizing: border-box;
        background: #F3F0FF;
        border: 1px dashed #C1B2FA;
        border-radius: 8px;
        height: 160px;
        /* margin-left: 16px; */
        margin-right: 16px;
        flex: none;
        width: 100%;
    }

    .progress-bar-block{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px;
        gap: 12px;
        height: 72px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px #EAE2FD;
        border-radius: 8px;
        box-sizing: border-box;
        width: 100%;
    }
    .nameFile {
        font-size: 14px;
        /* width: 332px; */
        height: 18px;
        padding: 0;
        margin: 0;
        /* width: 70%; */
        text-align: left;
    
    }
    .infoLoading{
        width: 100%;
        box-sizing: border-box;
        height: 56px;
    
    }
    .sizeFile {
        font-weight: 500;
        font-size: 12px;
        line-height: 130%;
        color: #857E95;
        padding: 0;
        margin: 0;
        /* width: 50%; */
        text-align: left;
    }

    .lang {
        color: #20EF4D;
        }
    .langTarget {
        color: #8720EF;
    }
/* ========== */
.download{
        height: 52px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px 16px;
        margin-left: 24px;
        margin-right: 24px;
        border: 1px solid rgba(0, 0, 0, 0.03);
        border-radius: 2px;
        margin-bottom: 10px;
    }

.download-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    padding: 10px 24px;
    align-items: center;
}

.download-info-part {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    align-items: center;
}

.download-button {
        height: 32px;
        cursor: pointer;
        display: block;
        border: 1px solid #1890FF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        background-color: #1890FF;
        color: white;
        height: 32px;
        font-size: 14px;
        padding: 3px 12px;
    }
.headBlock {
    height: auto;
    padding: 0px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    box-sizing: border-box;
    width: 100%;
}        


.download-info-text,
.headDownload-active,
.headDownload-active {
    color: #000000;
    opacity: 85%;
}

/* .download-active {
    height: 100px;
} */

/* ========== */
 .parts-wrapper {
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px;
    /* gap: 12px; */
    margin-left: 24px;
    margin-right: 24px;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-radius: 2px;
    margin-bottom: 12px;
    
    }

.parts-list {
    position: relative;
    list-style: none;
    padding-left: 24px;
    width: 100%;
    box-sizing: border-box;
}

.part-bt {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        /* padding-left: 24px; */
        gap: 20px;
    }
    .part-bt-under{
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 24px;

}
     .bt-voice {
        height: 32px;
        cursor: pointer;
        display: block;
        border: 1px solid #1890FF;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 2px;
        background-color: #1890FF;
        color: white;

     }
     .img-save{
        height: 20px;
        width: 20px;

     }
     .img-save-no-active {
        opacity: 60%;
        cursor: not-allowed;
     }

     .save-project-button {
        display: flex;
        gap: 3px;
     }



.main-title {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;    
}
.main-title-active{
    box-sizing: border-box;
    width: 100%;
    height: auto;
    /* padding-left: 24px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;    

}


.part-main-title{
    color: #2a2a2a80;
    font-size: 16px;
    text-align: left;
}

.part-main-title-active {
    color: #000000;
    opacity: 85%;
}


.parts-time-wrapper {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.dropdown-btn {
    cursor: pointer;
    width: 100px;
    height: 27px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 100px;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 3px 5px;
    margin-bottom: 5px;
    z-index: 99;
}

.dropdown-content-item {
    padding: 3px 0px;
}
.parts-drop-wrapper {
  display: flex;
}

/* .parts-drop-wrapper .dropdown {
  margin-right: 10px;
} */

.parts-drop-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

.clicked{
    background-color: lightgray;
    color: gray;
    border: 1px solid rgba(217, 217, 217, 1);
    cursor: not-allowed;
}

.arrowImg {
    display: block;
    height: 20px;
    width: 20px;
    opacity: 30%;
}

.commonGender,
.commonVoice {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 3px;
}

.need-align-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 3px;
}
.speed-for-all-block {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.part-bt-line-block{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
}
.buton-google {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}
@media screen and (max-width: 1197px) {
    .part-bt{
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* padding-left: 24px; */
        gap: 20px;
        flex-wrap: wrap;
    } 
}
@media screen and (max-width: 1080px) {
    .parts-header{
        flex-direction: column;
        align-items: flex-start;
    }

    .parts-drop-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .parts-speaker, .parts-drop-title, .dropdown {
        margin-bottom: 20px;
    }


    /* .download-active {
        height: 140px;
    } */


    .main-title {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
    }

    .part-bt {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 20px;

    }

    /* .commonGender, .commonVoice {
        margin-bottom: 20px;
    } */

    .parts-list {
        padding-left: 24px;
    }

    .parts-number {
        padding: 2px;
    }
    
    .secondary-button {
        margin-top: 20px;
    }
    .waiting {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 3px;
        margin-right: 40%;
    }

}
@media screen and (max-width: 673px) {
    .part-bt-line-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}
.download{
    height: auto;
}
.headBlock {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

.download-info {
        flex-direction: column;
        width: auto;
        padding: 0;
        gap: 20px;
        height: auto;
    }
.buton-google {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
}


}

@media screen and (max-width: 426px) {
    .dropzone{
        height: 200px;
    }
    .afterSelect{
        height: 160px;
    }
    .translation {
        flex-direction: column;
        justify-content: flex-start;
    }
    .activeSettings{
        height: 380px;
    }
    .part-bt{
        padding: 0;
    }
    .part-bt-under{
        flex-direction: column;
        gap: 20px;
}
.download-info{
    height: auto;
}


}

</style>
