<template>
    <div  @click.stop >
        <select @change="changeLanguage">
            <option value="ua">UA</option>
            <option value="eng">ENG</option>
        </select>
  </div>
</template>

<script>
    export default {
        methods: {
            changeLanguage(event) {
                const selectedLanguage = event.target.value;
                console.log(selectedLanguage); 
                // Тут ви можете виконати необхідні дії з обраною мовою
                if( selectedLanguage === 'ua'){
                    console.log(' you change ukranian')
                } else {
                    console.log(' you change english')
                }
                this.$emit('language-changed', selectedLanguage);
            },
        },
    };
</script>

<style scoped>
    /* .language-dropdown {
        position: absolute;
        z-index: 999;
        top: 20px;
        right: 50px;
        color: black;
    } */
</style>