<template>
    <div class="wrap-list">
        <div class="parts-drop-title">
                            <div class="dropdown" ref="'dropdown1' + segmentIndex">
                                <div class="dropdown-btn" :class="buttonClass" @click="isDropdownVisible = !isDropdownVisible">
                                    <p class="dropdown-btn-title">{{  title }}</p> 
                                    <img :src="imagePath" class="arrowImg"/>
                                </div>
                                    <div class="dropdown-content" :class="buttonClass1" v-show="isDropdownVisible">
                                        <div v-for="(item, index) in list" :key="index" class="dropdown-content-item">
                                            <input
                                            type="radio"
                                            :id="`radio1-${index}`"
                                            :value="item"
                                            @click.stop="updateItemForTitle(item, index)"
                                            :disabled="readOnly"
                                            :checked="selectedItem === item"
                                            />
                                            <label :for="`radio1-${index}`" @click.prevent class="label">{{ item }}</label>
                                        </div>
                                    </div>
                            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'dropdown-list',
    props: {
        title: {
            type: String
        },
        list: {
            type: Object
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        imagePath: String,
        buttonClass: String,
        buttonClass1: String,

    },
    data() {
        return {
            isDropdownVisible: false,
            selectedItem: null,
            // title: 'choose',

        }
    },
    computed: {
        getImagePath() {
            return require("@/icon/arrow_down.png")
        }, 

    },
    mounted() {
        // console.log('dropdown list mounted', this.list)
        // console.log('dropdown list mounted', this.title)

    },
    methods: {
        updateItemForTitle(item, index) {
            // console.log(item, index)
            this.selectedItem = item
            this.isDropdownVisible = false
            this.$emit('updateItem', this.selectedItem, index)
            
        }
    }

}

</script>

<style scoped>

.wrap-list {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.dropdown-btn {
    cursor: pointer;
    width: auto; 
    min-width: 100px;
    /* height: 27px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;
}
.dropdown-btn-title{
    padding: 2px;
    margin: 0;
}

.dropdown-btn-width {
    cursor: pointer;
    width: auto; 
    min-width: 185px;
    /* height: 27px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;

}
.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    min-width: 100px;
    width: auto;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 3px 5px;
    margin-bottom: 5px;
    z-index: 99;
    overflow: scroll;
    max-height: 283px;
}
.dropdown-btn-content-width {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 185px;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    /* padding: 3px 5px;
    margin-bottom: 5px; */
    z-index: 99;

}

.parts-drop-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
}

/* .dropdown {
  margin-right: 20px;
} */
.dropdown-content-item {
    padding: 3px 0px;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.label {
    text-align: left;
}
.dropdown-btn-srt{
    cursor: pointer;
    width: auto; 
    width: 175px;
    /* height: 27px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: 16px;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    background-color: white;
}
.dropdown-btn-content-srt {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 175px;
    background:  white;
    border: 1px solid rgba(217, 217, 217, 1);
    border-radius: 2px;
    padding: 3px 5px;
    margin-bottom: 5px;
    z-index: 99;
    overflow: scroll;
    max-height: 283px;

}

</style>