
import { apiUrl } from '../components/api'

const state = {
  languageList: [],
};

const mutations = {
  setLanguageList(state, languageList) {
    state.languageList = languageList;
  },
};

const actions = {
  async getTargetLangsList({ commit }) {
    try {
        const sessionKey = window.sessionStorage.getItem('auth_key')

        
      if (!sessionKey) throw new Error("No session key available.");

      const response = await fetch(`${apiUrl}/api/get-target-langs-lists`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ auth_key: sessionKey }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          commit('setLanguageList', data.target_langs_list);
        } else {
          console.error("Backend Error: ", data.error);
        }
      } else {
        console.error("Network Response Error");
      }
    } catch (err) {
      console.error(err);
    }
  },
};

const languages = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default languages;
