<template>
    <div class="wrap-projectList" > 
        <table>
            <thead>
                <tr>
                    <th><input type="checkbox" class="checkbox" v-model="selectAll" @change="checkAllRows" :indeterminate="isIndeterminate" :checked="isAllSelected"/></th>
                    <th>Name</th>
                    <th>Translate</th>
                    <th>
                        <div class="sort-duration">
                            <p class="text-title">Duration</p>
                            <div class="sort">
                                <div class="triangle-up" @click="sortUp('duration')" :class="{'up': upDuration}"></div>
                                <div class="triangle-down" @click="sortDown('duration')" :class="{'down': downDuration}"></div>
                            </div>
                        </div>
                    </th>
                    <th>Basic Language</th>
                    <th>Target Language</th>
                    <th>Segments</th>
                    <th>
                        <div class="sort-time">
                            <p class="text-title">Last updated time</p>
                            <div class="sort">
                                <div class="triangle-up" @click="sortUp('time')" :class="{'up1': upTime}"></div>
                                <div class="triangle-down" @click="sortDown('time')" :class="{'down1': downTime}"></div>
                            </div>
                        </div>

                    </th>
                    <th>Deletion time of video</th>
                    <th>Action</th>
                </tr>
            </thead>
            
                <tbody >
                    <tr v-for="item in tableData" :key="item.id" :data-key="item.id" >
                        <td><input type="checkbox" class="checkbox" :value="item.id" v-model="item.selected"/></td>
                        <td>
                            <div class="cell-text-container ">
                                <div class="cell-text">
                                    {{ item.name }}
                                </div>
                            </div>
                        </td>
                        <td>{{ item.translation? "Yes" : "No" }}</td>
                        <td>{{ formattedTime(item.duration)  }}</td>
                        <td>{{ item.basic_lang }}</td>
                        <td>{{ item.target_lang }}</td>
                        <td class="shorten-text">{{ item.info? "Yes" : "No" }}</td>
                        <td>{{ item.updated_on }}</td>
                        <td>{{ item.deletion_time }}</td>
                        <td class="td-buttons">
                            <button class="td-button" @click="editProject(item.id)">Edit</button>
                            <button class="td-button" @click="downloadVideo(item.id)" :class="{'notActivButton': !item.translation || item.file_type !== 'video'}">Download video</button>
                            <button class="td-button" @click="downloadAudio(item.id)" :class="{'notActivButton': !item.translation}">Download audio</button>
                            <button class="td-button" @click="promptDelete(item.id)">Delete</button>
                        </td>
                    </tr>
                </tbody>
        </table>
                  
        <div class="pagination">
            <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
            <p>{{ currentPage }}</p> / <p>{{ totalPages }}</p>
            <button @click="nextPage" :disabled="currentPage === totalPages">next</button>
        </div>
           
        <!-- New Modal for confirmation -->
        <div v-if="showModal" class="modal-background" @click.self="showModal = false">
            <div class="modal-content">
                <h2>Confirm Delete</h2>
                <p>Are you sure you want to delete this video?</p>
                <div class="modal-button">
                    <button @click="confirmDelete">Confirm</button>
                    <button @click="showModal = false">Cancel</button>
                </div>

            </div>
        </div>
        <plashka-modal v-show="isModal"
        :show-reminder="isModal" 
        @update:show-reminder="isModal = $event"
        >
            You have not selected projects
        </plashka-modal>
        <div class="popup-wrap" v-show="waitingDownloadFile">
            <div class="popup">
                <p class="weaitFile">Please wait, the file is being prepared for downloading</p>
                <loading-spinner/>   
            </div>
        </div>
    </div>
</template>

<script>
import PlashkaModal from './UI/PlashkaModal.vue';

export default {
  components: { PlashkaModal },
 

    name: "ProjectList",
    data() {
        return {
            currentPage: 1,
            itemsPerPage: 6,
            totalPages: 0,
            showModal: false,
            currentVideoId: null,
            selectAll: false,
            durationSort: 'none', // 'none', 'asc', 'desc'
            timeSort: 'none',    
            upDuration: false,
            downDuration: false,
            upTime: false,
            downTime: false,

            isModal: false

            // isText: "Yes",
            // noText: "No"
           
        }
    },
     created(){
        this.totalPages = Math.ceil(this.$store.state.video.videoList.length / this.itemsPerPage);
        // this.$store.dispatch('video/fetchVideoList');
        // console.log(this.$store.state.video.videoList)

    },
    
    computed: {
        waitingDownloadFile() {
                console.log('waitingDownloadFile', this.$store.state.video.waitingDownloadFile)
                return this.$store.state.video.waitingDownloadFile
        },
        tableData() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            // console.log('computed tableData', this.$store.state.video.videoList.length)
            return this.$store.state.video.videoList.slice(startIndex, endIndex);
        },
        isIndeterminate() {
            // console.log('isIndeterminate')
            return this.tableData.length > 0 && !this.isAllSelected && !this.isNoneSelected;
        },
        isAllSelected() {
            // console.log('isAllSelected')
            return this.tableData.length > 0 && this.tableData.every(item => item.selected);
        },
        isNoneSelected() {
            // console.log('isNoneSelected')
            return this.tableData.length > 0 && this.tableData.every(item => !item.selected);
        },
        // totalPages() {
        //     return Math.ceil(this.$store.state.video.videoList.length / this.itemsPerPage)
        // },
    },

    // mounted() {
    //     this.fetchVideoList();
    //     console.log('mounted projectList')
    // },
    methods: {
        sortUp(column) {
            console.log('sortUp')
            if (column === 'duration') {
                this.upDuration = true
                this.downDuration = false
                this.upTime = false
                this.downTime = false

                this.durationSort = 'asc';
                this.$store.dispatch('video/sortVideolistUp', column)
                // this.tableData.sort((a, b) => a.duration - b.duration);
            } else if (column === 'time') {
                this.upTime = true
                this.downTime = false
                this.upDuration = false
                this.downDuration = false

                this.timeSort = 'asc';
                this.$store.dispatch('video/sortVideolistUp', column)

                // this.tableData.sort((a, b) => new Date(a.updated_on) - new Date(b.updated_on));
            }
        },
        sortDown(column) {
            console.log('sortDown')
            if (column === 'duration') {
                this.upDuration = false
                this.downDuration = true
                this.upTime = false
                this.downTime = false
                this.durationSort = 'desc';
                this.$store.dispatch('video/sortVideolistDown', column)

            } else if (column === 'time') {
                this.upTime = false
                this.downTime = true
                this.upDuration = false
                this.downDuration = false
                this.timeSort = 'desc';
                this.$store.dispatch('video/sortVideolistDown', column)

            }
        },
        
        formattedTime(totalSeconds) {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
        },

        async downloadVideo(videoId) {
            try {
                const video = this.tableData.filter(item => item.id === videoId)
                // console.log('video', video)
                const el = video.map(item=> item.translation)
                // console.log('el', el)
                if(el[0] === false) {
                    console.log(" don't translate")
                    return
                } else {
            await this.$store.dispatch('video/downloadVideo', videoId);
            } 
        }catch (error) {
            console.error(error);
            }
        },

        async downloadAudio(videoId) {
            try {
                const video = this.tableData.filter(item => item.id === videoId)
                // console.log('video', video)
                const el = video.map(item=> item.translation)
                // console.log('el', el)
                if(el[0] === false) {
                    console.log(" don't translate")
                    return
                } else {
            await this.$store.dispatch('video/downloadAudio', videoId);
            }
         } catch (error) {
            console.error(error);
            }
        },

    
        // async downloadVideo(videoId) {
        //         try {
        //             // this.isVoiceSend = true;
        //             const sessionKey = window.sessionStorage.getItem('auth_key')
        //             console.log('sessionKey actions', sessionKey)
        //             if (!sessionKey) throw new Error("No session key available.");
        //             console.log('videoTextTranlate', this.videoTextTranlate)
        //             // const dataToSend = {
        //             //     // auth_key: sessionKey, 
        //             //     video_id: this.videoId
        //             // };

        //             const response = await fetch('http://localhost:5001/download-video/<video_id', {
        //             method: 'GET',
        //             // body: JSON.stringify(dataToSend)
        //         });
        //         if (!response.ok) {
        //             throw new Error('Failed to download video');
        //             }

        //             const blob = await response.blob();
        //             const url = window.URL.createObjectURL(blob);
        //             const link = document.createElement('a');
        //             link.href = url;
        //             link.setAttribute('download', `video_${this.videoId}.mp4`);
        //             document.body.appendChild(link);
        //             link.click();
        //             window.URL.revokeObjectURL(url);
                    
        //         } catch (error) {
        //             console.error(error.message);
        //         }
        //     },


        editProject(videoId) {
            console.log(videoId)
            this.currentVideoId = videoId;
            console.log('edit video', this.currentVideoId)
            console.dir(this.tableData)
            const stepProject = Object.values(this.tableData).reduce((data, item) => {
                if (item.id === videoId) {
                    data[item.id] = {
                    id: item.id,
                    basic_lang: item.basic_lang,
                    name: item.name,
                    target_lang: item.target_lang,
                    translation: item.translation // figure out!!!
                }
            }
                return data;
            }, {})
            this.$emit('edit-video', videoId); //need!
              console.log(stepProject)
        },

        checkAllRows() {
            console.log(this.tableData)
            if (this.isIndeterminate || this.isNoneSelected) {
                console.log(this.isIndeterminate)
                console.log(this.isNoneSelected)
            this.tableData.forEach(item => {
                item.selected = this.selectAll;
            });
                this.selectAll = true;
            } else {
            this.tableData.forEach(item => {
                item.selected = false;
            });
            }
            this.updateSelectAll();
        },

        updateSelectAll() {
            if (this.isAllSelected) {
                console.log('updateSelectAll', this.isAllSelected)
                this.selectAll = true;
            } else if (this.isNoneSelected) {
                console.log('updateSelectAll - none')
                this.selectAll = false;
            } 
        },
        
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },

        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },

        promptDelete(videoId) {
            this.currentVideoId = videoId;
            this.showModal = true;
        },
        promptDeleteVideos() {
            console.log('promptDeleteVideos')
              console.log('videosId');
              const selectedItems = this.tableData.filter(item => item.selected);
              console.log(selectedItems.length);
              if(selectedItems.length === 0){
                this.isModal = true
              } else {
              const videoIds = selectedItems.map(item => item.id);
              console.log(videoIds);
              this.currentVideoId = videoIds;
              this.showModal = true;
            }

        },

        async confirmDelete() {
            if (Array.isArray(this.currentVideoId)) {
                 this.$store.dispatch('video/deleteVideos', this.currentVideoId)
            } else {
                this.$store.dispatch('video/deleteVideo', this.currentVideoId)
            }
            this.showModal = false;
            this.currentVideoId = null;

            // Оновити totalPages після видалення рядків
            // this.totalPages = Math.ceil(this.$store.state.video.videoList.length / this.itemsPerPage);

            await this.$store.dispatch('video/fetchVideoList');
            this.updateTotalPages();

            // Перевірка чи поточна сторінка не більше загальної кількості сторінок
            if (this.currentPage > this.totalPages) {
                this.currentPage = this.totalPages;
            }

        },
        updateTotalPages() {
            this.totalPages = Math.ceil(this.$store.state.video.videoList.length / this.itemsPerPage);
        },

    },
}
</script>

<style scoped>
.wrap-projectList {
    /* margin: 5px 24px; */
width: 100%;
max-width: 100%;
overflow: scroll;
}
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;

}
thead {
    background: #FAFAFA;
    height: 56px;
    
}
 tr {
    height: 56px;
    border-bottom: 1px solid #ddd;
    background-color: white;
    padding: 0px;
    
 }
 .shorten-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 56px;
    max-width: 100px;
 }
 .td-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 56px;
 }
 .td-button {
    color: #1890FF;
    border: none;
    background: none;
    cursor: pointer;
 }
 .notActivButton {
    color: gray;
    border: none;
    background: none;
    cursor: not-allowed;

 }
 .checkbox {
    margin: 15px;
 }

 .sort-duration,
.sort-time{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.sort {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
}
.text-title{
    text-align: center;
}
.triangle-up{
	width: 0;
	height: 0;
	border-width: 0 9px 7px 9px;
	border-color: transparent transparent #8F8F8F transparent;
	border-style: solid;
    cursor: pointer;
}
.up, .up1 {
    width: 0;
	height: 0;
	border-width: 0 9px 7px 9px;
	border-color: transparent transparent #035cf7 transparent;
	border-style: solid;
    cursor: pointer;
 
}
.triangle-down{
	width: 0;
	height: 0;
	border-width: 7px 9px 0 9px;
	border-color: #8F8F8F transparent transparent transparent;
	border-style: solid;
    cursor: pointer;
}
.down, .down1 {
    width: 0;
	height: 0;
	border-width: 7px 9px 0 9px;
	border-color: #035cf7 transparent transparent transparent;
	border-style: solid;
    cursor: pointer;

}

.pagination{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    justify-content: center;
    align-items: center;
}
tr:hover {background-color: rgba(211, 211, 211, 0.73);}
.modal-background {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-size: 16px;
}

.cell-text {
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3; 
    
}
.cell-text-container {
  /* display: flex; */
  /* align-items: center; */
  height: 100%;
  text-align: left;
}

.popup-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.popup {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 5px;
    border-radius: 3px;
}

.modal-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

@media screen and (max-width: 1080px) {
    .wrap-projectList {
        /* margin: 5px 24px; */
        width: 100%;
        max-width: 100%;
        overflow-x: scroll;
    }
    


}


</style>