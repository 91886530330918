<template>
    <div class="progress-wrap">
      <div class="progress-bar">
        <div class="progress-bar-fill" :style="{ width: progressPers + '%' }"></div>
      </div>
      <div class="progress-text">{{ progressPers }}%</div>
    </div>
  </template>
  
  <script>
    export default {
        name: 'ProgressBar',
        props: {
            progressValue: {
            type: Number,
            // required: true
            default: 0 // Значення за замовчуванням
            }
        },
        data() {
                return {
                progress: this.progressValue
                };
            },

        computed: {
            progressPers() {
                console.log('прогресбар progressPers', this.progressValue)
            return this.progressValue;
            }
        },
    };
  </script>
  
    
  <style>
  .progress-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .progress-bar {
    width: 100%;
    height: 10px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
    /* position: relative; */
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #007bff;
    transition: width 2s ease;
  }
  
  .progress-text {
    /* position: absolute; */
    font-size: 14px;
    /* top: 0;
    right: 0; */
    /* padding: 0 10px; */
    line-height: 20px;
    color: black;
  }
  </style>
  