// video.js (Vuex module)
import { apiUrl } from '../components/api'

const state = {
    videoList: [],
    countProjects: 0,
    translatedProjects: 0,
    notTranslatedProjects: 0,
    dateExpiration: null,
    voicesLists: [],
    currentVoices: [],
    segmentLists: [],
    waitingDownloadFile: false
};

const mutations = {
    setVideoList(state, videoList) {
        state.videoList = [...videoList];
        console.log('state.videoList', state.videoList)
    },
    deleteVideo(state, videoId) {
        state.videoList = state.videoList.filter(video => video.id !== videoId);
    },
    deleteVideos(state, videoIds) {
        state.videoList = state.videoList.filter(video => !videoIds.includes(video.id));
    },
    setCountProjects(state) {
        state.countProjects = state.videoList.length
    },
    setDateExpiration(state, dateString) {
        console.log('dateString', dateString)
        // const dateExp = new Date(dateString);
        state.dateExpiration = dateString
        console.log('state.dateExpiration', state.dateExpiration)
    },
    setVoicesList(state, lists) {
        console.log('setVoicesList', lists)
        state.currentVoices = lists
        console.log('state.voicesLists', state.currentVoices)
    },
    setSegmentLists(state, lists) {
        console.log('setSegmentLists', lists)
        state.segmentLists = lists
        console.log('state.segmentLists', state.segmentLists)
    },
    setWaitingDownloadFile(state, value){
        state.waitingDownloadFile = value
    }
};

const actions = {
    async fetchVideoList({ commit }) {
        try {
            const sessionKey = window.sessionStorage.getItem('auth_key')
            console.log('sessionKey actions', sessionKey)

            if (!sessionKey) throw new Error("No session key available.");

            const response = await fetch(`${apiUrl}/api/get-video-lists`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey }),
            });
            // console.log('response', response)

            if (response.ok) {
                const data = await response.json();
                // console.log('data', data)
                if (data.success) {
                    commit('setVideoList', data.videoList);
                    commit('setCountProjects', data.videoList.length)
                } else {
                    console.error("Backend Error: ", data.error);
                }
            } else {
                console.error("Network Response Error");
            }
        } catch (err) {
            console.error(err);
        }
    },

    async deleteVideo({ commit }, videoId) {
        try {
            const sessionKey = sessionStorage.getItem("auth_key");
            console.log(sessionKey)
            if (!sessionKey) throw new Error("No session key available.");

            const response = await fetch(`${apiUrl}/api/delete-video`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey, video_id: videoId }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    commit('deleteVideo', videoId);
                } else {
                    console.error("Backend Error: ", data.error);
                }
            } else {
                console.error("Network Response Error");
            }
        } catch (err) {
            console.error(err);
        }
    },



    async deleteVideos({ commit }, videoIds) {
        try {
            const sessionKey = sessionStorage.getItem("auth_key");
            if (!sessionKey) throw new Error("No session key available.");

            const response = await fetch(`${apiUrl}/api/delete-videos`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey, video_ids: videoIds }),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    commit('deleteVideos', videoIds);
                } else {
                    console.error("Backend Error: ", data.error);
                }
            } else {
                console.error("Network Response Error");
            }
        } catch (err) {
            console.error(err);
        }
    },

    /* eslint-disable */
    async downloadVideo({ commit }, videoId) {
        try {
            const sessionKey = sessionStorage.getItem("auth_key");
            if (!sessionKey) throw new Error("No session key available.");

            // const response = await fetch(`${apiUrl}/download-video/${videoId}`, {
            //     method: "GET",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${sessionKey}`, 
            //     },
            // });
            commit('setWaitingDownloadFile', true)
            const response = await fetch(`${apiUrl}/api/download-video`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey, video_id: videoId }),
            });

            if (response.ok) {
                console.log('response', response)
                const videoBlob = await response.blob();
                const url = URL.createObjectURL(videoBlob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${videoId}_translated.mp4`;
                document.body.appendChild(a);
                commit('setWaitingDownloadFile', false)
                a.click();
                URL.revokeObjectURL(url);
            } else {
                console.error("Backend Error: ", response.statusText);
            }
        } catch (err) {
            console.error(err);
        }
    },

    /* eslint-disable */
    async downloadAudio({ commit }, videoId) {
        try {
            const sessionKey = sessionStorage.getItem("auth_key");
            if (!sessionKey) throw new Error("No session key available.");

            // const response = await fetch(`${apiUrl}/download-audio/${videoId}`, {
            //     method: "GET",
            //     headers: {
            //         "Content-Type": "application/json",
            //         "Authorization": `Bearer ${sessionKey}`, 
            //     },
            // });
            commit('setWaitingDownloadFile', true)
            const response = await fetch(`${apiUrl}/api/download-audio`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ auth_key: sessionKey, video_id: videoId }),
            });
            if (response.ok) {
                const videoBlob = await response.blob();
                const url = URL.createObjectURL(videoBlob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `${videoId}_aligned.mp3`;
                
                document.body.appendChild(a);
                commit('setWaitingDownloadFile', false)
                a.click();
                URL.revokeObjectURL(url);
            } else {
                console.error("Backend Error: ", response.statusText);
            }
        } catch (err) {
            console.error(err);
        }
    },

        /* eslint-disable */
        async downloadXls({ commit }, data) {
            console.log('dispatch xls', data)
            try {
                const { videoId, type_of_align} = data
                console.log(videoId, type_of_align)

                const sessionKey = sessionStorage.getItem("auth_key");
                if (!sessionKey) throw new Error("No session key available.");
        
                const response = await fetch(`${apiUrl}/api/download-segments-xls`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ auth_key: sessionKey, video_id: videoId, type_of_align: type_of_align }),
                });
                if (response.ok) {
                    const videoBlob = await response.blob();
                    const url = URL.createObjectURL(videoBlob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${videoId}_segments.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(url);
                } else {
                    console.error("Backend Error: ", response.statusText);
                }
            } catch (err) {
                console.error(err);
            }
        },

        /* eslint-disable */
        async downloadSrt({ commit }, data) {
            console.log('dispatch srt', data)
            try {
                const { videoId, text} = data
                console.log(videoId, text)
                const sessionKey = sessionStorage.getItem("auth_key");
                if (!sessionKey) throw new Error("No session key available.");
                const response = await fetch(`${apiUrl}/api/download-segments-srt`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ auth_key: sessionKey, video_id: videoId, srt_text: text }),
                });
                if (response.ok) {
                    const videoBlob = await response.blob();
                    const url = URL.createObjectURL(videoBlob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = `${videoId}_segments.srt`;
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(url);
                } else {
                    console.error("Backend Error: ", response.statusText);
                }
            } catch (err) {
                console.error(err);
            }
        },
        
    
    getDateExpiration({ commit }) {
        const date = document.cookie.split('|||')
        console.log('getDateExpiration', date)
        const [authKey, timestamp, dateExp] = date;
        // getters.getCookieValue('auth_key')
        console.log(dateExp)
        const dateExpir = sessionStorage.getItem("expired_date");
        if(dateExp) {
            const date1 = dateExp.split(';')
            console.log('date1', date1)
            const [dateExpir1, , ] = date1
            console.log('dateExpir1', dateExpir1)
    
            commit('setDateExpiration', dateExpir1)
        } else {
            commit('setDateExpiration', dateExpir)

        }
    },

    updateDateExpiration({ commit }, dateString) {
       console.log('dateString updateDateExpiration',dateString)
        commit('setDateExpiration', dateString)
    },

    getSegmentLists( {commit}, lists) {
        console.log('getSegmentLists', lists)
        const segmentArray = Object.values(lists);
        commit('setSegmentLists', segmentArray)
    },

    getAllVoices({commit}, lists) {
        console.log('getSegmentLists', lists)
        const segmentArray = Object.values(lists);
        const allVoices = segmentArray.map(segment => segment.all_voices);
        commit('setVoicesList', allVoices)

    },
    sortVideolistUp({commit}, column){
        console.log('sortVideolistUp1')
        if(column === 'duration') {
            console.log('sortVideolistUp2')
            const list = state.videoList.sort((a, b) => a.duration - b.duration);
            commit('setVideoList', list)
        } else if(column === 'time') {
            console.log('sortVideolistUp3')
            const list = state.videoList.sort((a, b) => new Date(a.updated_on) - new Date(b.updated_on));
            commit('setVideoList', list)
        }
    },
    sortVideolistDown({commit}, column){
        if(column === 'duration') {
            const list = state.videoList.sort((a, b) => b.duration - a.duration);
            commit('setVideoList', list)
        } else if(column === 'time') {
            const list = state.videoList.sort((a, b) => new Date(b.updated_on) - new Date(a.updated_on));
            commit('setVideoList', list)
        }
    }


};






const getters = {
    countProjects: state => state.videoList.length,
    translatedProjects: state => {
        return state.videoList.filter(item => item.translation == true).length
    },
    dateExpiration: state => state.dateExpiration,
    currentVoice: state => state.segmentLists.current_voice,
    femaleVoices: state=> state.allVoices.FEMALE,
    maleVoices: state => state.allVoices.MALE,
};





const video = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default video;
