<template>
    <div class="wrap">
        <div class="global-header" >
            <div>
                <router-link to="/" class="titleMain">
                    <p class="titleMain">Clip Translate
                        <span style="position: relative; top: -0.6em; font-size: 0.6em;">β</span>
                        <span style="font-size: 8px;">  V2.2024-02-15</span>
                    </p>
                </router-link>
            </div>
            <div class="toolbar">
                <a href="https://www.youtube.com/watch?v=YT4yxrmKlq8" target="_blank" style="font-size: 16px; color: white; text-decoration: none;">Instruction</a>
                <a href="https://cliptranslate.canny.io/feature-requests" target="_blank" style="font-size: 16px; color: white; text-decoration: none;">Add your feedback</a>
                <router-link to="/home/donate" class="no-underline" style="font-size: 16px; color: Red; text-decoration: none;">Donate</router-link>
                <div><p style="font-size: 16px; color: white;" class="exit" @click="exitToLogin">Exit</p></div>
            </div>
            <div class="toolbar" v-if="hide">
                <!-- <div class="qaaBlock"><img src="../icon/qaa.svg" class="qaa"/></div> -->
                <!-- <div class="badgeBlock"><img src="../icon/badge.svg" class="badge"/></div> -->
                <!-- <div class="profileContent">
                    <img src="../icon/workspase.svg" class="profileMini"/>
                    <p class="nameProfile">Serati Ma</p>
                </div> -->
                <div class="languageBlock" @click.stop="openLanguageDropdown" ref="dropdownRef">
                    <img src="../icon/language.svg"/>
                    <language-dropdown 
                    class="language-dropdown"
                    v-if="showLanguageDropdown" 
                    @language-changed="handleLanguageChanged"/>
                </div>
            </div>
        </div>
        <div class="sidebar-menu"  :class="{'menu-active': !changeSidebarView}">
            <div class="item-menu" :class="{ 'item-menu-icon': changeSidebarView === false}">
                <router-link to="/home" class="no-underline"><div class="main-menu img" @click="selectMenuItem('home')" :class="{ 'active': selectedMenuItem === 'home', 'menu-active': !changeSidebarView}">
                    <img src="../icon/home1.svg" style="width: 20px; height: 20px; margin-left: 5px;"> 
                    <p class="titleMenu">Home</p>
                </div></router-link>
                <router-link to="/home/projects" class="no-underline">
                    <div class="my-project img" 
                    :class="{ 'active': selectedMenuItem === 'projects', 'menu-active': !changeSidebarView}"          
                    @click="selectMenuItem('projects')">
                    <img src="../icon/document.png" style="width: 20px; height: 20px; margin-left: 5px;"> 
                    <p class="titleMenu">My projects</p></div></router-link>
                <router-link to="/home/donate" class="no-underline"><div class="profile img" @click="selectMenuItem('donate')" 
                    :class="{ 'active': selectedMenuItem === 'donate', 'menu-active': !changeSidebarView}">
                    <img src="../icon/charity.png" style="width: 20px; height: 20px; margin-left: 5px;"> 
                    <p class="titleMenu">Donate</p>
                </div></router-link>
                
            </div>
            <div class="collapser" @click="toggleSidebar">
                <div class="wrapper-collapser">                
                    <img src="../icon/arrow_open.png" alt="close_sidebar" class="collapser-img" v-show="!changeSidebarView">
                    <img src="../icon/arrow_close.png" alt="close_sidebar" class="collapser-img" v-show="changeSidebarView">
                </div>
            </div>
        </div>
        <div class="page-container" :class="{'menu-active': !changeSidebarView}">
            <router-view></router-view>
        </div>
         
        <plashka-modal 
            v-if="shouldShowPlashka < 3 && shouldShowPlashka > 0"
            :show-reminder="plashkaVisible" 
            @update:show-reminder="plashkaVisible = $event"> 
            WARNING! Your key is expiring {{dateExpiration}}
        </plashka-modal>
        <plashka-modal 
            v-if="shouldShowPlashka <= 0"
            style="color: 'red';"
            :show-reminder="plashkaVisible" 
            @update:show-reminder="moveToLogin"> 
            WARNING! Your key has expired. Update the key.
        </plashka-modal>

    </div>
</template>

<script>
    import LanguageDropdown from './LanguageDropdown.vue';


    export default {
        name: "AppHome",
        components: {
            LanguageDropdown,
        },
        data() {
            return {
                selectedMenuItem: 'home',
                changeSidebarView: false,
                children: true,
                showLanguageDropdown: false,
                plashkaVisible: true,

                hide: false
            };
        },
        created() {
            this.$store.dispatch('video/getDateExpiration')
            // console.log(this.$store.getters['video/dateExpiration'])
        },

        mounted() {
        // Перевіряємо, чи є збережена сторінка в локальному сховищі
            const storedMenuItem = localStorage.getItem('selectedMenuItem');
            if (storedMenuItem) {
                if ((this.$route.path === "/" || this.$route.path === "/home") &&
                storedMenuItem !== "home") {
                this.selectedMenuItem = "home";
                } else {
                this.selectedMenuItem = storedMenuItem;
                }
            }
            document.addEventListener('click', this.onClickOutside);

            // const dateExpiration = this.$store.getters['video/dateExpiration']
            // this.calculateDaysRemaining(dateExpiration)

        },

        computed: {
            pageTitle() {
                return this.$route.meta.label;
            },
            dateExpiration() {
                console.log('getter', this.$store.getters['video/dateExpiration'])
                const dateExp = new Date(this.$store.getters['video/dateExpiration'])
                return dateExp;
            },
            shouldShowPlashka(){
                console.log('shouldShowPlashka')
                return this.calculateDaysRemaining(this.dateExpiration) //< 3 //
                // return this.dateExpiration
            },
        },
        methods: {
            exitToLogin(){
                sessionStorage.clear();
                const cookies = document.cookie.split("; ");
                for (let i = 0; i < cookies.length; i++) {
                    let cookie = cookies[i];
                    let eqPos = cookie.indexOf("=");
                    let  name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                }
                this.$router.push('/')
            },

            moveToLogin() {
                console.log('moveToLogin')
                this.$router.push({ name: 'Login'})
                this.plashkaVisible = false
            },
            calculateDaysRemaining(expirationDate) {
            console.log('calculateDaysRemaining')
            console.log('expirationDate',expirationDate)
            const currentDate = new Date();
            console.log('currentDate',currentDate)
            const timeDiff = expirationDate - currentDate;
            console.log('timeDiff', timeDiff)
            const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60));
            console.log('daysRemaining', daysRemaining)
            return daysRemaining;
        },

            openLanguageDropdown(){
                this.showLanguageDropdown = !this.showLanguageDropdown;
            },
            selectMenuItem(item) {
                this.selectedMenuItem = item;
                localStorage.setItem('selectedMenuItem', item); 
            },
            toggleSidebar() {
                console.log("sidebar");
                this.changeSidebarView = !this.changeSidebarView;
            },
            handleActiveRoute(route) {
                console.log('router')
                // Обробка активного маршруту
                this.selectedMenuItem = route;
            },
            handleLanguageChanged(selectedLanguage) {
                console.log('Selected language:', selectedLanguage);
                // this.showLanguageDropdown = false; 
            },
            // onClickOutside(event) {
            //     if (!this.$refs.dropdownRef.contains(event.target)) {
            //         this.showLanguageDropdown = false
            //     }
            //     },
        },

        beforeRouteUpdate(to, from, next) {
            console.log('beforeRouteUpdate')
            if (from.name === 'Login') {
                console.log('from Login')
                this.calculateDaysRemaining(this.dateExpiration)
                this.showReminder = true;
            } else {
                console.log('not from Login')
                this.showReminder = false;
            }
        
            next();
        },

        beforeUnmount() {
            document.removeEventListener('click', this.onClickOutside);
        },

        watch: {
            // Спостерігаємо за змінами шляху
            $route(to) {
                console.log('change path to', this.$route.path)
                // Визначте логіку для зміни активного пункту меню відповідно до шляху
                if (to.path === "/home" || to.path === "/") {
                    this.selectedMenuItem = "home";
                } else if (to.path === "/home/projects") {
                    this.selectedMenuItem = "projects";
                } else if (to.path === "/home/profile") {
                    this.selectedMenuItem = "profile";
                } else if (to.path === "/home/arhive") {
                    this.selectedMenuItem = "arhive";
                }
            },
        },
    }
   
</script>

<style scoped>

    .wrap {
        margin: 0;
        padding: 0;
    }
    .global-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 16px;
        /* gap: 850px; */
        position: fixed;
        height: 48px;
        left: 0px;
        right: 0px;
        top: 0px;
        background: #001529;
        z-index: 999;
    }
    .sidebar-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* align-items: flex-start; */
        padding: 0px;
        gap: 276px;
        position: fixed;
        width: 208px;
        left: 0px;
        top: 48px;
        bottom: 0px;
        background: #FFFFFF;
        /* box-shadow: 5px 0 5px  rgba(128, 128, 128, 0.638); */
        transition:  0.5s ease; /* Додали перехід для згладжування руху */
        transform: translateX(0); /* Задаємо початкове положення сайдбару */
        z-index: 999;
        border-right: 0.5px solid #646565;
    }

    .img {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    transition:  0.5s ease; 

    }
    .no-underline {
        text-decoration: none;
    }
    .img p{
        transition:  0.5s step-end; 
        overflow-x: hidden;
        white-space: nowrap; 
        color: rgba(0, 0, 0, 0.85);
    }


    .item-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 208px;
        height: 208px;
        flex: none;
        /* order: 1; */
        text-align: start;
        gap: 8px;
    }

    .item-menu-icon {
        width: 35px;
        height: 100px;
    }
    .img{
        height: 40px;
        width: 208px;
    }
    .active {
        background-color: #add8e6;
    }
    .page-container {
        position: fixed;
        /* height: 100vh; */
        left: 208.5px;
        right: 0px;
        top: 48px;
        bottom: 0px;
        background-color: #F0F2F5;
        transition:  0.5s ease; 
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        /* max-width: 1200px; */
    }
    .page-container.menu-active {
        left:35px;
    }
    .container-header {
        height: 100px;
        background-color: #FFFFFF;
    }
    .img:hover{
        cursor: pointer;
    }
    .collapser{
        height: 20px;
        width: 20px;
        position: fixed;
        right: -10px;
        bottom: 50%;
        cursor: pointer;
    }
    .collapser-img{
        height: 20px;
        width: 20px;
        opacity: 60%;
    }
    .wrapper-collapser {
        height: 20px;
        width: 20px;
        background-color: #F0F2F5;
        border-radius: 50%;
    }
    .sidebar-menu.menu-active {
        width: 35px;
    }
    .img.menu-active {
        width: 35px;
    }

    .side-bar-switcher {
        position: fixed;
        cursor: pointer;
        left: 208px;
        top: 50%;
        transition:  0.5s ease; 
        transform: translateX(0); 
        z-index: 999;
    }
        
    
    /* TOOLBAR */
    .toolbar{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        /* width: 244px; */
        height: 48px;
        gap: 10px;
    }
    .language, .badge, .qaa{
        height: 16px;
        width: 16px;
    }
    .language-dropdown {
        position: absolute;
        z-index: 999;
        top: 37px;
        right: 20px;
        color: black;

    }
    .profileMini{
        height: 24px;
        width: 24px;
    }
    .profileContent{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
        cursor: pointer;
    }
    .qaaBlock {
        height: 48px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .languageBlock {
        height: 48px;
        width: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .badgeBlock {
        height: 48px;
        width: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .nameProfile {
        font-size: 14px;
        color: #FFFFFF;
    }
    .titleMain {
        text-decoration: none;
        color: #FFFFFF;
    }
    .exit{
        cursor: pointer;
    }

</style>