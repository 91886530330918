<template>
    <div class="wrap-plashka" @click.stop="hideConfirm" v-if="showConfirm">
        <div  class="confirm" @click.stop>
            <slot class="slot-plashka"></slot>
            <div class="modal-button">
                <button @click="confirmAct">Confirm</button>
                <button @click="closeConfirm">Cancel</button>
            </div>

        </div>
    </div>
  </template>

  <script>

  export default {
    name: 'plashka-confirm',
    props: {
        showConfirm: {
            type: Boolean,
            default: false
        }

    },
    data() {
        return {
        }
    },
     methods: {
        confirmAct() {
            this.$emit('actConfirm', true)
        },
        closeConfirm() {
            this.$emit('update:showReminder', false)

        }
     }

  }
</script>

<style scoped>
.wrap-plashka {
     position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.02);
    z-index: 999;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.confirm {
    width: 250px;
    height: auto;
    border: 1px solid gray;
    border-radius: 5px;
    background: white;
    padding: 20px;
    position: absolute;
    /* top: 50px; */
    /* right: 50px; */
    color: black;
    text-align: start;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.button-plashka {
    align-self: end;
}
.slot-plashka {
    display: block;
    margin: 5px;
}
.modal-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>