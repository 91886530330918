<template>

    <div class="dropzone"  ref="dropzone" 
        @dragenter.prevent="toggleActive"
        @dragleave.prevent="toggleActive"
        @dragover.prevent
        @drop.prevent
        :class="{'active-dropzone': active}"
    >
        <form class="my-form">
            <img src="../icon/download.svg" alt="drag and drog" class="imgLoad">
            <input type="file" id="dropzoneFile" class="dropzoneFile" accept=".mp4,.mov,.mp3,.wav" @change="handleFile">
            <label  for="dropzoneFile" class="labelButton" >Upload file</label>
            <p class="text2">Click or drag file to this area to upload. You can upload file with .mp3, .mp4, .mov, .wav extensions only. </p> 
        </form>
    </div>

</template>

<script>
import {ref} from 'vue'

export default {
    name: 'DropzoneVue',

    setup() {
        const active = ref(false)
        const toggleActive = () => {
            active.value = !active.value
        }
        return { active, toggleActive} 
    }
}

</script>

<style scoped>
.dropzone{
    box-sizing: border-box;
    background: #F3F0FF;
    border: 1px dashed #C1B2FA;
    border-radius: 8px;
    height: 160px;
    /* margin-left: 16px; */
    margin-right: 16px;
    flex: none;
    width: 100%;
}
    .my-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imgLoad{
        height: 32px;
        width: 45px;
        margin-top: 35px;
        margin-bottom: 19px;
    }
    .dropzoneFile{
        font-size: 14px;
        color: #7A5FEC;
        width: 84px;
        height: 21px;
        margin: 0 auto;
        
    }
    .text2 {
        font-size: 16px;
        color: #746E82;
        height: 21px;
        margin: 0 auto;
    }
    #dropzoneFile{
        display: none;
    }
    .labelButton{
        cursor: pointer;
        color: #7A5FEC;
        margin: 0 auto;
    }


.file-info {
    margin-top: 35px;
    display: block;
}
 
.active-dropzone {
    background: #e5dffd;
 border: 1px dashed #b5a4fb;
}

</style>